import React, { FC } from 'react'
import { Box, Card, CircularProgress, Divider, IconButton, Link, Menu, MenuItem } from '@mui/material'
import { PostInterface, PostType } from 'shared-data/interfaces/post.interface'
import { useDispatch } from 'react-redux'
import { MoreHoriz } from '@mui/icons-material'
import { getPostAndEdit } from '../../state/postComposer.slice'
import { BackgroundDecorationComponent } from './Details/BackgroundDecoration.component'
import { PostItselfComponent } from './Details/PostItself.component'
import { PostStatisticsComponent } from './Details/PostStatistics.component'
import { PostScheduledComponent } from './Details/PostScheduled.component'
import { PostItemDraftAttachmentsComponent } from './Details/PostItemDraftAttachments.component'
import { setPostToDiscardOrDelete } from '../../state/posts.slice'

interface Props {
  post: PostInterface
  postIndex: number
  deleting: boolean
  postType: PostType
  pulse?: number[]
}

const LoadingOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      position: 'absolute',
      backgroundColor: '#ffffffaa',
      width: 1,
      height: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
)

export const PostItemWithDetailsComponent: FC<Props> = ({ post, postIndex, deleting = false, postType, pulse }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isPosting, setIsPosting] = React.useState<boolean>(false)
  const isMenuOpen = Boolean(anchorEl)
  const d = useDispatch()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = async () => {
    handleClose()
    d(setPostToDiscardOrDelete({ type: postType, index: postIndex }))
  }

  const handleEdit = () => {
    setAnchorEl(null)
    d(getPostAndEdit(post._id as string))
  }

  const MenuComponent = () => (
    <Menu
      id={`menu${postIndex}`}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': `post${postIndex}`,
      }}
    >
      {post.location && [
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
          }}
          key="location"
        >
          <Link href={post.location} target="_blank" rel="noopener noreferrer">
            View on LinkedIn
          </Link>
        </MenuItem>,
        <Divider variant="middle" sx={{ margin: '0 10px 0' }} key="location-divider" />,
      ]}
      {!post.urn && [
        <MenuItem onClick={handleEdit} key={1}>
          Edit
        </MenuItem>,
        <Divider variant="middle" sx={{ margin: '0 10px 0' }} key={2} />,
        <MenuItem onClick={handleDelete} key={3}>
          Discard
        </MenuItem>,
      ]}
      {post.urn && [
        <MenuItem onClick={handleClose} key="clone-to-drafts">
          Clone to drafts
        </MenuItem>,
        <Divider variant="middle" sx={{ margin: '0 10px 0' }} key={2} />,
        <MenuItem onClick={handleDelete} key={3}>
          Delete
        </MenuItem>,
      ]}
    </Menu>
  )

  const loading = isPosting || deleting

  return (
    <Card
      elevation={12}
      sx={{
        mb: 5,
        opacity: post.isDeletedOnLinkedIn ? 0.5 : 1,
        borderLeft: post.isDeletedOnLinkedIn ? '4px solid red' : 'none',
        position: 'relative',
      }}
    >
      {loading ? <LoadingOverlay /> : <MenuComponent />}
      <BackgroundDecorationComponent />
      <Box display="flex">
        <Box
          sx={{
            width: 1 / 3,
            minHeight: 330,
            borderRight: 'thin solid #dedede',
            cursor: post.isDraft ? 'pointer' : 'default',
          }}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          onClick={() => {
            if (post.isDraft) {
              handleEdit()
            }
          }}
        >
          <PostItselfComponent post={post} />
        </Box>
        <Box sx={{ width: 2 / 3 }} pl={2} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="flex-end" flexGrow={0}>
            <IconButton
              aria-label="settings"
              id={`post${postIndex}`}
              aria-controls={isMenuOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreHoriz />
            </IconButton>
          </Box>
          {post.urn && !post.isDraft && pulse && <PostStatisticsComponent post={post} pulse={pulse} />}
          {!post.urn && post.scheduledAt && !post.isDraft && !post.isPosted && (
            <PostScheduledComponent post={post} onPosting={() => setIsPosting(true)} />
          )}
          {post.isDraft && <PostItemDraftAttachmentsComponent post={post} />}
        </Box>
      </Box>
    </Card>
  )
}
