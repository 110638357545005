import React from 'react'
import {
  Badge,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { PlanTypes } from 'shared-data/features/plan.types'
import IconButton from '@mui/material/IconButton'
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { ANNUAL_PRICE_TOOLTIP, features, prices } from '../../pages/Pricing/settings'
import { RootState } from '../../store/RootState'

const PricingTableComponent = ({ isAnnual, clickSubscribe }) => {
  const user = useSelector((state: RootState) => state.user.user)
  const theme = useTheme()

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Feature</TableCell>
            <TableCell align="center" sx={{ width: 200, pt: 3 }}>
              <Typography variant="h6">Free</Typography>
              {user?.data && (
                <Box mt={3}>
                  <Button
                    type="button"
                    variant="contained"
                    color="success"
                    disabled={Boolean(user?.data?.stripeSubscriptionId) && user?.data?.plan === PlanTypes.FREE}
                    onClick={(e) => clickSubscribe(e, PlanTypes.FREE, isAnnual ? 'a' : 'm')}
                  >
                    {user?.data?.plan && user?.data?.plan !== PlanTypes.FREE ? 'Downgrade' : 'Subscribe'}
                  </Button>
                </Box>
              )}
              <Box mt={3}>
                {isAnnual && [
                  // <Typography variant="h6" color={theme.palette.warning.main}>
                  //   ${prices[PlanTypes.FREE].annualPerMonth}* <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  // </Typography>,
                  <Box>
                    <Tooltip title={ANNUAL_PRICE_TOOLTIP}>
                      <Typography variant="h6" color={theme.palette.grey['900']}>
                        ${prices[PlanTypes.FREE].annual} <TimePeriodWrapper>/ year</TimePeriodWrapper>
                      </Typography>
                    </Tooltip>
                  </Box>,
                ]}
                {!isAnnual && [
                  <Typography variant="h6" color={theme.palette.warning.main}>
                    ${prices[PlanTypes.FREE].monthly} <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  </Typography>,
                ]}
              </Box>
            </TableCell>
            <TableCell align="center" sx={{ width: 200, pt: 3 }}>
              <Badge color="error" badgeContent="30 days free" variant={user?.data?.isTrialUsed ? 'dot' : 'standard'}>
                <Typography variant="h6">Personal</Typography>
              </Badge>
              {user?.data && (
                <Box mt={3}>
                  <Badge color="warning" badgeContent={!isAnnual ? 'Pay monthly' : 'Pay yearly'}>
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      disabled={user?.data?.plan === PlanTypes.PERSONAL}
                      onClick={(e) => clickSubscribe(e, PlanTypes.PERSONAL, isAnnual ? 'a' : 'm')}
                    >
                      {user?.data?.plan && user?.data?.plan === PlanTypes.BUSINESS ? 'Downgrade' : 'Subscribe'}
                    </Button>
                  </Badge>
                </Box>
              )}
              <Box mt={3}>
                {!isAnnual && (
                  <Typography variant="h6" color={theme.palette.warning.main}>
                    ${prices[PlanTypes.PERSONAL].monthly} <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  </Typography>
                )}
                {isAnnual && [
                  // <Tooltip title={ANNUAL_PRICE_TOOLTIP}>
                  //   <Typography variant="h6" color={theme.palette.warning.main}>
                  //     ${prices[PlanTypes.PERSONAL].annualPerMonth}* <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  //   </Typography>
                  // </Tooltip>,
                  <Box>
                    <Tooltip title={ANNUAL_PRICE_TOOLTIP}>
                      <Typography variant="h6" color={theme.palette.grey['900']}>
                        ${prices[PlanTypes.PERSONAL].annual} <TimePeriodWrapper>/ year</TimePeriodWrapper>
                      </Typography>
                    </Tooltip>
                  </Box>,
                ]}
              </Box>
            </TableCell>
            <TableCell align="center" sx={{ width: 200, pt: 3 }}>
              <Badge color="error" badgeContent="30 days free" variant={user?.data?.isTrialUsed ? 'dot' : 'standard'}>
                <Typography variant="h6">Business</Typography>
              </Badge>
              {user?.data && (
                <Box mt={3}>
                  <Badge color="warning" badgeContent={!isAnnual ? 'Pay monthly' : 'Pay yearly'}>
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      disabled={user?.data?.plan === PlanTypes.BUSINESS}
                      onClick={(e) => clickSubscribe(e, PlanTypes.BUSINESS, isAnnual ? 'a' : 'm')}
                    >
                      Subscribe
                    </Button>
                  </Badge>
                </Box>
              )}
              <Box mt={3}>
                {!isAnnual && (
                  <Typography variant="h6" color={theme.palette.warning.main}>
                    ${prices[PlanTypes.BUSINESS].monthly} <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  </Typography>
                )}
                {isAnnual && [
                  // <Tooltip title={ANNUAL_PRICE_TOOLTIP}>
                  //   <Typography variant="h6" color={theme.palette.warning.main}>
                  //     ${prices[PlanTypes.BUSINESS].annualPerMonth}* <TimePeriodWrapper>/ month</TimePeriodWrapper>
                  //   </Typography>
                  // </Tooltip>,
                  <Box>
                    <Tooltip title={ANNUAL_PRICE_TOOLTIP}>
                      <Typography variant="h6" color={theme.palette.grey['900']}>
                        ${prices[PlanTypes.BUSINESS].annual} <TimePeriodWrapper>/ year</TimePeriodWrapper>
                      </Typography>
                    </Tooltip>
                  </Box>,
                ]}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{feature.name}</Typography>
                {feature.description && (
                  <Typography mt={1} variant="body2" sx={{ fontWeight: 100 }}>
                    {feature.description}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  verticalAlign:
                    feature.noteInFree || feature.noteInPersonal || feature.noteInBusiness ? 'top' : 'middle',
                }}
              >
                {feature.inFree && !feature.overrideInFree && (
                  <IconButton>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                )}
                {feature.inFree && feature.overrideInFree && (
                  <Typography variant="body1">{feature.overrideInFree}</Typography>
                )}
                {feature.noteInFree && (
                  <Typography variant="body2" sx={{ fontSize: 11 }}>
                    {feature.noteInFree}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  verticalAlign:
                    feature.noteInFree || feature.noteInPersonal || feature.noteInBusiness ? 'top' : 'middle',
                }}
              >
                {feature.inPersonal && !feature.overrideInPersonal && (
                  <IconButton>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                )}
                {feature.inPersonal && feature.overrideInPersonal && (
                  <Typography variant="body1">{feature.overrideInPersonal}</Typography>
                )}
                {feature.noteInPersonal && (
                  <Typography variant="body2" sx={{ fontSize: 11 }}>
                    {feature.noteInPersonal}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  verticalAlign:
                    feature.noteInFree || feature.noteInPersonal || feature.noteInBusiness ? 'top' : 'middle',
                }}
              >
                {feature.inBusiness && !feature.overrideInBusiness && (
                  <IconButton>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                )}
                {feature.inBusiness && feature.overrideInBusiness && (
                  <Typography variant="body1">{feature.overrideInBusiness}</Typography>
                )}
                {feature.noteInBusiness && (
                  <Typography variant="body2" sx={{ fontSize: 11 }}>
                    {feature.noteInBusiness}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TimePeriodWrapper = styled('small')(() => ({
  fontSize: 14,
  color: '#989795',
  fontWeight: 400,
}))

export default PricingTableComponent
