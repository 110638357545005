import { useDispatch, useSelector } from 'react-redux'
import './assets/styles/app.scss'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { getLinkedinProfileHelper } from 'shared-data/helpers/profiles/getLinkedinProfile.helper'
import { getTwitterProfileHelper } from 'shared-data/helpers/profiles/getTwitterProfile.helper'
import { SignInPage } from './pages/SignIn.page'
import { SchedulePage } from './pages/Schedule.page'
import { RootState } from './store/RootState'
import { useUserApi } from './hooks/useUserApi'
import { SignUpPage } from './pages/SignUp.page'
import Loader from './components/loader'
import { Footer } from './components/footer/footer'
import { TopNavBarComponent } from './components/navbar/topNavBar.component'
import { PasswordResetRequestForm } from './components/auth/forms/passwordResetRequest.form'
import { PasswordResetForm } from './components/auth/forms/passwordReset.form'
import { LinkedInConnectorModalComponent } from './components/socialNetworksConnectorWarning/LinkedInConnectorModal.component'
import { IdeasPage } from './pages/Ideas.page'
import { ConnectionsPage } from './pages/Connections.page'
import { ProfilePage } from './pages/Profile.page'
import { PricingPage } from './pages/Pricing/Pricing.page'
import { DraftsPage } from './pages/Drafts.page'
import { PostComposer } from './components/PostComposer/PostComposer.component'
import { TimelinePage } from './pages/Timeline.page'
import { SettingsPage } from './pages/settings/Settings.page'
import { DashboardPage } from './pages/Dashboard.page'
import { CommonMessage } from './components/commonMessage/CommonMessage.component'
import { userSlice } from './state/user.slice'
import { TimeTargetPage } from './pages/TimeTarget.page'
import { getPostAndEdit, postComposerSlice } from './state/postComposer.slice'
import { TimeTargetModal } from './components/timeTarget/timeTargetModal.component'
import { TeamsPage } from './pages/Teams.page'
import { OrganizationPage } from './pages/Organization.page'
import { InvitationPage } from './pages/Invitation.page'
import { IntercomComponent } from './components/Intercom/Intercom.component'
import { FileModalComponent } from './components/fileModal/FileModal.component'
import { nextFile, prevFile } from './state/fileModal.slice'
import { deletePost } from './state/posts.slice'

// trigger 4

export default function App() {
  const { user, token, shouldReconnectSocket } = useSelector((state: RootState) => state.user)
  const files = useSelector((state: RootState) => state.file.filesList)
  const postToDiscardOrDelete = useSelector((state: RootState) => state.posts.postToDiscardOrDelete)

  const { load, socketReconnect } = useUserApi()

  const dispatch = useDispatch()

  const linkedinProfile = getLinkedinProfileHelper(user.data)
  const twitterProfile = getTwitterProfileHelper(user.data)

  useEffect(() => {
    if (user?.data?._id && Boolean(window.location.hash)) {
      dispatch(getPostAndEdit(window.location.hash.replace('#', '')))
    }
  }, [user])

  useEffect(() => {
    if (token) {
      if (shouldReconnectSocket) {
        socketReconnect()
      } else {
        dispatch(userSlice.actions.setShouldReconnectSocket(true))
      }
    }
  }, [token])

  useEffect(() => {
    void load()
  }, [])
  // jes
  if (user.loading) {
    return <Loader />
  }
  //
  return (
    <div
      className="App"
      onDrop={(e) => {
        e.preventDefault()
        e.persist()
        if (e.dataTransfer?.files?.length) {
          const toSet = e.dataTransfer.files[0]
          if (toSet.type.includes('image') || toSet.type.includes('video')) {
            dispatch(postComposerSlice.actions.setAttachment(e.dataTransfer.files[0]))
          }
        }
      }}
      onDragOver={(e) => {
        e.preventDefault()
        e.stopPropagation()
        e.persist()
      }}
      onPaste={(e) => {
        const { files } = e.clipboardData
        if (files) {
          const file = files[0]
          if (file?.type.includes('image')) {
            dispatch(postComposerSlice.actions.setAttachment(file))
          }
        }
      }}
      onKeyDown={(e) => {
        if (files.length) {
          e.preventDefault()
          if (['Tab', 'ArrowRight', 'ArrowDown'].includes(e.key)) {
            dispatch(nextFile())
          } else if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
            dispatch(prevFile())
          }
        }
      }}
    >
      {/* Show only plan selection window to the user */}
      {(!user?.data || user?.data?.stripeSubscriptionId) && <TopNavBarComponent user={user} />}
      <CommonMessage
        open={!!user.error}
        title={user.error ?? ''}
        message="Try again"
        okMessage="Ok"
        handleClose={() => {
          dispatch(userSlice.actions.setError(''))
        }}
      />
      <CommonMessage
        open={!!postToDiscardOrDelete}
        title="Are you sure"
        message="Please confirm post deletion"
        cancel="Cancel"
        okMessage="Confirm"
        handleClose={(c) => {
          dispatch(deletePost(c !== true))
        }}
      />
      <FileModalComponent />
      {user?.data && (
        <>
          <PostComposer />
          <TimeTargetModal />
        </>
      )}

      {user?.data?.profiles && user?.data?.stripeSubscriptionId && (!linkedinProfile || !twitterProfile) && (
        <LinkedInConnectorModalComponent user={user} />
      )}
      <main>
        <IntercomComponent />
        <Routes>
          {!user?.data && (
            <>
              <Route path="/" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/password-reset" element={<PasswordResetRequestForm />} />
              <Route path="/password-reset/:key" element={<PasswordResetForm />} />
              <Route path="/pricing" element={<PricingPage />} />
              {/* <Route path="*" element={<Navigate to="/schedule" />} /> */}
            </>
          )}
          {user?.data && user?.data?.stripeSubscriptionId && (
            // {user?.data && user?.data?.stripeSubscriptionId && (
            <>
              <Route path="/" element={<SchedulePage />} />
              <Route path="/schedule" element={<SchedulePage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/ideas" element={<IdeasPage />} />
              <Route path="/draft" element={<DraftsPage />} />
              <Route path="/timeline" element={<TimelinePage />} />
              <Route path="/timetargets" element={<TimeTargetPage />} />
              <Route path="/connections" element={<ConnectionsPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/teams" element={<TeamsPage />} />
              <Route path="/organization" element={<OrganizationPage />} />
              {/* <Route path="*" element={<Navigate to="/schedule" />} /> */}
            </>
          )}
          {user?.data && !user?.data?.stripeSubscriptionId && (
            <>
              <Route path="*" element={<PricingPage />} />
            </>
          )}
          <Route path="/invite/:token" element={<InvitationPage />} />
          <Route path="/callback/linkedin" element={<LinkedInCallback />} />
          <Route path="/callback/twitter" element={<LinkedInCallback />} />
        </Routes>
      </main>
      <Footer />
    </div>
  )
}
