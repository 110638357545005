import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { getLinkedinProfileHelper } from 'shared-data/helpers/profiles/getLinkedinProfile.helper'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import Layout from '../../components/layout'
import { useUserApi } from '../../hooks/useUserApi'
import { linkedInLoginConfig } from '../../helpers/linkedInLogin.helper'
import { RootState } from '../../store/RootState'
import axios from '../../lib/axios'
import { userSlice } from '../../state/user.slice'
import { setDocumentTitleHelper } from '../../helpers/setDocumentTitle.helper'
import { spacing } from '../../config/theme'
import { DangerActionConfirmComponent, DangerActions } from './dangerActionConfirm.component'

export const SettingsPage = () => {
  const user = useSelector((state: RootState) => state.user.user)

  const theme = useTheme()

  const linkedinProfile = getLinkedinProfileHelper(user.data)
  const { updateUser, load } = useUserApi()

  const { linkedInLogin } = useLinkedIn(linkedInLoginConfig({ updateUser, load }))

  const dispatch = useDispatch()

  const [isSavedToast, setIsSavedToast] = useState(false)
  const [dangerType, setDangerType] = useState<keyof typeof DangerActions | null>(null)

  const onChange = async (prop: string, value: string | number | boolean) => {
    dispatch(userSlice.actions.updateField({ prop, value }))
    await axios.post('/private/settings', { prop, value })
    setIsSavedToast(true)
  }

  useEffect(() => {
    setDocumentTitleHelper('Settings')
  }, [])

  return (
    <Layout>
      <Container>
        <Box>
          <Typography variant="h3">Settings</Typography>
          <Typography mt={5}>
            This is the settings page of Content Creator tool. Here you can control certain behaviours of our features.
          </Typography>
        </Box>
        <Box mt={5} display="grid" sx={{ gridTemplateColumns: '1fr 1fr', gap: theme.spacing(3) }}>
          <Snackbar
            open={isSavedToast}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoHideDuration={6000}
            onClose={() => setIsSavedToast(false)}
            color="success"
            action={
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsSavedToast(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          >
            <Alert onClick={() => setIsSavedToast(false)} severity="success" sx={{ width: '100%' }}>
              Settings successfully updated!
            </Alert>
          </Snackbar>
          <Paper sx={{ p: 4 }}>
            <Box>
              <Typography variant="h6">Posts scheduling</Typography>
              <Typography variant="body2" mt={1}>
                Here you can manage various settings related to how you will post
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={user.data?.isAutoPromotion}
                      onChange={(event, checked) => onChange('isAutoPromotion', checked)}
                    />
                  }
                  label="Automatic Content Creator promotion on your posts"
                />
              </FormGroup>
              {/* <FormGroup> */}
              {/*  <FormControlLabel */}
              {/*    control={ */}
              {/*      <Switch */}
              {/*        checked={user.data?.isSkipWeekends} */}
              {/*        onChange={(event, checked) => onChange('isSkipWeekends', checked)} */}
              {/*      /> */}
              {/*    } */}
              {/*    label="Skip weekends on schedule time suggestions" */}
              {/*  /> */}
              {/* </FormGroup> */}
              <FormGroup sx={{ mt: 3 }}>
                <TextField
                  type="number"
                  label="How many posts you want to post per 7 days"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={user.data?.schedulePostsPerWeek || ''}
                  helperText="For example, value 7 means 1 post per day. 14 means two posts per day."
                  onChange={(event) => onChange('schedulePostsPerWeek', event.target.value)}
                />
              </FormGroup>
              {/* <FormGroup sx={{ mt: 2 }}> */}
              {/*  <TextField */}
              {/*    type="number" */}
              {/*    label="How many days upfront you want to default" */}
              {/*    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} */}
              {/*    value={user.data?.schedulePostDaysAhead || ''} */}
              {/*    helperText="Date set automatically when you start composing a new post. This is applied only if the maximum posts for that day is not reached yet." */}
              {/*    onChange={(event) => onChange('schedulePostDaysAhead', event.target.value)} */}
              {/*  /> */}
              {/* </FormGroup> */}
            </Box>
          </Paper>
          <Paper sx={{ p: 4 }}>
            <Box>
              <Typography variant="h6">Social Networks connect</Typography>
              <Typography variant="body2" mt={1}>
                Here you can connect social networks or reset connections to them.
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-start">
              <Button
                type="button"
                color="success"
                variant="contained"
                onClick={() => {
                  linkedInLogin()
                }}
                size="large"
              >
                {linkedinProfile ? 'Reset LinkedIn access' : 'Grant LinkedIn access'}
              </Button>
            </Box>
          </Paper>
          <RedPaper>
            {dangerType && (
              <DangerActionConfirmComponent
                dangerType={dangerType}
                onClose={() => {
                  setDangerType(null)
                }}
              />
            )}
            <Box>
              <Typography variant="h6">Danger zone</Typography>
              <Typography variant="body2" mt={1}>
                Here you should be careful
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-start">
              <Button
                type="button"
                color="error"
                variant="outlined"
                onClick={() => {
                  setDangerType('DELETE_ACCOUNT')
                }}
                size="small"
              >
                Delete my account
              </Button>
            </Box>
          </RedPaper>
        </Box>
      </Container>
    </Layout>
  )
}

const RedPaper = styled(Paper)(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  box-shadow: 0px 2px 1px -1px ${theme.palette.error.main}, 0px 1px 1px 0px ${
    theme.palette.error.main
  }, 0px 1px 3px 0px ${theme.palette.error.main};
  padding: ${spacing(4)}px;
`
)
