import React, { FC } from 'react'
import { LinkedinTargetInterface } from 'shared-data/interfaces/linkedin/linkedinTarget.interface'
import { Box, Typography } from '@mui/material'
import { LinkedIn as LinkedInIcon } from '@mui/icons-material'

interface Props {
  target: LinkedinTargetInterface
}

export const TeamTargetComponent: FC<Props> = ({ target }) => {
  return (
    <Box
      display="inline-block"
      sx={{
        backgroundColor: '#0a66c2',
        p: 1,
        mr: 2,
        pr: 2,
        borderRadius: 1,
        ...(target.invalid ? { opacity: 0.5, pointerEvents: 'none' } : {}),
      }}
    >
      <Box display="flex" alignItems="center">
        <LinkedInIcon sx={{ color: 'white' }} fontSize="large" />
        {target?.image && <img src={target?.image} alt="" width={30} style={{ marginLeft: '8px' }} />}
        <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }} ml={1}>
          {target?.name}
        </Typography>
      </Box>
    </Box>
  )
}
