import styled from 'styled-components'
import { spacing } from '../config/theme'

export const Wrapper = styled.div`
  min-height: 50vh;
  padding-bottom: ${spacing(4)}px;
`

export const InputWrapper = styled.div`
  margin-bottom: ${spacing(2)}px;
  margin-right: 2px;
  .public-DraftEditorPlaceholder-root {
    pointer-events: none;
  }
`

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ErrorWrapper = styled.div`
  color: red;
  margin-bottom: ${spacing(1)}px;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
`

export const ServerErrorWrapper = styled.div`
  color: red;
  margin-bottom: ${spacing(1)}px;
`

export const ForgotPasswordText = styled.span`
  color: #3e91cf;
`

export const AcceptTaCWrapper = styled.div`
  margin-bottom: ${spacing(2)}px;

  input {
    display: inline-block;
    width: auto;
    margin-right: ${spacing(1)}px;
  }

  label {
    margin: 0;
    display: inline-block;
  }
`
