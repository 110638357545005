import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { QueryInterface } from 'shared-data/interfaces/query.interface'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import { Button } from '../components/button'
import { shadow, spacing } from '../config/theme'
import { ColumnEditComponent } from '../components/Column/ColumnEdit.component'
import { ColumnComponent } from '../components/Column/Column.component'

export const IdeasPage = () => {
  const [queries, setQueries] = useState<QueryInterface[]>([])
  const [isNewQueryOpen, setIsNewQueryOpen] = useState(false)

  const fetchQueries = async () => {
    const resp = await axios.get<QueryInterface[]>(`/private/query/get`)
    if (resp?.data) {
      setQueries(resp.data)
    }
  }

  const saveQuery = async (query: string, _id?: string) => {
    const resp = await axios.post(`/private/query/edit`, { query, _id })
    if (resp?.data) {
      await fetchQueries()
      setIsNewQueryOpen(false)
    }
  }

  const deleteQuery = async (_id: string) => {
    const yes = window.confirm('Are you sure you want to delete this?')
    if (yes) {
      const resp = await axios.post(`/private/query/delete`, { _id })
      if (resp?.data) {
        await fetchQueries()
      }
    }
  }

  useEffect(() => {
    void fetchQueries()
  }, [])

  return (
    <Layout>
      <Container>
        <ActionsWrapper>
          <Button label="Add" onClick={() => setIsNewQueryOpen(true)} />
        </ActionsWrapper>
      </Container>
      <WideContainer>
        <Wrapper>
          <ColumnsWrapper>
            <ColumnsInnerWrapper>
              {isNewQueryOpen && (
                <ColumnWrapper>
                  <ColumnEditComponent
                    query=""
                    onSave={(q) => saveQuery(q, undefined)}
                    onCancel={() => setIsNewQueryOpen(false)}
                  />
                </ColumnWrapper>
              )}
              {queries &&
                queries.map((query) => (
                  <ColumnWrapper key={query._id}>
                    <ColumnComponent query={query} onDelete={deleteQuery} />
                  </ColumnWrapper>
                ))}
            </ColumnsInnerWrapper>
          </ColumnsWrapper>
        </Wrapper>
      </WideContainer>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing(3)}px;
`

const ColumnsWrapper = styled.div``

const ColumnsInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  height: 800px;
  padding: ${spacing(3)}px;
`
const ColumnWrapper = styled.div`
  ${shadow()}
  padding: ${spacing(2)}px;
  margin: 0 ${spacing(2)}px;
  min-width: 350px;
`
const WideContainer = styled.div`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 100%;
`
