import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { InputWrapper, ServerErrorWrapper, Wrapper } from 'web/src/styles/form.styles'
import { useParams } from 'react-router-dom'
import { Box, Button, TextField } from '@mui/material'
import { useUserApi } from '../../../hooks/useUserApi'
import { PageTitleAndExcerpt } from '../../PageTitleAndExcerpt/PageTitleAndExcerpt'
import { FormWrapper } from './wrapper.form'
import { RootState } from '../../../store/RootState'

import { ReCaptchaComponent } from '../../ReCaptcha/ReCaptcha.component'

const PasswordResetSchema = Yup.object().shape({
  password: Yup.string().min(5, 'Password is too short!').max(80, 'Too Long!').required('Required'),
  // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{5,})/, 'Must Contain 5 Characters, One Uppercase, One Lowercase'),
  changepassword: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
  }),
})

export const PasswordResetForm = () => {
  const user = useSelector((state: RootState) => state.user.user)
  const { passwordReset } = useUserApi()
  const { key } = useParams()
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)

  return (
    <Wrapper>
      <PageTitleAndExcerpt title="Set your new password" />
      <FormWrapper>
        <Formik
          initialValues={{
            password: '',
            changepassword: '',
            resetPasswordToken: key,
            recaptcha: '',
          }}
          validationSchema={PasswordResetSchema}
          onSubmit={({ resetPasswordToken, password, recaptcha }) => {
            // same shape as initial values
            void passwordReset(resetPasswordToken, password, recaptcha)
          }}
        >
          {({ errors, touched, setFieldValue, handleChange, values }) => (
            <Form>
              <Field name="resetPasswordToken" type="hidden" />
              <InputWrapper>
                <TextField
                  name="password"
                  type="password"
                  placeholder="New password"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
                {/* {errors.password && touched.password ? <ErrorWrapper>{errors.password}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="changepassword"
                  type="password"
                  placeholder="Repeat new password"
                  value={values.changepassword}
                  onChange={handleChange}
                  error={touched.changepassword && Boolean(errors.changepassword)}
                  helperText={touched.changepassword && errors.changepassword}
                  fullWidth
                />
                {/* {errors.changepassword && touched.changepassword ? ( */}
                {/*  <ErrorWrapper>{errors.changepassword}</ErrorWrapper> */}
                {/* ) : null} */}
              </InputWrapper>

              {user?.error && <ServerErrorWrapper>{user?.error}</ServerErrorWrapper>}

              <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <div />

                <ReCaptchaComponent
                  onChange={(val) => {
                    setFieldValue('recaptcha', val)
                    if (val) {
                      setIsRecaptchaLoaded(true)
                    }
                  }}
                />

                <Button type="submit" disabled={!isRecaptchaLoaded} color="success" variant="contained" size="large">
                  Request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
