import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import { asyncDataInitialState } from '../store/AsyncData'
import { UserState } from './user.state'

const initialState: UserState = {
  user: asyncDataInitialState,
  token: '',
  shouldReconnectSocket: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setShouldReconnectSocket: (state, action: PayloadAction<boolean>) => {
      state.shouldReconnectSocket = action.payload
    },
    setData: (state, action: PayloadAction<UserInterface | null>) => {
      state.user.loading = false
      state.user.data = action.payload
    },
    setLoader: (state, action: PayloadAction<boolean | undefined>) => {
      state.user.loading = action.payload === undefined ? true : action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.user.loading = false
      state.user.error = action.payload
    },
    updateField: (state, action: PayloadAction<{ prop: string; value: any }>) => {
      if (state.user.data) {
        state.user.data[action.payload?.prop] = action.payload?.value
      }
    },
  },
})
