import React, { FC } from 'react'
import { Chart } from 'react-google-charts'
import { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { Box, useTheme } from '@mui/material'

interface Props {
  value: Array<[string, number, number]>
}

export const PulseChartComponent: FC<Props> = ({ value }) => {
  const theme = useTheme()

  const data = [['Day', 'Social actions per day', 'Social actions per day target average'], ...value]

  const options: ChartWrapperOptions['options'] = {
    is3D: false,
    title: undefined,
    legend: { position: 'none' },
    series: {
      0: { color: theme.palette.primary.main, areaOpacity: 0.8, enableInteractivity: true, visibleInLegend: true },
      1: {
        areaOpacity: 0.5,
        color: '#c4c4c4',
        enableInteractivity: false,
        type: 'area',
        visibleInLegend: false,
      },
    },
    chartArea: { left: 0, top: 0, width: '99%', height: '40' },
    hAxis: { title: '2 week pulse', textPosition: 'bottom' },
  }

  return (
    <Box pr={3}>
      <Chart chartType="AreaChart" width="100%" height={60} data={data} options={options} />
    </Box>
  )
}
