import axios from '../lib/axios'
import { randomString } from '../lib/randomString'

let codeClosure = ''
export const linkedInLoginConfig = ({ updateUser, load }) => {
  const { state, linkedinRedirectUri } = prepareLinkedinLogin()
  return {
    clientId: String(process.env.REACT_APP_LINKEDIN_CLIENT_ID),
    redirectUri: linkedinRedirectUri, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    scope: [
      ...new Set([
        'r_liteprofile', // Use your name and photo
        'r_basicprofile', // Use your basic profile including your name, photo, headline, and public profile URL
        'w_member_social', // Create, modify, and delete posts, comments, and reactions on your behalf
        'r_member_social', // Retrieve your posts, comments, reactions, and other engagement data
        'r_1st_connections_size', // Use your 1st-degree connections' data
        'r_emailaddress', // Use the primary email address associated with your LinkedIn account
        'rw_organization_admin', // Manage your organization's pages and retrieve reporting data
        'r_organization_social', // Retrieve your organization's posts, comments, reactions, and other engagement data
        'r_ads_reporting', // Retrieve reporting for your advertising accounts
        'w_organization_social', // Create, modify, and delete posts, comments, and reactions on your organization's behalf
        'r_organization_admin', // Retrieve your organization's pages and their reporting data (including follower, visitor and content analytics)
        'r_ads', // Retrieve your advertising accounts
        'rw_ads', // Manage your advertising accounts
      ]),
    ].join(','),
    state,
    onSuccess: async (code) => {
      if (codeClosure === code) return
      console.log(code)
      codeClosure = code
      const response = await axios.post('/private/linkedin/code', {
        code,
        state,
      })
      console.log('response', response)
      // console.log('response', response)
      await updateUser(response.data.user)
      await load()
    },
    onError: (error) => {
      console.log(error)
      // window.alert(error.errorMessage);
    },
  }
}

export const prepareLinkedinLogin = () => {
  const state = randomString(10)
  const linkedinRedirectUri = `${window.location.origin}/callback/linkedin`
  void axios.post('/private/linkedin/prepare', { state, linkedinRedirectUri })
  return {
    state,
    linkedinRedirectUri,
  }
}
