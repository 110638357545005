import React, { FC, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { InputWrapper, ServerErrorWrapper, Wrapper } from 'web/src/styles/form.styles'
import { Box, Button, TextField } from '@mui/material'
import { useUserApi } from '../../../hooks/useUserApi'
import { PageTitleAndExcerpt } from '../../PageTitleAndExcerpt/PageTitleAndExcerpt'
import { FormWrapper } from './wrapper.form'
import { RootState } from '../../../store/RootState'
import { ReCaptchaComponent } from '../../ReCaptcha/ReCaptcha.component'

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(3, 'Too Short!').max(80, 'Too Long!').required('Required'),
})

interface Props {
  email?: string
}

export const SignInForm: FC<Props> = ({ email }) => {
  const user = useSelector((state: RootState) => state.user.user)
  const { signIn } = useUserApi()
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)

  return (
    <Wrapper>
      <PageTitleAndExcerpt title="Sign In to Content Creators Hub" />
      <FormWrapper>
        <Formik
          initialValues={{
            email,
            password: '',
            recaptcha: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={({ email, password, recaptcha }) => {
            // same shape as initial values
            void signIn(email, password, recaptcha)
          }}
        >
          {({ errors, touched, setFieldValue, handleChange, values }) => (
            <Form>
              <InputWrapper>
                <TextField
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
                {/* {errors.email && touched.email ? <ErrorWrapper>{errors.email}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
                {/* {errors.password && touched.password ? <ErrorWrapper>{errors.password}</ErrorWrapper> : null} */}
              </InputWrapper>

              {user?.error && <ServerErrorWrapper>{user?.error}</ServerErrorWrapper>}

              <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  component={Link}
                  to="/password-reset"
                  type="submit"
                  disabled={!isRecaptchaLoaded}
                  variant="text"
                  size="large"
                >
                  Forgot Password
                </Button>

                <ReCaptchaComponent
                  onChange={(val) => {
                    setFieldValue('recaptcha', val)
                    if (val) {
                      setIsRecaptchaLoaded(true)
                    }
                  }}
                />

                <Button type="submit" disabled={!isRecaptchaLoaded} color="success" variant="contained" size="large">
                  Sign In
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
