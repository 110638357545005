import React from 'react'

export const BackgroundDecorationComponent = () => (
  <svg
    width="684"
    height="366"
    viewBox="0 0 684 366"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: 'absolute', right: -5, bottom: -5 }}
  >
    <path
      d="M683.5 366H4.99999C1.33332 358 -3.80001 335.8 4.99999 311C16 280 100.5 237 136.5 218C172.5 199 220 96 266.5 79.5C313 63 398.5 99.5 434 96C469.5 92.5 503 45.5 556 18C598.4 -3.99996 658.667 -0.499959 683.5 4.00004V366Z"
      fill="#1976D2"
      fillOpacity="0.04"
    />
  </svg>
)
