import { FeatureConfigInterface } from './featureConfig.interface'
import { FeatureTypes } from './feature.types'
import { PlanTypes } from './plan.types'

/**
 * This is the config of all implemented features on this platform.
 */
export const featuresConfig: FeatureConfigInterface = {
  [FeatureTypes.POST_TEXT_IMAGE_VIDEO_LINK]: {
    [PlanTypes.FREE]: { enabled: true, specialLimit: true },
    [PlanTypes.PERSONAL]: { enabled: true },
    [PlanTypes.BUSINESS]: { enabled: true },
    [PlanTypes.ENTERPRISE]: { enabled: true },
  },
  [FeatureTypes.TIME_TARGET]: {
    [PlanTypes.FREE]: { enabled: false, specialLimit: false },
    [PlanTypes.PERSONAL]: { enabled: true },
    [PlanTypes.BUSINESS]: { enabled: true },
    [PlanTypes.ENTERPRISE]: { enabled: true },
  },
}
