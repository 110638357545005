import React, { FC, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { AcceptTaCWrapper, ErrorWrapper, InputWrapper, Wrapper } from 'web/src/styles/form.styles'
import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Button, Typography } from '@mui/material'
import { useUserApi } from '../../../hooks/useUserApi'
import { PageTitleAndExcerpt } from '../../PageTitleAndExcerpt/PageTitleAndExcerpt'
import { FormWrapper } from './wrapper.form'
import { ReCaptchaComponent } from '../../ReCaptcha/ReCaptcha.component'

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(5, 'Password is too short!').max(80, 'Too Long!').required('Required'),
  // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{5,})/, 'Must Contain 5 Characters, One Uppercase, One Lowercase'),
  changepassword: Yup.string().when('password', {
    is: (val) => !!(val && val.length > 0),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
  }),
  acceptTerms: Yup.bool().oneOf([true], 'To proceed, you must read and agree with our Terms and Conditions'),
  acceptPrivacy: Yup.bool().oneOf([true], 'To proceed, you must read and agree with our Privacy Policy'),
})

interface Props {
  token?: string
  email?: string
}

export const SignupForm: FC<Props> = ({ token, email = '' }) => {
  const { signUp } = useUserApi()
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)

  return (
    <Wrapper>
      <PageTitleAndExcerpt title="Sign Up to Content Creators Hub" />
      <FormWrapper>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email,
            password: '',
            changepassword: '',
            acceptTerms: false,
            acceptPrivacy: false,
            recaptcha: '',
            token,
          }}
          validationSchema={SignupSchema}
          onSubmit={({ email, password, firstName, lastName, recaptcha }) => {
            // same shape as initial values
            void signUp(email, password, firstName, lastName, recaptcha, token)
          }}
        >
          {({ errors, touched, setFieldValue, handleChange, values }) => (
            <Form>
              {Boolean(token) && (
                <Box mb={3}>
                  <Typography variant="body2">
                    You have also been invited to an organization. After you sign up you will automatically join the
                    team!
                  </Typography>
                  <Typography variant="body2" mt={2}>
                    In case you already have an account with this email - login and click the link again.
                  </Typography>
                </Box>
              )}
              <InputWrapper>
                <TextField
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={values.firstName}
                  onChange={handleChange}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  fullWidth
                />
                {/* {errors.firstName && touched.firstName ? <ErrorWrapper>{errors.firstName}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={values.lastName}
                  onChange={handleChange}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  fullWidth
                />
                {/* {errors.lastName && touched.lastName ? <ErrorWrapper>{errors.lastName}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={Boolean(token)}
                  fullWidth
                />
                {/* {errors.email && touched.email ? <ErrorWrapper>{errors.email}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="password"
                  type="password"
                  placeholder="New password"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
                {/* {errors.password && touched.password ? <ErrorWrapper>{errors.password}</ErrorWrapper> : null} */}
              </InputWrapper>
              <InputWrapper>
                <TextField
                  name="changepassword"
                  type="password"
                  placeholder="Repeat new password"
                  value={values.changepassword}
                  onChange={handleChange}
                  error={touched.changepassword && Boolean(errors.changepassword)}
                  helperText={touched.changepassword && errors.changepassword}
                  fullWidth
                />
                {/* {errors.changepassword && touched.changepassword ? ( */}
                {/*  <ErrorWrapper>{errors.changepassword}</ErrorWrapper> */}
                {/* ) : null} */}
              </InputWrapper>

              <AcceptTaCWrapper>
                <FormGroup sx={{ alignItems: 'flex-start' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptTerms"
                        defaultChecked={false}
                        value={values.acceptTerms}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <>
                        Accept{' '}
                        <a href="https://www.cortip.com/terms-and-conditions" target="_blank" rel="noreferrer">
                          Terms & Conditions
                        </a>
                      </>
                    }
                  />
                  {errors.acceptTerms && touched.acceptTerms ? <ErrorWrapper>{errors.acceptTerms}</ErrorWrapper> : null}
                </FormGroup>
              </AcceptTaCWrapper>

              <AcceptTaCWrapper>
                <FormGroup sx={{ alignItems: 'flex-start' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptPrivacy"
                        defaultChecked={false}
                        value={values.acceptPrivacy}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <>
                        Accept{' '}
                        <a href="https://www.cortip.com/privacy-policy" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </>
                    }
                  />
                  {errors.acceptPrivacy && touched.acceptPrivacy ? (
                    <ErrorWrapper>{errors.acceptPrivacy}</ErrorWrapper>
                  ) : null}
                </FormGroup>
              </AcceptTaCWrapper>

              <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <div />

                <ReCaptchaComponent
                  onChange={(val) => {
                    setFieldValue('recaptcha', val)
                    if (val) {
                      setIsRecaptchaLoaded(true)
                    }
                  }}
                />

                <Button type="submit" disabled={!isRecaptchaLoaded} color="success" variant="contained" size="large">
                  Sign Up
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
