import { PlanTypes } from 'shared-data/features/plan.types'

export interface FeatureProp {
  name: string
  description?: string
  inFree: boolean
  inPersonal: boolean
  inBusiness: boolean
  noteInFree?: string
  noteInPersonal?: string
  noteInBusiness?: string
  overrideInFree?: string
  overrideInPersonal?: string
  overrideInBusiness?: string
}

export const features: FeatureProp[] = [
  {
    inFree: true,
    inPersonal: true,
    inBusiness: true,
    name: 'Sharing on personal profile',
    description: 'You will be able to share text, image, link in text, link in the comments on your profile!',
    noteInFree: '* “This was posted via Cortip Content Creator“ or similar comment is added to each post',
    noteInPersonal: 'Unlimited',
    noteInBusiness: 'Unlimited',
  },
  {
    inFree: true,
    inPersonal: true,
    inBusiness: true,
    name: 'Create draft posts',
    description: 'You will be able to create drafts for future editing and drafts with good ideas for the future.',
  },
  {
    inFree: true,
    inPersonal: true,
    inBusiness: true,
    name: 'Memos',
    description:
      'You will be able to use our mobile app to share great content into drafts and create audio memos for future posts!',
  },
  { inFree: true, inPersonal: true, inBusiness: true, name: 'Post scheduling', description: '' },
  { inFree: true, inPersonal: true, inBusiness: true, name: 'Post statistics', description: '' },
  { inFree: true, inPersonal: true, inBusiness: true, name: 'Timezone targeting', description: '' },
  {
    inFree: true,
    inPersonal: true,
    inBusiness: true,
    name: 'Snippets and templates for posts',
    description: '',
    overrideInFree: '1',
  },
  {
    inFree: true,
    inPersonal: true,
    inBusiness: true,
    name: 'Posting to the business Page',
    description: '',
    overrideInFree: '1',
  },
  {
    inFree: false,
    inPersonal: true,
    inBusiness: true,
    name: 'Auto share post from business page to personal profile',
    description: '',
  },
  { inFree: false, inPersonal: true, inBusiness: true, name: 'Analyse tags and hashtags performance', description: '' },
  {
    inFree: false,
    inPersonal: true,
    inBusiness: true,
    name: 'Teams, access levels & post scheduling targets',
    description: '',
  },
  {
    inFree: false,
    inPersonal: false,
    inBusiness: true,
    name: 'Post draft reviews and confirmation chain',
    description: '',
  },
  { inFree: false, inPersonal: false, inBusiness: true, name: 'Posts kanban board', description: '' },
  {
    inFree: false,
    inPersonal: false,
    inBusiness: true,
    name: 'Auto share post from business page to the whole team personal profiles',
    description: '',
  },
]

export const ANNUAL_PRICE_TOOLTIP = 'Price is applied only if subscribing for an annual plan'

export const prices = {
  [PlanTypes.FREE]: {
    monthly: 0,
    annual: 0,
    annualPerMonth: 0,
  },
  [PlanTypes.PERSONAL]: {
    monthly: 39,
    annual: 390,
    annualPerMonth: Number(390 / 12).toFixed(1),
  },
  [PlanTypes.BUSINESS]: {
    monthly: 199,
    annual: 1990,
    annualPerMonth: Number(1990 / 12).toFixed(1),
  },
}
