import React, { ChangeEvent, createRef, memo, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Autorenew as AutoRenewIcon, Delete as DeleteIcon, Image as ImageIcon } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { useDispatch, useSelector } from 'react-redux'
import { PostAttachmentInterface } from 'shared-data/interfaces/postAttachment.interface'
import { RootState } from '../../store/RootState'
import { postComposerSlice } from '../../state/postComposer.slice'

interface DropZone {
  selectImage: () => void
}

const DropZoneComponent: React.FC<DropZone> = memo(({ selectImage }) => {
  const [highlightZone, setHighlight] = useState(false)

  return (
    <DropZoneWrapper
      className={highlightZone ? 'highlighted' : ''}
      onClick={selectImage}
      onDrop={() => {
        setHighlight(false)
      }}
      onDragOver={() => {
        setHighlight(true)
      }}
      onMouseDown={(e) => {
        if (e.button === 2) (e.target as HTMLFormElement).contentEditable = String(true)
        // wait just enough for 'contextmenu' to fire
        setTimeout(() => {
          ;(e.target as HTMLFormElement).contentEditable = String(false)
        }, 20)
      }}
      onMouseLeave={() => setHighlight(false)}
      onDragLeave={() => setHighlight(false)}
    >
      <Box>
        <ImageIcon fontSize="large" />
        <OndemandVideoIcon fontSize="large" />
      </Box>
      <Typography variant="subtitle1">Click, Paste or Drag & Drop attachment here</Typography>
      <Typography variant="body2">Accepted images are in PNG, JPG, GIF formats and no more than 50 MB.</Typography>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        Accepted videos are in PASF, FLV, MPEG-1 and MPEG-4, MKV, WebM, H264/AVC, MP4, VP8 and VP9, WMV2 and WMV3
        formats and no more than 5 GB.
      </Typography>
    </DropZoneWrapper>
  )
})

interface Preview {
  attachment: File | PostAttachmentInterface
  onClearClick: () => void
  selectAttachment: () => void
}

const PreviewComponent: React.FC<Preview> = memo(({ attachment, onClearClick, selectAttachment }) => {
  const [highlightZone, setHighlight] = useState(false)
  const d = useDispatch()

  return (
    <PreviewWrapper
      className={highlightZone ? 'highlighted' : ''}
      onDrop={() => {
        setHighlight(false)
      }}
      onDragOver={() => {
        setHighlight(true)
      }}
      onDragLeave={() => setHighlight(false)}
      onMouseLeave={() => setHighlight(false)}
      onMouseDown={(e) => {
        if (e.button === 2) (e.target as HTMLFormElement).contentEditable = String(true)
        // wait just enough for 'contextmenu' to fire
        setTimeout(() => {
          ;(e.target as HTMLFormElement).contentEditable = String(false)
        }, 20)
      }}
      onPaste={(e) => {
        const { items } = e.clipboardData
        if (items) {
          const toSet = items[0]
          if (toSet.type.includes('image') && toSet.kind === 'file') {
            const blob = toSet.getAsFile()
            d(postComposerSlice.actions.setAttachment(blob))
          }
        }
      }}
    >
      <AttachmentWrapper>
        {attachment?.type!.includes('image') && (
          <Img
            src={(attachment as PostAttachmentInterface).uploadSrc ?? URL.createObjectURL(attachment as File)}
            alt=""
          />
        )}
        {attachment?.type!.includes('video') && (
          <Video
            src={(attachment as PostAttachmentInterface).uploadSrc ?? URL.createObjectURL(attachment as File)}
            controls
          >
            <track kind="captions" />
          </Video>
        )}
        <RemoveImgButton onClick={onClearClick}>
          <DeleteIcon color="primary" />
        </RemoveImgButton>
        <RefreshImgButton onClick={selectAttachment}>
          <AutoRenewIcon color="primary" />
        </RefreshImgButton>
      </AttachmentWrapper>
    </PreviewWrapper>
  )
})

export const SUPPORTED_FORMATS = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'video/x-ms-asf',
  'video/x-flv',
  'video/mpeg',
  'video/mp4',
  'video/x-matroska',
  'video/webm',
  'video/h264',
  'video/x-ms-wmv',
].join(', ')

type dropZone = {
  onChange: (a: File | PostAttachmentInterface) => void
}

export const AttachmentDropZoneComponent: React.FC<dropZone> = memo(({ onChange }) => {
  const {
    post: { data: post },
    attachment,
  } = useSelector((state: RootState) => state.postComposer)

  const d = useDispatch()

  useEffect(() => {
    if (attachment) {
      onChange(attachment)
    }
  }, [attachment])

  const inputRef = createRef<HTMLInputElement>()

  const selectAttachment = () => {
    inputRef.current?.click()
  }

  const onFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file: File = e.target.files[0]
      d(postComposerSlice.actions.setAttachment(file))
      // @ts-ignore
    }
  }

  useEffect(() => {
    if (post) {
      d(postComposerSlice.actions.setAttachment(post.attachment ?? null))
    }
  }, [post])

  const onClearClick = () => {
    d(postComposerSlice.actions.setAttachment(null))
  }
  return (
    <>
      <FileInput type="file" ref={inputRef} accept={SUPPORTED_FORMATS} onChange={onFileSelected} />
      {attachment && attachment.type ? (
        <PreviewComponent attachment={attachment} onClearClick={onClearClick} selectAttachment={selectAttachment} />
      ) : (
        <DropZoneComponent selectImage={selectAttachment} />
      )}
    </>
  )
})

const FileInput = styled('input')(() => ({
  display: 'none',
}))

const AttachmentWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}))

const Img = styled('img')(() => ({ width: '100%' }))
const Video = styled('video')(() => ({ width: '100%' }))

const RemoveImgButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  marginLeft: theme.spacing(2),
  color: theme.palette.grey[500],
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}))

const RefreshImgButton = styled(RemoveImgButton)(({ theme }) => ({
  top: theme.spacing(7),
}))

const DropZoneWrapper = styled('div')(({ theme }) => ({
  color: 'darkslategray',
  backgroundColor: 'white',
  padding: theme.spacing(2),
  cursor: 'pointer',
  border: `2px dashed ${theme.palette.secondary.dark}`,
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '&:hover, &.highlighted': {
    backgroundColor: '#f0f0f0',
  },
}))

const PreviewWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  color: 'darkslategray',
  backgroundColor: 'white',
  padding: theme.spacing(2),
  cursor: 'default',
  border: `2px dashed ${theme.palette.secondary.dark}`,
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '&:hover, &.highlighted': {
    backgroundColor: 'aliceblue',
  },
}))
