import React from 'react'
import { Avatar, Box } from '@mui/material'
import styled from 'styled-components'
import { CommentInterface } from 'shared-data/interfaces/comment.interface'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/RootState'

interface Props {
  comment: CommentInterface
}

export const CommentBalloonComponent: React.FC<Props> = ({ comment }) => {
  const user = useSelector((state: RootState) => state.user.user.data)
  const isMe = Boolean(comment.user?._id === user?._id)
  return (
    <Box sx={{ mb: 2, mx: 1 }}>
      <Box display="flex" alignItems="center">
        <Avatar src={comment.user?.avatar} alt="Avatar" sx={{ width: '30px', height: '30px', mr: 1 }} />
        <Box>
          <Name>{comment.user ? `${comment.user.firstName} ${comment.user?.lastName}` : 'Deleted User'}</Name>
          <Time>{comment.createdAt}</Time>
        </Box>
      </Box>
      <Box sx={{ mt: 0.3 }}>
        <Triangle isMe={isMe} />
        <Baloon isMe={isMe}>{comment.text}</Baloon>
      </Box>
    </Box>
  )
}

const Triangle = styled.div<{ isMe: boolean }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  //border-bottom: 5px solid #2058a5;
  border-bottom: 5px solid ${(props) => (!props.isMe ? '#2058a5' : '#999999')};
  margin-left: 10px;
`

const Baloon = styled.div<{ isMe: boolean }>`
  background-color: ${(props) => (!props.isMe ? '#2058a5' : '#999999')};
  color: white;
  padding: 8px 13px;
  font-size: 14px;
  border-radius: 8px;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
`
const Time = styled.div`
  font-size: 12px;
  line-height: 1;
`
