import React from 'react'
import styled from 'styled-components'
import { anchorifyTextLinks } from '../../../lib/anchorifyTextLinks.lib'
import { hashtagTextToLinkedInSearchLinkLib } from '../../../lib/hashtagTextToLinkedInSearchLink.lib'
import { newLineToHTMLBR } from '../../../lib/newLineToHTMLBR.lib'

interface Props {
  text: string
}

export const PostTextComponent: React.FC<Props> = ({ text }) => {
  const isLong = text.length > 500

  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <Wrapper>
      <Triangle />
      <Text isExpanded={isExpanded} isLong={isLong}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p
          dangerouslySetInnerHTML={{
            __html: newLineToHTMLBR(hashtagTextToLinkedInSearchLinkLib(anchorifyTextLinks(text))),
          }}
          style={isLong ? (isExpanded ? { height: 'auto' } : { overflow: 'hidden', height: '120px' }) : {}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
        {isLong && !isExpanded && (
          <SeeMoreLess
            onClick={(e) => {
              setIsExpanded(true)
              e.stopPropagation()
            }}
          >
            See more
          </SeeMoreLess>
        )}
        {isLong && isExpanded && (
          <SeeMoreLess
            onClick={(e) => {
              setIsExpanded(false)
              e.stopPropagation()
            }}
          >
            See less
          </SeeMoreLess>
        )}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -16px;
`

const Text = styled.div<{ isExpanded: boolean; isLong: boolean }>`
  background-color: #ededed;
  word-wrap: break-word;
  padding: 10px 14px;
  font-size: 14px;
  color: #000000;

  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;

  a {
    color: #2058a5;
    font-style: italic;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  > p {
    position: relative;
    margin: 0;
  }

  p:after {
    display: ${(props) => (props.isExpanded ? 'none' : props.isLong ? 'block' : 'none')};
    content: '';
    background: rgb(237, 237, 237);
    background: linear-gradient(0deg, rgba(237, 237, 237, 1) 0%, rgba(237, 237, 237, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
  }
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ededed;
  margin-left: 57px;
`

const SeeMoreLess = styled.button`
  border: none;
  color: #2058a5;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;

  :hover {
    text-decoration: none;
  }
`
