import React from 'react'
import { Card, TextField, Typography } from '@mui/material'
import timeZones from 'shared-data/timeTarget/timeZones.json'
import { styled } from '@mui/material/styles'
import { TrashIcon } from '../icons/trashIcon'

type zone = {
  value: string
  from: string
  to: string
  onTimeChanged: (c: { from?: string; to?: string }) => void
  onNameChanged: (c: string) => void
  name: string
  onRemove: () => void
}

const TimeZoneCardComponent: React.FC<zone> = ({ value, from, to, onTimeChanged, onNameChanged, name, onRemove }) => {
  const zone = timeZones.find(({ value: v }) => v === value)
  if (!zone) return <></>

  return (
    <Card sx={{ mt: 1, mb: 1, p: 1, position: 'relative' }}>
      <TextField
        onChange={(e) => {
          onNameChanged(e.target.value)
        }}
        name="timeTargetName"
        variant="standard"
        placeholder="Time target Name"
        margin="normal"
        fullWidth
        value={name}
      />
      <Typography variant="h6">{zone.text}</Typography>
      <TrashIconButton onClick={onRemove}>
        <TrashIcon />
      </TrashIconButton>
      <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
        {zone.utc.join(', ')}
      </Typography>
      <TimePicker
        type="time"
        max={to}
        name="from"
        value={from}
        onChange={(e) => onTimeChanged({ from: e.target.value })}
      />
      <span> - </span>
      <TimePicker type="time" min={from} name="to" value={to} onChange={(e) => onTimeChanged({ to: e.target.value })} />
    </Card>
  )
}

const TrashIconButton = styled('button')(({ theme }) => ({
  top: theme.spacing(1),
  right: theme.spacing(1),
  position: 'absolute',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
}))
const TimePicker = styled('input')(({ theme }) => ({
  borderRadius: `5px`,
  width: 'fitContent',
  height: '40px',
  display: 'inline-block',

  '&:invalid': {
    color: theme.palette?.error?.main || 'red',
  },
}))

export default TimeZoneCardComponent
