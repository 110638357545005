import React, { ChangeEvent, FC, useState } from 'react'
import { TeamInterface } from 'shared-data/interfaces/team.interface'
import { OrganizationInterface } from 'shared-data/interfaces/organization.interface'
import { Box, Button, FormControlLabel, FormGroup, IconButton, Paper, Switch, TextField } from '@mui/material'
import { DeleteForever as RevokeIcon } from '@mui/icons-material'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import { TeamMembersSelectComponent } from './TeamMembersSelect.component'
import { TeamTargetComponent } from './TeamTarget.component'

interface Props {
  team: TeamInterface
  organization: OrganizationInterface
  onUpdateName: (name: string) => void
  onUpdateMembers: (users: UserInterface[]) => void
  onDelete: (team: TeamInterface) => void
  onUpdateBooleanKey: (key: string, value: boolean) => void
}

export const TeamItem: FC<Props> = ({
  team,
  organization,
  onDelete,
  onUpdateName,
  onUpdateMembers,
  onUpdateBooleanKey,
}) => {
  const [teamName, setTeamName] = useState(team.name)

  const onNameSaveClick = () => {
    onUpdateName(teamName)
  }

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value)
  }

  return (
    <Box display="block" key={team._id} mb={4}>
      <Paper sx={{ p: 3 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Box display="flex" alignItems="center">
            <TeamTargetComponent target={team.target} />
            <Box display="flex" alignItems="center" flexGrow={1}>
              <TextField
                InputProps={{ disableUnderline: true, sx: { fontSize: 24 } }}
                variant="standard"
                sx={{ width: 300 }}
                inputMode="text"
                value={teamName}
                onChange={onNameChange}
              />
              {team.name !== teamName && (
                <>
                  <Button variant="contained" color="success" size="small" sx={{ ml: 2 }} onClick={onNameSaveClick}>
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => setTeamName(team.name)}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          </Box>

          <IconButton onClick={() => onDelete(team)}>
            <RevokeIcon />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <Box width={1 / 2} pr={4}>
            <TeamMembersSelectComponent
              users={organization.users.filter((u) => u._id!.toString() !== team.ownerUser._id!.toString())}
              usersInOrg={team.users}
              onChange={onUpdateMembers}
            />
          </Box>

          <Box width={1 / 2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={team.isTargetOwnerApprovalRequired}
                    onChange={(e) => onUpdateBooleanKey('isTargetOwnerApprovalRequired', e.target.checked)}
                  />
                }
                label="Is owner approval required? If no, then everyone in the team will be able to schedule or post on the profile."
              />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={team.isCanSetAsTarget}
                    onChange={(e) => onUpdateBooleanKey('isCanSetAsTarget', e.target.checked)}
                  />
                }
                label={`Can team members set "${team.target?.name}" as a target for their new posts?`}
              />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={team.isCanEditDraft}
                    onChange={(e) => onUpdateBooleanKey('isCanEditDraft', e.target.checked)}
                  />
                }
                label={`Can team members see all draft posts created for the target "${team.target?.name}" without being assigned to a specific post?`}
              />
            </FormGroup>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
