import React, { useEffect } from 'react'
import styled from 'styled-components'
import Container from '../components/layout/container'
import { SignupForm } from '../components/auth/forms/signup.form'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const SignUpPage = () => {
  useEffect(() => {
    setDocumentTitleHelper('Sign Up')
  }, [])

  return (
    <Wrapper>
      <Container>
        <SignupForm />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
