import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import PostComponent from '../components/Post/Post.component'
import { openComposer } from '../state/postComposer.slice'
import { RootState } from '../store/RootState'
import { getPosts, setLoader } from '../state/posts.slice'
import PostSkeleton from '../components/Post/PostSkeleton.component'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const SchedulePage = () => {
  const { data: posts, loading } = useSelector((state: RootState) => state.posts?.schedule)
  const postToDiscardOrDelete = useSelector((state: RootState) => state.posts.postToDiscardOrDelete)

  const d = useDispatch()

  useEffect(() => {
    d(setLoader({ schedule: true }))
    d(getPosts('schedule'))
  }, [])

  useEffect(() => {
    setDocumentTitleHelper('Schedule')
  }, [])

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Button variant="contained" color="success" onClick={() => d(openComposer())}>
            Compose new post
          </Button>
          {!loading && (
            <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
              {posts?.length ? 'Your scheduled posts' : `There’re no posts scheduled so far`}
            </Typography>
          )}
          <Box sx={{ display: 'flex', mt: 5 }}>
            <Box sx={{ width: 1 }}>
              {!posts || loading ? (
                <>
                  <PostSkeleton />
                  <PostSkeleton attachment />
                </>
              ) : (
                posts.map((post, index) => (
                  <PostComponent
                    post={post}
                    postType="schedule"
                    postIndex={index}
                    key={post._id}
                    deleting={
                      !!postToDiscardOrDelete &&
                      postToDiscardOrDelete.index === index &&
                      postToDiscardOrDelete.type === 'schedule'
                    }
                  />
                ))
              )}
            </Box>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
