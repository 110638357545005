import React from 'react'
import { Paper } from '@mui/material'

interface Props {
  children: React.ReactNode | React.ReactChildren
}

export const FormWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Paper elevation={15} sx={{ p: 4, maxWidth: 'sm', margin: 'auto' }}>
      {children}
    </Paper>
  )
}
