import React from 'react'
import styled from 'styled-components'
import { spacing, themeConfig } from '../../config/theme'
import Container from '../layout/container'

interface Props {
  title: string
  excerpt?: string
}

export const PageTitleAndExcerpt: React.FC<Props> = ({ title, excerpt }) => {
  return (
    <TitleAndExcerptWrapper>
      <Container>
        <Title>{title}</Title>
        {excerpt && <Excerpt>{excerpt}</Excerpt>}
      </Container>
    </TitleAndExcerptWrapper>
  )
}

const TitleAndExcerptWrapper = styled.section`
  margin: ${spacing(10)}px auto ${spacing(5)}px;
  text-align: center;
  max-width: 95%;
  line-height: 1.2;
  @media only screen and (min-width: ${themeConfig.breakpoints.md}px) {
    margin: ${spacing(15)}px auto ${spacing(10)}px;
    max-width: 80%;
  }
`

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;

  @media only screen and (min-width: ${themeConfig.breakpoints.md}px) {
    font-size: 36px;
  }
`

const Excerpt = styled.h2`
  margin-top: ${spacing(4)}px;
  font-size: 20px;
  font-weight: 200;
`
