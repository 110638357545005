import React from 'react'

export const TwitterIcon = ({ fill = '#fff', size = 15.499 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <path
      id="Icon_simple-twitter"
      data-name="Icon simple-twitter"
      d="M15.469,4.869a6.458,6.458,0,0,1-1.824.5,3.2,3.2,0,0,0,1.4-1.758,6.563,6.563,0,0,1-2.019.765,3.176,3.176,0,0,0-5.5,2.169,3.251,3.251,0,0,0,.082.726A9,9,0,0,1,1.059,3.96a3.114,3.114,0,0,0-.43,1.6A3.178,3.178,0,0,0,2.042,8.2,3.167,3.167,0,0,1,.6,7.806v.039a3.179,3.179,0,0,0,2.548,3.117,3.207,3.207,0,0,1-1.428.055A3.188,3.188,0,0,0,4.7,13.224,6.373,6.373,0,0,1,.756,14.583,6.744,6.744,0,0,1,0,14.54a9.038,9.038,0,0,0,4.88,1.427,8.975,8.975,0,0,0,9.04-9.032c0-.135,0-.271-.01-.407A6.416,6.416,0,0,0,15.5,4.882l-.03-.013Z"
      transform="translate(0 -3.369)"
      fill={fill}
    />
  </svg>
)
