import { DateTime } from 'luxon'

const generateDates = (index = 0) => {
  const week = 7
  const dates: { [key: string]: string[] } = {}

  for (let i = 1; i < week; i++) {
    const date = DateTime.local().plus({ day: i + 7 * index })
    const key = `${date.year}-${date.month}-${date.day}`
    dates[key] = []
  }
  return dates
}

export const getCorrectTimePickerTime = (isDate = false, datePassed?: DateTime) => {
  const date = datePassed || DateTime.local()
  const { year } = date
  let month: string | number
  month = date.month
  month = month < 10 ? `0${month}` : month
  let day: string | number
  day = date.day
  day = day < 10 ? `0${day}` : day
  if (isDate) {
    return `${year}-${month}-${day}`
  }
  let hour: string | number
  hour = date.hour
  hour = hour < 10 ? `0${hour}` : hour
  let minutes: string | number = date.minute
  minutes = minutes < 10 ? `0${minutes}` : minutes

  return `${year}-${month}-${day}T${hour}:${minutes}`
}

export const suggestTimeHelper = (postsPerSevenDays = 7, scheduled: string[]): string => {
  let generateIndex = 0
  let weeks: { [key: string]: string[] }[] = [generateDates(generateIndex)]

  scheduled.forEach((c: string) => {
    const d = DateTime.fromISO(c)
    const date = `${d.year}-${d.month}-${d.day}`

    while (!weeks.some((week) => Object.keys(week).includes(date))) {
      weeks = [...weeks, generateDates(++generateIndex)]
      if (generateIndex > 53) {
        // dude has a post scheduled more than year
        return
      }
    }
    const foundWeek = weeks.find((w) => w[date] !== undefined)
    if (foundWeek) foundWeek[date].push(c)
  })

  let bestWeek = weeks.find((w) => Object.values(w).reduce((acc, curr) => acc + curr.length, 0) < postsPerSevenDays)

  if (!bestWeek) bestWeek = generateDates(++generateIndex)

  const [bestDay, posts] = Object.entries(bestWeek).sort(
    ([d, scheduled], [d1, scheduled1]) => scheduled.length - scheduled1.length
  )[0]

  const hours: { [key: number]: string[] } = {}

  // 9 am - 11 pm
  for (let h = 9; h < 23; h++) {
    hours[h] = []
  }

  posts.forEach((p: string) => {
    const h = DateTime.fromISO(p).hour
    console.log(h)
    if (!hours[h]) {
      hours[h] = []
    }
    hours[h].push(p)
  })
  // todo - I think we should add calculateBestHourDependOnPostsAnalytics()
  const [bestHour] = Object.entries(hours).sort(([h, p], [h1, p1]) => p.length - p1.length)[0]

  const [year, month, day] = bestDay.split('-')

  const toReturn = DateTime.local(+year, +month, +day, +bestHour, 30)
  console.log(getCorrectTimePickerTime(false, toReturn), 1)
  return getCorrectTimePickerTime(false, toReturn)
}
