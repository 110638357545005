import React, { useEffect, useState } from 'react'
import { MenuItem, Select } from '@mui/material'
import { PostTargetTypes } from 'shared-data/types/postTarget.types'
import { useSelector } from 'react-redux'
import { LinkedinTargetInterface } from 'shared-data/interfaces/linkedin/linkedinTarget.interface'
import { TargetSelectItem } from './TargetSelectItem.component'
import { RootState } from '../../../store/RootState'

const TargetSelectComponent = ({ onChange }) => {
  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)
  const {
    targets: { data: targets },
    teamsTargets: { data: teamsTargets },
  } = useSelector((state: RootState) => state.targets)
  const {
    teamsImIn: { data: teamsImIn },
  } = useSelector((state: RootState) => state.teams)

  const [selectedTarget, setSelectedTarget] = useState<LinkedinTargetInterface | undefined>()

  useEffect(() => {
    if (targets && teamsTargets) {
      const nextTargets = [...targets, ...teamsTargets]
      const targetToSelect =
        nextTargets && nextTargets.length > 0
          ? post?.targetEntity
            ? nextTargets.find(({ _id }) => post.targetEntity!._id === _id)
            : nextTargets[0]
          : undefined
      if (targetToSelect) {
        setSelectedTarget(targetToSelect)
        onChange(targetToSelect._id)
      }
    }
  }, [targets, post, teamsTargets])

  if (!targets || !teamsTargets || !selectedTarget || !teamsImIn) return <></>

  return (
    <Select
      value={selectedTarget._id}
      size="small"
      sx={{
        mb: 1,
        backgroundColor: selectedTarget.target === PostTargetTypes.AUTHOR ? '#fff4d1' : '#d1e4ff',
      }}
      renderValue={() => <TargetSelectItem target={selectedTarget} />}
      onChange={(v) => {
        const theT = targets.find(({ _id }) => _id === v.target.value)
        if (theT?.invalid) return
        const disabled =
          !theT &&
          teamsImIn
            .filter(({ target }) => target._id === v.target.value)
            .every(({ isCanSetAsTarget }) => !isCanSetAsTarget)
        if (disabled) return
        onChange(v.target.value)
        setSelectedTarget([...targets, ...teamsTargets]?.find((t) => t._id === v.target.value))
      }}
      variant="outlined"
    >
      {targets?.map((t) => (
        <MenuItem value={t._id} key={t._id}>
          <TargetSelectItem target={t} />
        </MenuItem>
      ))}
      {teamsTargets?.map((t) => {
        const disabled = teamsImIn
          .filter(({ target }) => target?._id === t?._id)
          .every(({ isCanSetAsTarget }) => !isCanSetAsTarget)
        return (
          <MenuItem value={t._id} key={t._id}>
            <TargetSelectItem target={t} disabled={disabled} />
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default TargetSelectComponent
