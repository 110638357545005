import React, { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  ClickAwayListener,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { TagInterface } from 'shared-data/interfaces/tag.interface'
import { useSelector } from 'react-redux'
import axios from '../../lib/axios'
import { RootState } from '../../store/RootState'
import { toInterfacedObjectArray } from '../../lib/toInterfacedObjectArray'

type props = {
  onChange: (c: TagInterface[]) => void
  simple?: boolean
  propsTags?: TagInterface[]
}

export const TagsSelectComponent: React.FC<props> = ({ onChange, simple = false, propsTags = [] }) => {
  const [suggestions, setSuggestions] = useState<TagInterface[]>([])
  const [tags, setTags] = useState<TagInterface[]>(propsTags ?? [])
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = useRef<null | HTMLInputElement>(null)
  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)

  useEffect(() => {
    if (!suggestions.length) {
      ;(async () => {
        const response = await axios.get('/private/get_tag_suggestions')
        setSuggestions(response.data)
      })()
    }
  }, [])

  useEffect(() => {
    if (post) {
      setTags(post.tags ?? [])
    }
  }, [post])

  const addTag = (e: any, newValue: (TagInterface | string)[], reason: AutocompleteChangeReason | 'clickOutside') => {
    if (inputValue && inputRef.current && reason === 'clickOutside') {
      newValue.push(inputValue)
      inputRef.current.value = ''
      setInputValue('')
    }
    const tags = toInterfacedObjectArray<TagInterface>(newValue, suggestions)
    setTags(tags)
    onChange(tags)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        addTag(null, [...tags], 'clickOutside')
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Box flexGrow={1}>
          <Autocomplete
            multiple
            freeSolo
            filterSelectedOptions
            options={suggestions}
            onChange={addTag}
            value={tags}
            clearOnBlur
            autoComplete
            getOptionLabel={(option) =>
              typeof option === typeof '' ? (option as string) : (option as TagInterface).name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Analytics tags"
                margin="normal"
                fullWidth
                ref={inputRef}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value)
                }}
              />
            )}
          />
        </Box>
        {!simple && (
          <Typography fontSize="10px" ml="5px" sx={{ width: '30%' }}>
            Enter some tags about your content and the tone. This is used internally to track statistics by topic.
          </Typography>
        )}
      </Stack>
    </ClickAwayListener>
  )
}
