export type AsyncData<T> = {
  loading: boolean
  error: string | null
  data: T | null
}

export const asyncDataInitialState = {
  loading: true,
  error: null,
  data: null,
}
