import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationInterface } from 'shared-data/interfaces/organization.interface'
import axios from 'axios'
import { asyncDataInitialState } from '../store/AsyncData'
import { OrganizationState } from './organization.state'

const initialState: OrganizationState = {
  organization: asyncDataInitialState,
  invites: asyncDataInitialState,
  organizationsImIn: asyncDataInitialState,
}

export const getMyOrganization = createAsyncThunk('getMyOrganization', async () => {
  try {
    return await axios.get('/private/org')
  } catch (e) {
    console.error(e)
    return { data: null }
  }
})

export const getOrganizationsImIn = createAsyncThunk('getOrganizationsImIn', async () => {
  try {
    return await axios.get('/private/org/imin')
  } catch (e) {
    console.error(e)
    return { data: null }
  }
})

export const getOrganizationInvites = createAsyncThunk('getOrganizationInvites', async () => {
  try {
    return await axios.get('/private/org/invites')
  } catch (e) {
    console.error(e)
    return { data: null }
  }
})

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<OrganizationInterface | null>) => {
      state.organization.loading = false
      state.organization.data = action.payload
    },
    setLoader: (state, action: PayloadAction<boolean | undefined>) => {
      state.organization.loading = action.payload === undefined ? true : action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.organization.loading = false
      state.organization.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyOrganization.fulfilled, (state, action) => {
        state.organization.data = action.payload.data
      })
      .addCase(getOrganizationInvites.fulfilled, (state, action) => {
        state.invites.data = action.payload.data
      })
      .addCase(getOrganizationsImIn.fulfilled, (state, action) => {
        state.organizationsImIn.data = action.payload.data
      })
  },
})
