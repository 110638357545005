import React, { FC } from 'react'
import { Chart } from 'react-google-charts'
import { ChartWrapperOptions } from 'react-google-charts/dist/types'
import { Box, Typography, useTheme } from '@mui/material'

interface Props {
  value: number
}

export const DonutChartComponent: FC<Props> = ({ value }) => {
  const SIZE = 120

  const theme = useTheme()

  const data = [
    ['Task', 'Hours per Day'],
    ['Engagement', value],
    ['Work', Math.abs(1 - value)],
  ]

  const options: ChartWrapperOptions['options'] = {
    pieHole: 0.7,
    is3D: false,
    legend: 'none',
    pieSliceText: 'none',
    // pieStartAngle: 135,
    tooltip: { trigger: 'none' },
    slices: {
      0: { color: value > 0.5 ? theme.palette.success.main : theme.palette.error.main },
      1: { color: '#efefef' }, // transparent
    },
    chartArea: { left: 0, top: 0, width: '100%', height: '100%' },
    height: SIZE,
    width: SIZE,
  }

  return (
    <Box sx={{ position: 'relative', display: 'inline-block', width: `${SIZE}px`, height: `${SIZE}px` }}>
      <Chart chartType="PieChart" width="100%" height={`${SIZE}px`} data={data} options={options} />

      <Box
        sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h4">{Number(value).toFixed(1)}</Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ width: '100%', mt: 1, textAlign: 'center', display: 'block', fontSize: 9, fontWeight: 700 }}
      >
        Engagement
      </Typography>
    </Box>
  )
}
