import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import mixpanel from 'mixpanel-browser'
import { userSlice } from '../state/user.slice'
import axios, {
  getRefreshToken,
  isSetToken,
  refreshToken,
  setRefreshToken,
  setToken,
  unsetRefreshToken,
  unsetToken,
} from '../lib/axios'
import { socketHandler } from '../lib/io'
import { socketInitializeService } from '../services/socket.service'
import { targetsSlice } from '../state/targets.slice'
import { getMyOrganization, getOrganizationsImIn, organizationSlice } from '../state/organization.slice'
import { getTargets } from '../state/timeTargets.slice'
import { getTeamsImIn } from '../state/teams.slice'
import { RootState } from '../store/RootState'

export const useUserApi = () => {
  const { user } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const navigation = useNavigate()

  const socketReconnect = async () => {
    if (!user.data) return
    console.log('data.socketRoomId', user.data.socketRoomId)
    socketInitializeService(user.data.socketRoomId)
    socketHandler.setDispatch(dispatch)
  }

  const load = async () => {
    try {
      if (isSetToken()) {
        dispatch(userSlice.actions.setLoader())
        dispatch(targetsSlice.actions.setLoader())

        const response = await axios.get<UserInterface>('/private/me')
        const data = await response.data

        dispatch(userSlice.actions.setData(data))
        if (data.organization) {
          dispatch(organizationSlice.actions.setData(data.organization))
        }
        dispatch(getMyOrganization())
        dispatch(getOrganizationsImIn())
        dispatch(getTargets())
        // dispatch(getMyTeams())
        dispatch(getTeamsImIn())

        console.log('data.socketRoomId', data.socketRoomId)
        socketInitializeService(data.socketRoomId)
        socketHandler.setDispatch(dispatch)
        if (data && data.email) {
          try {
            const responseOrgs = await axios.get('/private/postTargets')
            console.log('/private/postTargets', responseOrgs.data)
            dispatch(targetsSlice.actions.setTargets(responseOrgs.data.targets))
            dispatch(targetsSlice.actions.setTeamsTargets(responseOrgs.data.teamsTargets))
            mixpanel.people.set({
              $distinct_id: data._id,
              plan: data.plan,
            })
            if (data['organization']?._id) {
              mixpanel.add_group('organization', data['organization']._id)
            }
          } catch (e) {
            console.error(e)
          }
        }
      } else {
        await refreshToken()
        await load()
      }
    } catch (e) {
      dispatch(userSlice.actions.setData(null))
      dispatch(targetsSlice.actions.setTargets(null))
      dispatch(targetsSlice.actions.setTeamsTargets(null))
      unsetToken()
      // dispatch(userSlice.actions.setError(e.toString()));
    }
  }

  const signIn = async (email, password, recaptcha) => {
    try {
      dispatch(userSlice.actions.setLoader())

      const response = await axios.post(
        '/public/login',
        {
          email,
          password,
        },
        {
          headers: {
            recaptcha,
          },
        }
      )
      const data = await response.data
      setToken(data.token)
      setRefreshToken(data.refreshToken)

      dispatch(userSlice.actions.setData(data.user))
      console.log('data.socketRoomId', data.user.socketRoomId)
      console.log('data.user', data.user)
      socketInitializeService(data.user.socketRoomId)

      mixpanel.track('Sign in')
    } catch (e) {
      console.error(e)
      console.log(e)
      const message = e.toJSON && e.toJSON().status === 403 ? `Login credentials are invalid` : e?.message || ''
      dispatch(userSlice.actions.setError(message))
      unsetToken()
    } finally {
      dispatch(userSlice.actions.setLoader(false))
      await load()
    }
  }

  const signUp = async (email, password, firstName, lastName, recaptcha, token) => {
    try {
      dispatch(userSlice.actions.setLoader())

      const response = await axios.post(
        '/public/register',
        {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          token,
        },
        {
          headers: {
            recaptcha,
          },
        }
      )
      const data = await response.data
      setToken(data.token)
      setRefreshToken(data.refreshToken)

      dispatch(userSlice.actions.setData(data.user))

      socketInitializeService(data.user.socketRoomId)

      mixpanel.track('Sign up')
    } catch (e) {
      console.error(e)
      dispatch(userSlice.actions.setError(e.response?.data?.message))
      unsetToken()
    }
  }

  const passwordResetRequest = async (email, recaptcha) => {
    try {
      dispatch(userSlice.actions.setLoader())

      const response = await axios.post(
        '/public/password/reset/request',
        {
          email,
        },
        {
          headers: {
            recaptcha,
          },
        }
      )
      const data = await response.data

      dispatch(userSlice.actions.setData(data.user))

      mixpanel.track('Password reset requested')
    } catch (e) {
      dispatch(userSlice.actions.setError(e.toString()))
    }
  }

  const passwordReset = async (resetPasswordToken, password, recaptcha) => {
    try {
      dispatch(userSlice.actions.setLoader())

      const response = await axios.post(
        '/public/password/reset',
        {
          resetPasswordToken,
          password,
        },
        {
          headers: {
            recaptcha,
          },
        }
      )
      const data = await response.data
      setToken(data.token)

      dispatch(userSlice.actions.setData(data.user))

      navigation('/')

      mixpanel.track('Password reset')
    } catch (e) {
      dispatch(userSlice.actions.setError(e.toString()))
      unsetToken()
    }
  }

  const signOut = async (redirectTo = '/') => {
    await axios.post('public/reset-refresh-token', { token: getRefreshToken() })
    try {
      dispatch(userSlice.actions.setLoader())

      unsetToken()

      unsetRefreshToken()

      dispatch(userSlice.actions.setData(null))

      socketHandler.getSocket().disconnect()

      mixpanel.track('Sign out')

      window.location.href = redirectTo
    } catch (e) {
      dispatch(userSlice.actions.setError(e.toString()))
      unsetToken()
    }
  }

  const updateUser = (user: UserInterface) => {
    try {
      dispatch(userSlice.actions.setLoader())
      dispatch(userSlice.actions.setData(user))
      mixpanel.track('User update')
    } catch (e) {
      dispatch(userSlice.actions.setError(e.toString()))
    }
  }

  return {
    load,
    signIn,
    signOut,
    signUp,
    passwordResetRequest,
    passwordReset,
    updateUser,
    socketReconnect,
  }
}
