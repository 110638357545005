import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, useTheme } from '@mui/material/styles'
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import PostComponent from '../components/Post/Post.component'
import { openComposer } from '../state/postComposer.slice'
import { RootState } from '../store/RootState'
import { getFilteredPosts, setLoader } from '../state/posts.slice'
import PostSkeleton from '../components/Post/PostSkeleton.component'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'
import { PROGRESS_STATUSES } from '../components/PostComposer/teamSettings/progressStatus.component'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, assigneeId: readonly string[], theme: Theme) {
  return {
    fontWeight:
      assigneeId.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export const DraftsPage = () => {
  const { data: posts, loading } = useSelector((state: RootState) => state.posts?.draft)
  const { data: targets } = useSelector((state: RootState) => state.targets.targets)
  const { data: organization, loading: isOrganizationLoading } = useSelector(
    (state: RootState) => state.organization.organization
  )
  const postToDiscardOrDelete = useSelector((state: RootState) => state.posts.postToDiscardOrDelete)

  const [assigneesFilter, setAssigneesFilter] = useState<string[]>(
    localStorage.getItem('assigneesFilter')?.split(',') || []
  )
  const [statusesFilter, setStatuses] = useState<string[]>(localStorage.getItem('statusesFilter')?.split(',') || [])
  const [targetsFilter, setTargetsFilter] = useState<string[]>(localStorage.getItem('targetsFilter')?.split(',') || [])

  const theme = useTheme()

  const d = useDispatch()

  useEffect(() => {
    d(setLoader({ draft: true }))
    d(
      getFilteredPosts({
        type: 'draft',
        assignees: assigneesFilter || [],
        statuses: statusesFilter || [],
        targets: targetsFilter || [],
      })
    )
  }, [assigneesFilter, statusesFilter, targetsFilter])

  useEffect(() => {
    setDocumentTitleHelper('Drafts')
  }, [])

  const handleAssigneesChange = (event: SelectChangeEvent<typeof assigneesFilter>) => {
    const {
      target: { value },
    } = event
    setAssigneesFilter(typeof value === 'string' ? value.split(',') : value)
    localStorage.setItem('assigneesFilter', value.toString())
  }

  const handleStatusesChange = (event: SelectChangeEvent<typeof statusesFilter>) => {
    const {
      target: { value },
    } = event
    setStatuses(typeof value === 'string' ? value.split(',') : value)
    localStorage.setItem('statusesFilter', value.toString())
  }

  const handleTargetsChange = (event: SelectChangeEvent<typeof targetsFilter>) => {
    const {
      target: { value },
    } = event
    setTargetsFilter(typeof value === 'string' ? value.split(',') : value)
    localStorage.setItem('targetsFilter', value.toString())
  }

  const handleResetFilters = () => {
    setTargetsFilter([])
    setStatuses([])
    setAssigneesFilter([])
    localStorage.removeItem('assigneesFilter')
    localStorage.removeItem('statusesFilter')
    localStorage.removeItem('targetsFilter')
  }

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Button variant="contained" color="success" onClick={() => d(openComposer())}>
            Compose new post
          </Button>

          <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
            {posts?.length ? 'Draft posts' : `There’re no draft posts so far`}
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ mt: 3, mb: 3, px: 3, py: 2, background: '#f6f6f6', border: 'thin solid #efefef' }}
          >
            <Typography variant="body1" sx={{ mr: 3 }}>
              Fitlers
            </Typography>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Assignees</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={assigneesFilter}
                onChange={handleAssigneesChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {organization?.users &&
                      organization.users
                        .filter((u) => selected.includes(u._id!))
                        .map((value) => <Chip key={value._id!} label={value.firstName} />)}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {!isOrganizationLoading &&
                  organization?.users &&
                  organization.users.map((assignee) => (
                    <MenuItem
                      key={assignee._id}
                      value={assignee._id}
                      style={getStyles(assignee._id!, assigneesFilter, theme)}
                    >
                      {assignee.firstName} {assignee.lastName} {assignee.email}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={statusesFilter}
                onChange={handleStatusesChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {PROGRESS_STATUSES.filter((u) => selected.includes(u.key)).map((value) => (
                      <Chip key={value.key} label={value.value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {PROGRESS_STATUSES.map((status) => (
                  <MenuItem key={status.key} value={status.key} style={getStyles(status.key, assigneesFilter, theme)}>
                    {status.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Targets</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={targetsFilter}
                onChange={handleTargetsChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {targets &&
                      targets
                        .filter((u) => selected.includes(u._id!))
                        .map((value) => <Chip key={value._id!} label={value.name} />)}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {targets &&
                  targets.map((target) => (
                    <MenuItem
                      key={target._id}
                      value={target._id}
                      style={getStyles(target._id!, assigneesFilter, theme)}
                    >
                      {target.name} {target.invalid ? '(invalid)' : ''}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <IconButton onClick={handleResetFilters}>
                <HighlightOffIcon />
              </IconButton>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', mt: 5 }}>
            <Box sx={{ width: 1 }}>
              {!posts || loading ? (
                <>
                  <PostSkeleton />
                  <PostSkeleton attachment />
                </>
              ) : (
                posts.map((post, index) => (
                  <PostComponent
                    post={post}
                    postType="draft"
                    postIndex={index}
                    key={post._id}
                    deleting={
                      !!postToDiscardOrDelete &&
                      postToDiscardOrDelete.index === index &&
                      postToDiscardOrDelete.type === 'draft'
                    }
                  />
                ))
              )}
            </Box>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
