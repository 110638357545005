import React, { useCallback, useEffect, useRef, useState } from 'react'
import Container from '@mui/material/Container'
import { useSelector } from 'react-redux'
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import { PlanTypes } from 'shared-data/features/plan.types'
import Layout from '../../components/layout'
import { RootState } from '../../store/RootState'
import axios, { getToken } from '../../lib/axios'
import Loader from '../../components/loader'
import { ANNUAL_PRICE_TOOLTIP } from './settings'
import PricingTableComponent from '../../components/pricingTable/PricingTable.component'
import { setDocumentTitleHelper } from '../../helpers/setDocumentTitle.helper'

export const PricingPage = () => {
  const user = useSelector((state: RootState) => state.user.user)

  const [isAnnual, setIsAnnual] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<HTMLFormElement>()

  useEffect(() => {
    setDocumentTitleHelper('Pricing')
  }, [])

  const clickSubscribe = useCallback(
    (e, plan: PlanTypes, duration: 'm' | 'a') => {
      if (formRef?.current) {
        setIsLoading(true)
        e.preventDefault()
        formRef.current.action = `${axios.defaults.baseURL}private/${
          user?.data?.stripeSubscriptionId ? 'create-customer-portal-session' : 'create-subscription-session'
        }?plan=${plan}&duration=${duration}&tkn=${getToken()}`
        formRef.current.submit()
      }
    },
    [formRef]
  )

  return (
    <Layout>
      {isLoading && <Loader />}
      <form
        method="post"
        ref={(r) => {
          if (r) {
            formRef.current = r!
          }
        }}
      >
        <input type="hidden" value={window.location.href} name="returnUrl" />
      </form>
      <Container>
        <Box>
          <Typography variant="h3">Plan selection</Typography>
          <Typography mt={5}>
            We fine-tuned a free plan for you to be able to test some of the central core features of the Cortip Content
            Creator tool. A free plan should be sufficient to know what you can do with it. A personal plan is tuned for
            individuals who want to boost their visibility and focus on personal branding. A business plan is for larger
            companies and teams where access limitations are required.
          </Typography>
          <Typography mt={3}>
            Be advised that we offer 30-day free trial only once, and this is the time to consider trying a plan with
            more features. You can cancel any time, of course!
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <FormGroup>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={2}>Monthly subscription</Box>
              <FormControlLabel
                control={<Switch value={isAnnual} onChange={(event, checked) => setIsAnnual(checked)} />}
                label="Annual subscription"
              />
            </Box>
          </FormGroup>
        </Box>
        <PricingTableComponent isAnnual={isAnnual} clickSubscribe={clickSubscribe} />
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Typography>* - {ANNUAL_PRICE_TOOLTIP}</Typography>
        </Box>
      </Container>
    </Layout>
  )
}
