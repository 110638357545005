import React, { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/RootState'

export const IntercomComponent = () => {
  const { loading: loadingUser, data: user } = useSelector((state: RootState) => state.user.user)
  const { boot, update } = useIntercom()

  useEffect(() => {
    if (!loadingUser && user?.email) {
      boot()
      update({
        email: user.email,
        userId: user._id,
        createdAt: user.createdAt,
        name: `${user.firstName} ${user.lastName}`,
        // avatar: user.
        customAttributes: {
          plan: user.plan,
          isTrialUsed: user.isTrialUsed,
        },
      })
    }
  }, [user, loadingUser])

  return <></>
}
