import React, { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { TimeLeft } from '../../PostComposer/Timeout.component'

interface Props {
  post: PostInterface
  onPosting: () => void
}

export const PostScheduledComponent: FC<Props> = ({ post, onPosting }) => {
  const [isPosting, setIsPosting] = useState(false)

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} pb={5}>
        <Typography variant="h3" color="primary" sx={{ textAlign: 'center' }}>
          {isPosting ? 'Posting' : 'Scheduled'}
        </Typography>
        {!isPosting && post.scheduledAt && (
          <TimeLeft
            dateTime={new Date(post.scheduledAt)}
            onOver={() => {
              setIsPosting(true)
              onPosting()
            }}
            withoutTitle
          />
        )}
      </Box>
    </Box>
  )
}
