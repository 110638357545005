import React, { FC, useState } from 'react'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { UserInterface } from 'shared-data/interfaces/user.interface'

interface Props {
  users: UserInterface[]
  usersInOrg: UserInterface[]
  onChange: (e: UserInterface[]) => void
}

export const TeamMembersSelectComponent: FC<Props> = ({ users, usersInOrg, onChange }) => {
  const [suggestions] = useState<UserInterface[]>(users)
  const [members, setMembers] = useState<UserInterface[]>(usersInOrg)

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box flexGrow={1}>
        <Typography>Manage who is in this team</Typography>
        <Autocomplete
          multiple
          freeSolo={false}
          filterSelectedOptions
          options={suggestions}
          onChange={(e, newValue: (UserInterface | string)[]) => {
            const memb = toUserInterfacedObjectArray(newValue, suggestions)
            setMembers(memb)
            onChange(memb)
          }}
          value={members}
          autoComplete
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'string' && typeof value === 'string') {
              return option === value
            }
            if (typeof option === 'string' && typeof value !== 'string') {
              return option === value._id
            }
            if (typeof option !== 'string' && typeof value === 'string') {
              return option._id === value
            }
            if (typeof option !== 'string' && typeof value !== 'string') {
              return option._id === value._id
            }
            return false
          }}
          getOptionLabel={(option) =>
            typeof option === typeof ''
              ? (option as string)
              : `${(option as UserInterface).firstName} ${(option as UserInterface).lastName}`
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" placeholder="Team members" margin="normal" fullWidth />
          )}
        />
      </Box>
    </Stack>
  )
}

const toUserInterfacedObjectArray = (val: (UserInterface | string)[], suggestions: UserInterface[]) =>
  val
    .map((t) => {
      if (
        typeof t === 'string' &&
        (val.filter((c) => typeof c !== 'string') as UserInterface[]).some((c) => c._id === t)
      )
        return false
      const next: { _id?: string } =
        typeof t === 'string'
          ? ({
              _id: t,
            } as UserInterface)
          : t
      const found = suggestions.find(({ _id }) => _id === next._id) as UserInterface
      if (found) {
        return found
      }
      return next as UserInterface
    })
    .filter((c) => c) as UserInterface[]
