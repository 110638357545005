import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { PersonAdd as AddIcon, DeleteForever as RevokeIcon } from '@mui/icons-material'
import axios from 'axios'
import { InvitationToOrganizationInterface } from 'shared-data/interfaces/invitationToOrganization.interface'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import mixpanel from 'mixpanel-browser'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import { RootState } from '../store/RootState'
import { getOrganizationInvites, getMyOrganization, getOrganizationsImIn } from '../state/organization.slice'
import { isEmailValidCheck } from '../lib/isEmailValid'
import Loader from '../components/loader'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const OrganizationPage = () => {
  const { loading: loadingUser, data: user } = useSelector((state: RootState) => state.user.user)
  const { loading: loadingOrganization, data: organization } = useSelector(
    (state: RootState) => state.organization?.organization
  )
  const { loading: loadingOrganizationsImIn, data: organizationsImIn } = useSelector(
    (state: RootState) => state.organization?.organizationsImIn
  )
  const { loading: loadingInvites, data: invites } = useSelector((state: RootState) => state.organization?.invites)

  useEffect(() => {
    if (organization?.name) {
      setOrgName(organization.name)
    }
  }, [organization])

  useEffect(() => {
    setDocumentTitleHelper('Organization')
  }, [])

  const [orgName, setOrgName] = useState('')

  if (loadingUser && loadingOrganization && loadingOrganizationsImIn && loadingInvites) {
    return <Loader />
  }

  const [inviteAnchorEl, setInviteAnchorEl] = React.useState<null | HTMLElement>(null)
  const isInviteOpen = Boolean(inviteAnchorEl)
  const onInviteButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInviteAnchorEl(event.currentTarget)
  }
  const handleInviteClose = () => {
    setInviteAnchorEl(null)
  }

  const d = useDispatch()

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setIsEmailValid(isEmailValidCheck(event.target.value))
  }

  const onSendInvitation = async () => {
    if (isEmailValid) {
      await axios.post('/private/org/invite', { email })
      d(getOrganizationInvites())
      setEmail('')
      setIsEmailValid(false)
      handleInviteClose()
      mixpanel.track('Organization Invite', {
        $distinct_id: user!._id!.toString(),
      })
    }
  }

  const onLeaveClick = async (id: string, orgName: string) => {
    if (window.confirm(`Are you sure you want to leave "${orgName}"?`)) {
      await axios.post('/private/org/leave', { id })
      d(getOrganizationsImIn())
    }
  }

  const onRevokeInviteClick = async (email: string, id: string) => {
    await axios.post('/private/org/revoke', { email, id })
    d(getOrganizationInvites())
  }

  useEffect(() => {
    ;(async () => {
      d(getMyOrganization())
      d(getOrganizationInvites())
      d(getOrganizationsImIn())
    })()
  }, [])

  const onChangeOrgNameText = (event: ChangeEvent<HTMLInputElement>) => {
    setOrgName(event.target.value)
  }

  const onCancelOrgNameText = () => {
    setOrgName(organization!.name!)
  }

  const onOrgNameSaveClick = async () => {
    await axios.post('/private/org/update', { name: orgName })
    d(getMyOrganization())
  }

  const onKickFromOrganization = async (userId: string, name: string, email: string) => {
    if (window.confirm(`Are you sure you want to remove ${name} ${email} from your organization?`)) {
      await axios.post('/private/org/kick', { userId })
      d(getMyOrganization())
    }
  }

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Box display="=flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
                My Organization
              </Typography>
              <Box display="flex" alignItems="center" mt={2}>
                <TextField
                  variant="standard"
                  value={orgName}
                  sx={{ width: 300 }}
                  inputMode="text"
                  onChange={onChangeOrgNameText}
                />
                {organization?.name !== orgName && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{ ml: 2 }}
                      onClick={onOrgNameSaveClick}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={onCancelOrgNameText}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            <Button endIcon={<AddIcon />} onClick={onInviteButtonClick}>
              Invite
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={inviteAnchorEl}
              open={isInviteOpen}
              onClose={handleInviteClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box py={2} px={3} display="=flex" flexDirection="row" alignItems="center">
                <TextField
                  type="email"
                  placeholder="mail@example.com"
                  inputMode="email"
                  variant="outlined"
                  size="small"
                  value={email}
                  sx={{ width: 300 }}
                  onChange={onEmailChange}
                />
                <Button
                  variant="contained"
                  color="success"
                  sx={{ ml: 2, mt: 0.2 }}
                  disabled={!isEmailValid}
                  onClick={onSendInvitation}
                >
                  Send invitation
                </Button>
              </Box>
            </Menu>
          </Box>
          <Typography variant="body1" component="h1" sx={{ mt: 5 }}>
            Here you can invite everyone who will be involved in content creation, edit, reviews and other content
            creation activities.
          </Typography>
          <Box sx={{ display: 'flex', mt: 5 }}>
            <TableContainer sx={{ width: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell style={{ width: '50%' }}>Role</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organization?.users
                    ?.filter((u) => Boolean(u.email))
                    .map((u: UserInterface, i) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} sx={{ mb: 1 }} key={`${u.email}`}>
                          <TableCell>
                            <Box display="=flex" flexDirection="row" alignItems="center">
                              {u.avatar && <Avatar src={u.avatar} alt={u.firstName} sx={{ mr: 1 }} variant="rounded" />}
                              <Typography variant="body1">{`${u.firstName} ${u.lastName}`}</Typography>
                              <Typography ml={2} variant="body1" color="gray">
                                {`${u.email}`}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="=flex" flexDirection="row" alignItems="center">
                              {/* <Select size="small"> */}
                              {/*  <MenuItem value="">Admin</MenuItem> */}
                              {/* </Select> */}
                              <Typography variant="body1">{user?._id === u._id ? 'Owner' : 'Member'}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">{user?._id === u._id ? '-' : ''}</Typography>

                            {user?._id !== u._id && (
                              <IconButton onClick={() => onKickFromOrganization(u._id!, u.firstName, u.email)}>
                                <RevokeIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {invites?.map((invite: InvitationToOrganizationInterface) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} sx={{ mb: 1 }} key={`${invite._id}`}>
                        <TableCell>
                          <Box display="=flex" flexDirection="row" alignItems="center">
                            <Typography variant="body1" color="gray">
                              {`${invite.email}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display="=flex" flexDirection="row" alignItems="center">
                            <Typography variant="body1">Invitation pending</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => onRevokeInviteClick(invite.email, invite._id!)}>
                            <RevokeIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box sx={{ width: 1 / 2 }} /> */}
          </Box>

          <Box mt={5}>
            <Box display="=flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
                Organizations I am in
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', mt: 5 }}>
              <TableContainer sx={{ width: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {organizationsImIn?.map((o) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} sx={{ mb: 1 }} key={o._id}>
                          <TableCell>
                            <Box display="=flex" flexDirection="row" alignItems="center">
                              <Typography variant="body1">{o.name}</Typography>
                              <Typography ml={2} variant="body1" color="gray">
                                ({o.users.length} users)
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Button size="small" onClick={() => onLeaveClick(o._id!, o.name)}>
                              Leave
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
