import React, { FC, useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { recaptchaConfig } from '../../config/recaptcha.config'

interface Props {
  onChange: (value: string | null) => void
}

export const ReCaptchaComponent: FC<Props> = ({ onChange }) => {
  const recaptchaRef = useRef<ReCAPTCHA>()

  useEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current?.execute()
    }
  }, [recaptchaRef.current])

  return (
    <ReCAPTCHA
      sitekey={recaptchaConfig.key}
      size="invisible"
      ref={(r) => {
        if (r) {
          recaptchaRef.current = r
        }
      }}
      onChange={(val) => {
        if (!val && recaptchaRef.current) {
          recaptchaRef.current.execute()
        }
        onChange(val)
      }}
      onExpired={() => {
        if (recaptchaRef.current) {
          recaptchaRef.current.execute()
        }
      }}
      // onErrored={() => {
      //   if (recaptchaRef.current) {
      //     recaptchaRef.current.execute();
      //   }
      // }}
    />
  )
}
