import { ExtendedTwitterSearchResultInterface } from 'shared-data/interfaces/twitter/ExtendedTwitterSearchResult.interface'
import { TwitterSearchResultInterface } from 'shared-data/interfaces/twitter/TwitterSearchResult.interface'
import { TwitterMediaInterface } from 'shared-data/interfaces/twitter/TwitterMedia.interface'
import { TwitterAttachmentInterface } from 'shared-data/interfaces/twitter/TwitterAttachment.interface'

const findMedia = (attachments?: TwitterAttachmentInterface, mediaList?: TwitterMediaInterface[]) => {
  if (Boolean(attachments) && Boolean(mediaList)) {
    const ml: TwitterMediaInterface[] = []
    attachments?.media_keys?.forEach((mk) => {
      const media = mediaList!.find((m) => m.media_key === mk)
      if (media) {
        ml.push(media)
      }
    })
    return ml
  }
  return []
}

export const twitterPostToExtendedPost = (sr: TwitterSearchResultInterface): ExtendedTwitterSearchResultInterface => {
  const etsr: ExtendedTwitterSearchResultInterface = {
    meta: sr.meta,
    data: sr.data.map((d) => ({
      ...d,
      user: sr.includes.users?.find((u) => u.id === d.author_id),
      media: findMedia(d.attachments, sr.includes?.media),
    })),
  }
  return etsr
}
