import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { TeamInterface } from 'shared-data/interfaces/team.interface'
import { asyncDataInitialState } from '../store/AsyncData'
import { TeamsState } from './teams.state'

const initialState: TeamsState = {
  teams: asyncDataInitialState,
  teamsImIn: asyncDataInitialState,
}

export const getMyTeams = createAsyncThunk('getMyTeams', async () => {
  try {
    return await axios.get('/private/teams')
  } catch (e) {
    console.error(e)
    return { data: [] }
  }
})

export const getTeamsImIn = createAsyncThunk('getTeamsImIn', async () => {
  try {
    return await axios.get('/private/teams/imin')
  } catch (e) {
    console.error(e)
    return { data: [] }
  }
})

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TeamInterface[] | null>) => {
      state.teams.loading = false
      state.teams.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyTeams.fulfilled, (state, action) => {
        state.teams.data = action.payload.data
        state.teams.loading = false
      })
      .addCase(getTeamsImIn.fulfilled, (state, action) => {
        state.teamsImIn.data = action.payload.data
        state.teamsImIn.loading = false
      })
  },
})

export const { setData } = teamsSlice.actions
