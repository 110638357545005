import React, { FC, useEffect, useState } from 'react'
import { QueryInterface } from 'shared-data/interfaces/query.interface'
import styled from 'styled-components'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { TwitterSearchResultInterface } from 'shared-data/interfaces/twitter/TwitterSearchResult.interface'
import { ExtendedTwitterSearchResultInterface } from 'shared-data/interfaces/twitter/ExtendedTwitterSearchResult.interface'
import { shadow, spacing } from '../../config/theme'
import { twitterPostToExtendedPost } from '../../helpers/twitterPostToExtendedPost'
import { usePostApi } from '../../hooks/usePostApi'

interface Props {
  query: QueryInterface
  onDelete: (id: string) => void
}

export const ColumnComponent: FC<Props> = ({ query, onDelete }) => {
  const [searchResults, setSearchResults] = useState<ExtendedTwitterSearchResultInterface>()

  const { initializeFromTwitter } = usePostApi()

  const fetchQueries = async () => {
    const resp = await axios.post<{ query: string }, AxiosResponse<TwitterSearchResultInterface>>(
      `/private/query/search`,
      {
        query: query.query,
      }
    )

    // const resp2 = await axios.post<
    //   { query: string },
    //   AxiosResponse<TwitterSearchResultInterface>
    // >(`/private/twitter/search`, {
    //   query: query.query
    // });
    // console.log('resp2', resp2.data);

    if (resp?.data) {
      renderResultsData(resp.data)
    }
  }

  const renderResultsData = (res) => {
    const r = twitterPostToExtendedPost(res)
    console.log('res', res)
    console.log('r', r)
    setSearchResults(r)
  }

  useEffect(() => {
    void fetchQueries()
  }, [])

  return (
    <Wrapper>
      <InnerWrapper>
        {query.query}
        <button type="button" onClick={() => onDelete(query._id)}>
          x
        </button>
        <button type="button" onClick={() => fetchQueries()}>
          refresh
        </button>
        <ResultsWrapper>
          {searchResults?.data &&
            searchResults.data.map((sr) => (
              <ResultWrapper key={sr.id}>
                <ResultTop>
                  {sr.user?.profile_image_url && (
                    <ResultUserImage width={30} height={30} src={sr.user?.profile_image_url} />
                  )}
                  {sr.user?.username && (
                    <ResultUsername href={`https://twitter.com/${sr.user?.username}`} target="_blank">
                      {`@${sr.user?.username}`}
                    </ResultUsername>
                  )}
                  <ResultTime href={`https://twitter.com/${sr.user?.username}/status/${sr.id}`} target="_blank">
                    {moment(sr.created_at).format('YYYY-MM-DD HH:mm')}
                  </ResultTime>
                </ResultTop>
                <ResultMiddle onClick={() => initializeFromTwitter(sr)}>{sr.text}</ResultMiddle>
                {sr.media && (
                  <ResultMedia>
                    {sr.media.map((m) => (
                      <ResultMediaImage src={m.url} key={m.media_key} />
                    ))}
                  </ResultMedia>
                )}
              </ResultWrapper>
            ))}
        </ResultsWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ResultsWrapper = styled.div`
  max-width: 350px;
  overflow-y: scroll;
  max-height: 642px;
`

const ResultWrapper = styled.div`
  ${shadow()}
  background: white;
  border-bottom: thin solid #dddddd;
  overflow-wrap: break-word;
  padding: ${spacing(2)}px ${spacing(2)}px ${spacing(2)}px 0;
}
`

const ResultTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing(1)}px;
`
const ResultUsername = styled.a`
  color: #5d7ebf;
  font-size: 11px;
  margin-right: ${spacing(1)}px;
`
const ResultUserImage = styled.img`
  width: 30px;
  border-radius: 30px;
  margin-right: ${spacing(1)}px;
`
const ResultMiddle = styled.div`
  cursor: pointer;
`
const ResultMedia = styled.div``
const ResultMediaImage = styled.img``
const ResultTime = styled.a`
  font-size: 10px;
`
