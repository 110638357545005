import React from 'react'
import { useIntercom } from 'react-use-intercom'
import { HelpCenter as HelpIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const HelpButtonComponent = () => {
  const { show } = useIntercom()

  const onClick = () => {
    show()
  }

  return (
    <>
      <IconButton onClick={onClick} sx={{ mr: 2 }}>
        <HelpIcon sx={{ color: 'white' }} />
      </IconButton>
    </>
  )
}
