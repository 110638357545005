import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { LinkedinTargetInterface } from 'shared-data/interfaces/linkedin/linkedinTarget.interface'

interface Props {
  target: LinkedinTargetInterface
  disabled?: boolean
}

export const TargetSelectItem: FC<Props> = ({ target, disabled = false }) => {
  return (
    <Box
      sx={{ display: 'flex', gap: 1, ...(disabled || target.invalid ? { opacity: 0.6, pointerEvents: 'none' } : {}) }}
      alignItems="center"
    >
      <img src={target.image} alt="" width={40} height={40} />
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h6" lineHeight="1">
          {target.name}{' '}
          {target.invalid && (
            <Typography variant="subtitle1" color="red">
              Invalid
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  )
}
