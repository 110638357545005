import { socketHandler } from '../lib/io'
import { getToken } from '../lib/axios'
import { socketMessagesHandler } from '../handlers/socketMessages.handler'

export const socketInitializeService = (roomId?: string, token?: string) => {
  try {
    if (!roomId) return
    if (!socketHandler.getSocket() || !socketHandler.getSocket().connected) {
      const t = token || getToken()
      socketHandler.socketConnect(t).on('connect', () => {
        console.log('SocketAnchor Connected')
        socketHandler.getSocket().removeAllListeners()
        socketHandler.getSocket().emit('join', roomId)
        socketHandler.getSocket().onAny(socketMessagesHandler)
        // socketHandler.getSocket().on('disconnect', () => {
        //   if (getToken()) {
        //     socketInitializeService(roomId);
        //   }
        // });
      })
    }
  } catch (e) {
    console.error(e)
  }
}
