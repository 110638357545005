import React, { FC } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import styled from 'styled-components'
import { nFormatter } from '../../../lib/nFormatter'
// import LikeImage from '../../../assets/images/linkedin/reactions/like.png'

interface Props {
  value: {
    like?: number
    celebrate?: number
    support?: number
    funny?: number
    love?: number
    insightful?: number
    curious?: number
    comments?: number
  }
}

export const ReactionsComponent: FC<Props> = ({
  value: { like = 0, celebrate = 0, support = 0, funny = 0, love = 0, insightful = 0, curious = 0, comments = 0 },
}) => {
  return (
    <Box pr={3} display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!like ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/like.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Like</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(like)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!celebrate ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/celebrate.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Celebrate</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(celebrate)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!support ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/support.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Support</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(support)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!funny ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/funny.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Funny</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(funny)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!love ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/love.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Love</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(love)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={2} sx={!insightful ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/insightful.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Insightful</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(insightful)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mr={1} sx={!curious ? grayedOut : {}}>
        <ImgWrapper>
          <Image src={require('../../../assets/images/linkedin/reactions/curious.png')} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Curious</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(curious)}</Typography>
      </Box>
      <Divider variant="middle" orientation="vertical" sx={{ height: 60 }} />
      <Box display="flex" flexDirection="column" alignItems="center" ml={1} sx={!comments ? grayedOut : {}}>
        <ImgWrapper>
          <ImageSVG src={require('../../../assets/images/linkedin/reactions/comment.svg').default} alt="" />
        </ImgWrapper>
        <Typography sx={{ fontSize: 8 }}>Comments</Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{nFormatter(comments)}</Typography>
      </Box>
    </Box>
  )
}

const ImgWrapper = styled.div`
  width: 43px;
  height: 43px;
`

const Image = styled.img`
  width: 43px;
  height: 43px;
`

const ImageSVG = styled.img`
  width: 33px;
  margin: 5px 0 0 5px;
  height: 33px;
`

const grayedOut = { filter: 'grayscale(1)', opacity: 0.5 }
