import React, { FC, useState } from 'react'
import axios from 'axios'
import FormData from 'form-data'
import { Box, Chip, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { ReactionType } from 'shared-data/types/Reaction.type'
import { useDispatch } from 'react-redux'
import { TagInterface } from 'shared-data/interfaces/tag.interface'
import { DonutChartComponent } from './DonutChart.component'
import { ReactionsComponent } from './Reactions.component'
import { PulseChartComponent } from './PulseChart.component'
import { TagsSelectComponent } from '../../PostComposer/TagsSelect.component'
import { userSlice } from '../../../state/user.slice'

interface Props {
  post: PostInterface
  pulse: number[]
}

export const PostStatisticsComponent: FC<Props> = ({ post, pulse }) => {
  const [isAddingATag, setIsAddingATag] = useState<boolean>(false)
  const [postTags, setTags] = useState<TagInterface[]>(post.tags ?? [])
  const [loading, setIsLoading] = useState(false)
  const d = useDispatch()
  // const onTagDelete = (tag) => {
  //   setIsLoading(true)
  //   console.log(tag)
  //   setIsLoading(false)
  // }

  const onTagAdd = async (tags) => {
    setIsLoading(true)
    const data = new FormData()
    data.append('tags', JSON.stringify(tags))

    try {
      const result = await axios.post(`/private/post/draft/${post._id}`, data, {
        headers: {
          Accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data`,
        },
      })
      setTags(result.data.tags)
    } catch (e) {
      d(userSlice.actions.setError(e?.message ?? e))
    }
    setIsAddingATag(false)
    setIsLoading(false)
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" flexDirection="row" mb={2}>
          <DonutChartComponent value={post.calculatedEngagementRate || 0} />
          <Box pt={2} pl={4}>
            <ReactionsComponent
              value={{
                like: post.reactions ? post.reactions[ReactionType.LIKE] : 0,
                celebrate: post.reactions ? post.reactions[ReactionType.PRAISE] : 0,
                support: post.reactions ? post.reactions[ReactionType.APPRECIATION] : 0,
                funny: post.reactions ? post.reactions[ReactionType.ENTERTAINMENT] : 0,
                love: post.reactions ? post.reactions[ReactionType.EMPATHY] : 0,
                insightful: post.reactions ? post.reactions[ReactionType.INTEREST] : 0,
                curious: post.reactions ? post.reactions[ReactionType.MAYBE] : 0,
                comments: post.comments ? post.comments.count : 0,
              }}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" justifyContent="flex-start">
            <Typography>Tags:</Typography>
            {postTags?.map((tag) => (
              <Chip
                label={tag.name}
                variant="filled"
                color="primary"
                key={tag._id}
                // onDelete={() => {
                //   onTagDelete(tag)
                // }}
              />
            ))}
            {!isAddingATag && (
              <IconButton size="small" aria-label="add a tag" color="default" onClick={() => setIsAddingATag(true)}>
                <AddCircleIcon />
              </IconButton>
            )}
          </Stack>
          {isAddingATag && (
            <TagsSelectComponent
              simple
              onChange={(tags) => {
                if (loading) return
                onTagAdd(tags)
              }}
              propsTags={postTags}
            />
          )}
          {loading && <LinearProgress />}
        </Box>
      </Box>
      <Box flexGrow={0}>
        <PulseChartComponent
          value={Array(14)
            .fill('')
            .map((c, i) => [
              c.toString(),
              post.weeklyPulse ? (post.weeklyPulse[i] ? post.weeklyPulse[i].value : 0) : 0,
              pulse[i],
            ])}
        />
      </Box>
    </Box>
  )
}
