import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LinkedinTargetInterface } from 'shared-data/interfaces/linkedin/linkedinTarget.interface'
import { asyncDataInitialState } from '../store/AsyncData'
import { TargetsState } from './targets.state'

const initialState: TargetsState = {
  targets: asyncDataInitialState,
  teamsTargets: asyncDataInitialState,
}

export const targetsSlice = createSlice({
  name: 'targets',
  initialState,
  reducers: {
    setTargets: (state, action: PayloadAction<LinkedinTargetInterface[] | null>) => {
      state.targets.loading = false
      state.targets.data = action.payload
    },
    setTeamsTargets: (state, action: PayloadAction<LinkedinTargetInterface[] | null>) => {
      state.teamsTargets.loading = false
      state.teamsTargets.data = action.payload
    },
    setLoader: (state, action: PayloadAction<boolean | undefined>) => {
      state.targets.loading = action.payload === undefined ? true : action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.targets.loading = false
      state.targets.error = action.payload
    },
  },
})
