import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2058A5',
    },
    secondary: {
      main: '#EBEBEB',
    },
    success: {
      main: '#09B509',
      contrastText: '#fff',
    },
  },
  // typography: {
  //   h1: {
  //     fontWeight: 700,
  //   },
  //   h2: {
  //     fontWeight: 700,
  //   },
  //   h3: {
  //     fontWeight: 700,
  //   },
  //   h4: {
  //     fontWeight: 700,
  //   },
  //   h5: {
  //     fontWeight: 700,
  //   },
  //   h6: {
  //     fontWeight: 700,
  //   },
  //   button: {
  //     fontWeight: 700,
  //   },
  // },
  components: {
    // Name of the component
    MuiFormGroup: {
      styleOverrides: {
        // Name of the slot
        root: ({ theme }) => ({
          marginBottom: theme.spacing(1),
        }),
      },
    },
  },
})
