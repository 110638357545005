import React from 'react'

export const YoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.602" height="10.205" viewBox="0 0 14.602 10.205">
    <path
      id="Icon_simple-youtube"
      data-name="Icon simple-youtube"
      d="M14.3,7a1.83,1.83,0,0,0-1.271-1.271,43.85,43.85,0,0,0-5.719-.3,42.871,42.871,0,0,0-5.719.3A1.83,1.83,0,0,0,.325,7a19.018,19.018,0,0,0-.318,3.533,19.018,19.018,0,0,0,.318,3.52A1.83,1.83,0,0,0,1.6,15.323a43.754,43.754,0,0,0,5.719.306,43.858,43.858,0,0,0,5.719-.306A1.83,1.83,0,0,0,14.3,14.052a19.018,19.018,0,0,0,.3-3.52A19.018,19.018,0,0,0,14.3,7ZM5.853,12.718V8.341l3.812,2.192Z"
      transform="translate(-0.007 -5.424)"
      fill="#fff"
    />
  </svg>
)
