import React from 'react'

export const Logo = ({ width = 75, light = false }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    viewBox="0 0 573 151"
  >
    <style type="text/css">
      {`
  // .st0{fill:${light ? '#ffffff' : '#414042'};}
  .st1{fill:#3E91CE;}
  .st2{fill:#4B5DAA;}
  .st3{fill:#414EA1;}
  .st4{fill:#CAD9EF;}
  .st5{fill:#3E91CF;} 
  .st6{fill:#5D7EBF;}
  .st7{fill:#416AB3;}
  .st8{fill:#BCBCBC;}
  `}
    </style>
    <g>
      <path
        className="st0"
        style={{ fill: light ? '#ffffff' : '#414042' }}
        d="M176.6,79.2c0-22.9,18.5-41.4,41.4-41.4c19.1,0,35,12.7,39.8,30h-22.1c-3.5-5.8-9.8-9.6-17.5-9.6
		c-11.9,0-20.7,9.1-20.7,21s8.8,21,20.7,21c7.4,0,13.2-3.5,16.8-8.8h22.6c-5.1,17-20.9,29.2-39.6,29.2
		C195.1,120.6,176.6,102.1,176.6,79.2z"
      />
      <path
        className="st0"
        style={{ fill: light ? '#ffffff' : '#414042' }}
        d="M348.6,79.2c0,22.9-18.5,41.4-41.4,41.4s-41.4-18.5-41.4-41.4c0-22.9,18.5-41.4,41.4-41.4
		S348.6,56.2,348.6,79.2z M286.7,79.2c0,11.9,8.8,21,20.7,21s20.4-9.1,20.4-21s-8.5-21-20.4-21S286.7,67.3,286.7,79.2z"
      />
      <path
        className="st0"
        style={{ fill: light ? '#ffffff' : '#414042' }}
        d="M357.4,77.1c0-26.5,14.8-38.3,34.3-38.3h1v20.4h-0.8c-9.1,0-13.6,5.5-13.6,18v42.2h-20.9V77.1z"
      />
      <path
        className="st0"
        style={{ fill: light ? '#ffffff' : '#414042' }}
        d="M432.8,59.4v22c0,12.8,4.2,18,14.4,18v20.4c-20.5,0-35.3-11.4-35.3-38.3v-22h-11.9V39.1H412V7.1h20.9v31.9
		h14.4v20.4H432.8z"
      />
      <path className="st0" style={{ fill: light ? '#ffffff' : '#414042' }} d="M457.4,39.1h20.9v80.2h-20.9V39.1z" />
      <path
        className="st0"
        style={{ fill: light ? '#ffffff' : '#414042' }}
        d="M531,37.8c22.8,0,41.4,18.5,41.4,41.4c0,22.9-18.6,41.4-41.4,41.4c-8.2,0-14.6-2.1-20.2-6.1v36.9h-21V79.2
		C489.8,56.2,508.2,37.8,531,37.8z M551.6,79.2c0-11.9-8.5-21-20.4-21c-11.9,0-20.7,9.1-20.7,21s8.8,21,20.7,21
		C543.1,100.2,551.6,91,551.6,79.2z"
      />
      <g>
        <path
          className="st1"
          d="M455.3,18c0-6.9,5.5-12.4,12.5-12.4c6.9,0,12.4,5.5,12.4,12.4c0,7.1-5.5,12.5-12.4,12.5
			C460.8,30.5,455.3,25,455.3,18z"
        />
      </g>
    </g>
    <polygon className="st2" points="87.8,0.6 18.6,120.5 157.1,120.5 " />
    <polygon className="st3" points="136,52.1 46.1,120.5 175.5,120.5 " />
    <polygon className="st4" points="39.5,52.1 129.4,120.5 0,120.5 " />
    <polygon className="st5" points="52.4,61.9 18.6,120.5 46.1,120.5 87.8,88.8 " />
    <polygon className="st6" points="123.1,61.9 87.8,88.8 129.4,120.5 157.1,120.5 " />
    <polygon className="st7" points="87.8,88.8 129.4,120.5 46.1,120.5 " />
    <rect x="0" y="131.7" className="st8" width="175.5" height="1.5" />
    <rect x="0" y="125.3" className="st8" width="1.5" height="6.5" />
    <rect x="174" y="125.3" className="st8" width="1.5" height="6.5" />
    <rect x="87" y="125.3" className="st8" width="1.5" height="6.5" />
    <rect x="43.5" y="125.3" className="st8" width="1.5" height="6.5" />
    <rect x="130.5" y="125.3" className="st8" width="1.5" height="6.5" />
    <rect x="21.8" y="127.4" className="st8" width="1.5" height="4.4" />
    <rect x="152.3" y="127.4" className="st8" width="1.5" height="4.4" />
    <rect x="65.3" y="127.4" className="st8" width="1.5" height="4.4" />
    <rect x="108.8" y="127.4" className="st8" width="1.5" height="4.4" />
  </svg>
)
