import React from 'react'
import { Box, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { AuditPostInterface } from 'shared-data/interfaces/auditPost.interface'
import { DateTime } from 'luxon'
import { auditToTextHelper } from '../../helpers/auditToText.helper'

interface Props {
  item: AuditPostInterface
}

export const CommentAuditActionComponent: React.FC<Props> = ({ item }) => {
  return (
    <Box sx={{ mb: 2, mx: 1 }} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Box sx={{ lineHeight: 1, textAlign: 'center', color: '#666666', borderBottom: 'thin solid #cecece', pb: 2 }}>
        {item.createdAt && (
          <Timestamp>{DateTime.fromISO(item.createdAt.toString()).toFormat('yyyy LLL dd HH:mm:ss')}</Timestamp>
        )}
        <Tooltip title={item.user?.email} placement="top">
          <Name>
            {item.user?.firstName} {item.user?.lastName}
          </Name>
        </Tooltip>{' '}
        <Details dangerouslySetInnerHTML={{ __html: auditToTextHelper(item) }} />
      </Box>
    </Box>
  )
}

const Timestamp = styled.span`
  font-size: 10px;
  margin-right: 6px;
`

const Name = styled.span`
  font-size: 12px;
  font-weight: bold;
`

const Details = styled.span`
  font-size: 12px;
`
