import _ from 'lodash'

export const didNotChangeHelper = (post, values, ignoreIgnorable = false) => {
  const scheduledAt = (() => {
    if (ignoreIgnorable) return true

    if (post?.scheduledAt) {
      return (
        new Date(post?.scheduledAt).getTime() ===
        (values.scheduledAt ? new Date(values.scheduledAt).getTime() : undefined)
      )
    }
    return !!values.scheduledAt
  })()

  const timeTarget = (() => {
    if (ignoreIgnorable) return true
    if (post?.timeTarget) {
      return post?.timeTarget === values.timeTarget
    }
    return !!post?.timeTarget === !!values.timeTarget
  })()

  const target = (() => {
    if (ignoreIgnorable) return true
    if (post?.target) {
      return post?.targetEntity?._id === values.target
    }
    return false
  })()

  const assignee = (() => {
    if (ignoreIgnorable) return true
    if (post?.assignee) {
      return post?.assignee!._id === values.assignee
    }
    return !values.assignee
  })()

  const progressStatus = (() => {
    if (ignoreIgnorable) return true
    if (post?.progressStatus) {
      return post?.progressStatus === values.progressStatus
    }
    return false
  })()

  const ignorable = { scheduledAt, timeTarget, target, assignee, progressStatus }

  const toReturn = {
    text: post?.text === values.text,
    tags: _.isEqual(values.tags, post?.tags),
    link: (post?.link ?? '') === values.link,
    attachment:
      !post?.attachment === !values?.media && _.isEqual(post?.attachment?.uploadSrc, values?.media?.uploadSrc),

    ...ignorable,
  }
  console.log('changes:', toReturn, 1)
  return toReturn
}
