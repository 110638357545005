import React, { FC } from 'react'
import styled from 'styled-components'
import { spacing } from '../../config/theme'

const Layout: FC = ({ children }) => {
  return (
    <Wrapper>
      <>{children}</>
    </Wrapper>
  )
}

export default Layout

const Wrapper = styled.div`
  padding: ${spacing(8)}px 0;
  min-height: 50vh;
`
