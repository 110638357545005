import React, { useRef, useState } from 'react'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import StopIcon from '@mui/icons-material/Stop'
import { IconButton } from '@mui/material'

const AudioRecorderComponent = ({ onChange }) => {
  const [isRecording, setIsRecording] = useState(false)
  const mediaRecorder = useRef<MediaRecorder | null>(null)
  const audioChunks = useRef<Blob[]>([])

  const recordAudio = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsRecording(true)
        mediaRecorder.current = new MediaRecorder(stream)
        mediaRecorder.current.start()

        mediaRecorder.current.addEventListener('dataavailable', (event) => {
          audioChunks.current.push(event.data)
        })

        mediaRecorder.current.addEventListener('stop', () => {
          const file = new File(audioChunks.current, `record-${new Date().getTime()}`, {
            type: mediaRecorder.current?.mimeType,
          })
          mediaRecorder.current = null
          audioChunks.current = []
          stream.getTracks().forEach((track) => track.stop())
          onChange(file)
          setIsRecording(false)
        })

        return ''
      })
      .catch((e) => {
        alert(e)
      })
  }

  const stopRecord = () => {
    mediaRecorder.current?.stop()
  }

  return (
    <IconButton>
      {isRecording ? (
        <StopIcon color="error" onClick={stopRecord} />
      ) : (
        <KeyboardVoiceIcon color="primary" onClick={recordAudio} />
      )}
    </IconButton>
  )
}

export default AudioRecorderComponent
