import React, { FC, useState } from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { Box, Button, Dialog, DialogContent, DialogContentText, Link, Typography } from '@mui/material'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import { getLinkedinProfileHelper } from 'shared-data/helpers/profiles/getLinkedinProfile.helper'
import { useUserApi } from '../../hooks/useUserApi'
import { AsyncData } from '../../store/AsyncData'
import { linkedInLoginConfig } from '../../helpers/linkedInLogin.helper'

interface Props {
  user: AsyncData<UserInterface>
}

export const LinkedInConnectorModalComponent: FC<Props> = ({ user }) => {
  const linkedinProfile = getLinkedinProfileHelper(user.data)
  const [isOpen, setIsOpen] = useState(!linkedinProfile)
  const { updateUser, load } = useUserApi()
  const { linkedInLogin } = useLinkedIn(linkedInLoginConfig({ updateUser, load }))

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent sx={{ p: 5, position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <svg width="868" height="327" viewBox="0 0 868 327" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M200 254C112.8 281.6 40 270 0 247V0H868C857.667 8.5 757.5 135 585 99C515.902 84.5795 577 303 511.5 324C446 345 309 219.5 200 254Z"
                fill="#2E63BC"
                fillOpacity="0.07"
              />
            </svg>
          </Box>
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <svg width="738" height="83" viewBox="0 0 738 83" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M84 66.6104C41.6 66.6104 10.3333 77.277 0 82.6104H738C732.333 69.6104 714.5 39.5104 688.5 23.1104C656 2.61038 548.5 -6.88962 483 5.61038C417.5 18.1104 392 55.1104 349.5 66.6104C307 78.1104 137 66.6104 84 66.6104Z"
                fill="#2E63BC"
                fillOpacity="0.05"
              />
            </svg>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ position: 'relative', zIndex: 9999 }}>
            <Box>
              <Typography variant="h3">
                Connect <span style={{ color: '#2e63bc' }}>LinkedIn</span>
              </Typography>
              <DialogContentText id="alert-dialog-description" mt={3}>
                Connection to your LinkedIn account is mandatory for this tool to be able to work as intended. In the
                next window, we will ask for various permissions such as the ability to post on your profile, read your
                profile information, access business pages, etc.
              </DialogContentText>
              <DialogContentText mt={3}>
                If you wish to disconnect your LinkedIn account from the third party application, you can do so at any
                time via{' '}
                <Link href="https://www.linkedin.com/psettings/permitted-services" target="_blank">
                  settings
                </Link>
                . Cortip{' '}
                <Link href="https://www.cortip.com/privacy-policy" target="_blank">
                  terms
                </Link>{' '}
                apply.{' '}
                <Link href="https://www.linkedin.com/help/linkedin/answer/1207" target="_blank">
                  Learn more
                </Link>
                .
              </DialogContentText>
            </Box>
            <Box pl={5}>
              {!isLoading && (
                <svg width="283" height="281" viewBox="0 0 283 281" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M126.417 119.892C128.018 118.236 128.905 116.017 128.886 113.714C128.867 111.41 127.944 109.206 126.316 107.576C124.688 105.946 122.485 105.021 120.181 105C117.877 104.979 115.658 105.864 114 107.463L89.072 132.368L79.4429 122.739C77.7955 121.094 75.5625 120.169 73.2343 120.169C70.9061 120.169 68.6731 121.094 67.0257 122.739L44.2414 145.511C33.2339 156.518 26.5826 171.137 25.5167 186.667C24.4508 202.197 29.0424 217.587 38.4429 229.995L2.7963 265.665C1.93311 266.469 1.24077 267.439 0.760576 268.517C0.280385 269.595 0.0221811 270.758 0.0013673 271.938C-0.0194465 273.117 0.197556 274.289 0.639431 275.383C1.08131 276.477 1.739 277.471 2.57328 278.305C3.40755 279.139 4.40132 279.797 5.4953 280.239C6.58927 280.681 7.76104 280.898 8.9407 280.877C10.1204 280.856 11.2838 280.598 12.3615 280.118C13.4392 279.638 14.4091 278.945 15.2134 278.082L50.8834 242.424C63.2894 251.826 78.6793 256.419 94.2091 255.356C109.739 254.292 124.359 247.643 135.367 236.637L158.139 213.853C159.785 212.205 160.709 209.972 160.709 207.644C160.709 205.316 159.785 203.083 158.139 201.435L148.452 191.736L173.356 166.843C174.195 166.032 174.864 165.063 175.324 163.99C175.784 162.918 176.026 161.765 176.035 160.599C176.045 159.432 175.822 158.275 175.38 157.196C174.937 156.116 174.285 155.136 173.459 154.311C172.634 153.487 171.653 152.835 170.573 152.394C169.493 151.952 168.336 151.731 167.169 151.741C166.003 151.752 164.85 151.995 163.778 152.456C162.707 152.917 161.738 153.586 160.927 154.426L136.035 179.319L101.513 144.797L126.417 119.892Z"
                    fill="#494949"
                  />
                  <path
                    d="M244.266 50.5563C253.668 62.9623 258.262 78.3522 257.198 93.882C256.134 109.412 249.485 124.032 238.479 135.04L215.683 157.812C214.036 159.458 211.803 160.382 209.475 160.382C207.146 160.382 204.913 159.458 203.266 157.812L124.569 79.1275C122.924 77.4801 122 75.2471 122 72.9189C122 70.5907 122.924 68.3576 124.569 66.7103L147.354 43.926C158.36 32.9185 172.979 26.2672 188.509 25.2013C204.039 24.1354 219.43 28.727 231.837 38.1275L267.495 2.46917C269.152 0.867983 271.371 -0.0186273 273.674 0.000296831C275.978 0.019221 278.182 0.942167 279.811 2.57035C281.441 4.19853 282.366 6.40167 282.387 8.70526C282.408 11.0088 281.524 13.2286 279.924 14.8863L244.266 50.5563Z"
                    fill="#2E63BC"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M202.217 74.605L209.015 81.4026L205.629 84.7886C208.555 83.8204 212.816 84.5817 216.588 88.3539C223.82 95.5852 221.66 101.178 214.548 108.291L201.375 121.463L194.054 114.142L205.607 102.59C209.657 98.5392 210.962 95.2759 208.469 92.7831C205.012 89.3258 201.114 90.3507 197.243 94.2217L185.688 105.776L178.367 98.455L202.217 74.605ZM166.125 85.5902L173.446 92.9112L197.296 69.0612L189.975 61.7402L166.125 85.5902ZM206.121 62.3332C205.508 62.9471 204.778 63.433 203.976 63.7626C203.173 64.0922 202.313 64.2589 201.445 64.2532C199.68 64.2477 197.99 63.542 196.745 62.2911C195.5 61.0446 194.796 59.3582 194.785 57.5965C194.782 56.7289 194.95 55.8692 195.28 55.0667C195.61 54.2643 196.096 53.5348 196.708 52.9202C197.947 51.6812 199.627 50.9893 201.386 50.9985C203.148 51.0077 204.835 51.7122 206.081 52.9587C207.329 54.2069 208.034 55.8962 208.043 57.6551C208.05 59.4121 207.36 61.0941 206.121 62.3332Z"
                    fill="white"
                  />
                </svg>
              )}
              {isLoading && (
                <Box>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ margin: 'auto', display: 'block' }}
                    width="200px"
                    height="200px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      stroke="#2e63bc"
                      strokeWidth="10"
                      r="35"
                      strokeDasharray="164.93361431346415 56.97787143782138"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                      />
                    </circle>
                  </svg>
                  <Box style={{ textAlign: 'center', color: '#2e63bc', fontWeight: 'bold' }}>Connecting...</Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <Button
              type="button"
              color="success"
              variant="contained"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true)
                setTimeout(() => {
                  linkedInLogin()
                }, 10)
              }}
              size="large"
            >
              Grant access
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
