import { useDispatch } from 'react-redux'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { ExtendedTwitterPostInterface } from 'shared-data/interfaces/twitter/ExtendedTwitterPost.interface'
import { postComposerSlice } from '../state/postComposer.slice'

export const usePostApi = () => {
  const dispatch = useDispatch()

  const initialize = async (post: Pick<PostInterface, 'text'>) => {
    dispatch(postComposerSlice.actions.setLoader())

    const data: PostInterface = {
      text: post.text,
      isDraft: true,
    }
    dispatch(postComposerSlice.actions.setData(data))
    dispatch(postComposerSlice.actions.openComposer({ text: data.text }))
  }

  const initializeFromTwitter = async (post: ExtendedTwitterPostInterface) => {
    dispatch(postComposerSlice.actions.setLoader())

    const data: PostInterface = {
      text: post.text,
      isDraft: true,
    }
    console.log('xxx post', post)
    dispatch(postComposerSlice.actions.setData(data))
    dispatch(postComposerSlice.actions.openComposer({ text: data.text }))
  }

  const discard = async () => {
    dispatch(postComposerSlice.actions.setData(null))
  }

  return {
    initialize,
    discard,
    initializeFromTwitter,
  }
}
