import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { spacing, themeConfig } from '../../config/theme'

interface Props {
  label: string
  onClick: (e?: any) => void
  size?: 'small' | 'medium' | 'large'
  backgroundColor?: string
  buttonType?: 'primary' | 'secondary' | 'info'
  isLoading?: boolean
  isDisabled?: boolean
  type?: 'submit' | 'button' | 'reset' | undefined
}

export const Button: React.FC<Props> = ({
  label,
  onClick,
  size = 'medium',
  buttonType = 'primary',
  backgroundColor,
  isLoading = false,
  isDisabled = false,
  type = 'submit',
}) => {
  return (
    <ButtonStyled onClick={onClick} buttonType={buttonType} label={label} size={size} disabled={isDisabled} type={type}>
      {label}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button<Props>`
  background-color: ${(props: Props) =>
    props.buttonType === 'primary'
      ? themeConfig.color.bg.primary
      : props.buttonType === 'secondary'
      ? themeConfig.color.bg.secondary
      : themeConfig.color.bg.info};
  color: ${(props: Props) =>
    props.buttonType === 'primary'
      ? themeConfig.color.text.primary
      : props.buttonType === 'secondary'
      ? themeConfig.color.text.secondary
      : themeConfig.color.text.info};
  transform: translate(0%);
  transition: 0.3s ease-out;

  font-size: ${(props: Props) =>
    props.size === 'large' ? `${spacing(2.5)}px` : props.size === 'small' ? `${spacing(1.2)}px` : `${spacing(1.5)}px`};
  line-height: ${(props: Props) =>
    props.size === 'large' ? `${spacing(2.5)}px` : props.size === 'small' ? `${spacing(1.2)}px` : `${spacing(1.5)}px`};
  padding: ${(props: Props) =>
    props.size === 'large'
      ? `${spacing(2)}px ${spacing(4)}px;`
      : props.size === 'small'
      ? `${spacing(1)}px ${spacing(1.7)}px;`
      : `${spacing(1.2)}px ${spacing(2)}px;`};

  &:hover {
    background-color: ${(props: Props) =>
      darken(
        0.1,
        props.buttonType === 'primary'
          ? themeConfig.color.bg.primary
          : props.buttonType === 'secondary'
          ? themeConfig.color.bg.secondary
          : themeConfig.color.bg.info
      )};
  }

  &:active {
    background-color: ${(props: Props) =>
      darken(
        0.2,
        props.buttonType === 'primary'
          ? themeConfig.color.bg.primary
          : props.buttonType === 'secondary'
          ? themeConfig.color.bg.secondary
          : themeConfig.color.bg.info
      )};
  }
`
