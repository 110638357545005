import React, { useEffect, useState } from 'react'
import { FormGroup, MenuItem, TextField } from '@mui/material'
import { PostProgressStatusTypes } from 'shared-data/types/postProgressStatus.types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/RootState'

export const PROGRESS_STATUSES = [
  { key: PostProgressStatusTypes.BACKLOG, value: '🟨 Backlog' },
  { key: PostProgressStatusTypes.IN_PROGRESS, value: '🟦 In Progress' },
  { key: PostProgressStatusTypes.BLOCKED, value: '🅱️ Blocked' },
  { key: PostProgressStatusTypes.IN_REVIEW, value: '🟪 In Review' },
  { key: PostProgressStatusTypes.DISCARDED, value: '⬛️ Discarded' },
  { key: PostProgressStatusTypes.REJECTED, value: '🟥 Rejected' },
  { key: PostProgressStatusTypes.DONE, value: '✅ Done' },
]

const ProgressStatusComponent = ({ onChange }) => {
  const [progressStatus, setProgressStatus] = useState<PostProgressStatusTypes>(PostProgressStatusTypes.BACKLOG)
  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)
  useEffect(() => {
    if (post?.progressStatus) {
      setProgressStatus(post.progressStatus)
      onChange(post.progressStatus)
    }
  }, [post])

  return (
    <FormGroup>
      <TextField
        size="small"
        sx={{
          mb: 1,
        }}
        variant="outlined"
        label="Status"
        select
        value={progressStatus}
        onChange={(v) => {
          onChange(v.target.value)
          setProgressStatus(v.target.value as PostProgressStatusTypes)
        }}
      >
        {PROGRESS_STATUSES.map(({ key, value }) => (
          <MenuItem value={key} key={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    </FormGroup>
  )
}

export default ProgressStatusComponent
