import React from 'react'
import { FileInterface } from 'shared-data/interfaces/file.interaface'
import { Box, IconButton, ListItem, Tooltip, Typography, useTheme } from '@mui/material'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material/styles'

interface Props {
  file: FileInterface
  onRemove?: () => void
  onOpen?: () => void
  onEdit?: (src: string) => void
}

const FileRepresentationComponent: React.FC<Props> = ({ file, onRemove, onOpen, onEdit }) => {
  const theme = useTheme()

  return (
    <ListItem sx={{ width: 'auto', flexDirection: 'column' }}>
      <Box
        sx={{
          width: 250,
          height: 150,
          boxShadow: theme.shadows[1],
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {!file.uploadSrc && <Typography sx={{ m: 3 }}>Unprocessed {file.type}</Typography>}
        {file.type === 'image' && (
          <div
            onClick={onOpen}
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${file.uploadSrc})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              cursor: 'pointer',
            }}
          />
        )}
        {file.type === 'video' && <VideoWithShield onClick={onOpen} />}
        {file.type === 'audio' && (
          <Tooltip title={file.audioTranscription || ''} placement="top">
            <audio src={file.uploadSrc} style={{ width: '100%', height: '70%' }} controls preload="none">
              <track kind="captions" />
            </audio>
          </Tooltip>
        )}
        {file.type === 'document' && (
          <a
            href={file.uploadSrc}
            target="_blank"
            rel="noreferrer"
            style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <InsertDriveFileIcon color="secondary" sx={{ width: '100px', height: '100px' }} />
          </a>
        )}
        {onRemove && (
          <RemoveButton onClick={onRemove}>
            <CloseIcon />
          </RemoveButton>
        )}
        {file && file.type === 'image' && file.uploadSrc && onEdit && (
          <EditButton onClick={() => onEdit(file.uploadSrc!)}>
            <EditIcon />
          </EditButton>
        )}
      </Box>
      <Typography variant="body1">{file.name}</Typography>
    </ListItem>
  )
}

export default FileRepresentationComponent

const VideoWithShield = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#000000',
  '&:after': {
    content: '""',
    width: '40px',
    border: '30px solid',
    borderColor: '#000000',
    borderLeft: '45px solid #ffffff',
    display: 'block',
    cursor: 'pointer',
    transform: 'translate(17px, 0px)',
  },
}))

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  height: 20,
  width: 20,
  backgroundColor: `${theme.palette.grey['100']} !important`,
  svg: {
    width: 14,
    height: 14,
  },
}))

const EditButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  right: theme.spacing(1),
  height: 20,
  width: 20,
  backgroundColor: `${theme.palette.grey['100']} !important`,
  svg: {
    width: 14,
    height: 14,
  },
}))
