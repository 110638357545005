import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Link } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DownloadIcon from '@mui/icons-material/Download'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/RootState'
import { nextFile, prevFile, setFile } from '../../state/fileModal.slice'
import FileRepresentationComponent from '../fileRepresentation.component'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 'fit-content',
  },
}))

export const FileModalComponent: FC = () => {
  const file = useSelector((state: RootState) => state.file.file)

  const d = useDispatch()

  const handleClose = () => {
    d(setFile(null))
  }

  if (!file) return <></>

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={!!file}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent
        sx={{ padding: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', width: 'fit-content' }}
      >
        {file.type === 'image' && (
          <img src={file.uploadSrc} alt={file.name} style={{ margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }} />
        )}
        {file.type === 'video' && (
          <video src={file.uploadSrc} autoPlay controls style={{ width: '100%' }}>
            <track kind="captions" />
          </video>
        )}
        {!['image', 'video'].includes(file.type) && <FileRepresentationComponent file={file} />}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => d(prevFile())}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Link href={file.uploadSrc} target="_blank" underline="none">
          <Button>
            Download
            <DownloadIcon />
          </Button>
        </Link>
        <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => d(nextFile())}>
          <ArrowForwardIosIcon />
        </IconButton>
      </DialogActions>
    </BootstrapDialog>
  )
}
