import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { InputWrapper, ServerErrorWrapper, Wrapper } from 'web/src/styles/form.styles'
import { Box, Button, TextField } from '@mui/material'
import { useUserApi } from '../../../hooks/useUserApi'
import { PageTitleAndExcerpt } from '../../PageTitleAndExcerpt/PageTitleAndExcerpt'
import { FormWrapper } from './wrapper.form'
import { RootState } from '../../../store/RootState'

import { ReCaptchaComponent } from '../../ReCaptcha/ReCaptcha.component'

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})

export const PasswordResetRequestForm = () => {
  const user = useSelector((state: RootState) => state.user.user)
  const { passwordResetRequest } = useUserApi()
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false)

  return (
    <Wrapper>
      <PageTitleAndExcerpt title="Reset your password" />
      <FormWrapper>
        <Formik
          initialValues={{
            email: '',
            recaptcha: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={({ email, recaptcha }) => {
            // same shape as initial values
            void passwordResetRequest(email, recaptcha)
          }}
        >
          {({ errors, touched, setFieldValue, handleChange, values }) => (
            <Form>
              <InputWrapper>
                <TextField
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
                {/* {errors.email && touched.email ? <ErrorWrapper>{errors.email}</ErrorWrapper> : null} */}
              </InputWrapper>

              {user?.error && <ServerErrorWrapper>{user?.error}</ServerErrorWrapper>}

              <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <div>Get password reset link to your email</div>

                <ReCaptchaComponent
                  onChange={(val) => {
                    setFieldValue('recaptcha', val)
                    if (val) {
                      setIsRecaptchaLoaded(true)
                    }
                  }}
                />

                <Button type="submit" disabled={!isRecaptchaLoaded} color="success" variant="contained" size="large">
                  Request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
