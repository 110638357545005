import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileInterface } from 'shared-data/interfaces/file.interaface'

export interface FileModal {
  file: FileInterface | null
  filesList: FileInterface[]
}

const initialState: FileModal = {
  file: null,
  filesList: [],
}

export const fileModalSlice = createSlice({
  name: 'fileModal',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<{ file: FileInterface; files?: FileInterface[] } | null>) => {
      if (action.payload) {
        state.file = action.payload.file
        state.filesList = action.payload.files ?? []
      } else {
        state.file = null
        state.filesList = []
      }
    },
    nextFile: (state) => {
      const currentIndex = state.filesList.findIndex(({ _id }) => _id === state.file!._id)
      state.file = state.filesList[currentIndex + 1] ?? state.filesList[0]
    },
    prevFile: (state) => {
      const currentIndex = state.filesList.findIndex(({ _id }) => _id === state.file!._id)
      state.file = state.filesList[currentIndex - 1] ?? state.filesList[state.filesList.length - 1]
    },
  },
})

export const { setFile, nextFile, prevFile } = fileModalSlice.actions
