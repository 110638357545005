import React from 'react'
import styled from 'styled-components'
import { LinkedinIcon } from '../icons/linkedin.icon'
import { FacebookIcon } from '../icons/facebook.icon'
import { InstagramIcon } from '../icons/instagram.icon'
import { TwitterIcon } from '../icons/twitter.icon'
import { YoutubeIcon } from '../icons/youtube.icon'
import { EmailIcon } from '../icons/email.icon'
import { spacing } from '../../config/theme'

export const SocialButtons = () => {
  return (
    <SocialWrapper>
      <a href="https://www.linkedin.com/company/cortip" target="_blank" rel="noreferrer">
        <LinkedinIcon />
      </a>
      <a href="https://www.facebook.com/cortip.b2b/" target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href="https://www.instagram.com/cortip.b2b/" target="_blank" rel="noreferrer">
        <InstagramIcon />
      </a>
      <a href="https://twitter.com/cortip_b2b" target="_blank" rel="noreferrer">
        <TwitterIcon />
      </a>
      <a href="https://www.youtube.com/channel/UCwX-0lwzKENk_aS8bIYXIRA" target="_blank" rel="noreferrer">
        <YoutubeIcon />
      </a>
      <a href="mailto:hi@cortip.com" target="_blank" rel="noreferrer">
        <EmailIcon />
      </a>
    </SocialWrapper>
  )
}

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    margin-right: ${spacing(1.6)}px;
    display: flex;

    :hover svg path {
      fill: #cecece;
    }
  }
`
