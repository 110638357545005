import React, { useLayoutEffect, useState } from 'react'
import { Box, Button, Popover, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { PermissionsInterface } from 'shared-data/interfaces/permissions'
import { PostProgressStatusTypes } from 'shared-data/types/postProgressStatus.types'
import { TimeLeft } from './Timeout.component'
import TimeTargetSelectComponent from './TimeTargetSelect.component'
import { getCorrectTimePickerTime, suggestTimeHelper } from '../../helpers/suggestTime.helper'
import { ButtonWithMenu } from '../buttonWithMenu/buttonWithMenu.component'
import { RootState } from '../../store/RootState'

interface props {
  submitting: boolean
  hasChanges: boolean
  isTimeTarget: boolean
  submitForm: (submitType: string, reason?: string) => void
  permissions: PermissionsInterface
  onScheduledChange: (date: Date | undefined) => void
  onTimeTargetChange: (timeTarget: string | undefined) => void
}

export const ComposerDialogActionsComponent: React.FC<props> = ({
  submitting,
  hasChanges,
  isTimeTarget,
  submitForm,
  permissions,
  onScheduledChange,
  onTimeTargetChange,
}) => {
  const { isCanPostSchedule, isCanDeliver, isCanEdit, isCanCreate, isCanAcceptReject } = permissions
  const [scheduledAt, setScheduledAt] = useState<string | undefined>(undefined)

  const [reasonAnchorEl, setReasonAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleOpenReason = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReasonAnchorEl(event.currentTarget)
  }
  const handleCloseReason = () => {
    setReasonAnchorEl(null)
  }
  const isReasonOpened = Boolean(reasonAnchorEl)
  const reasonId = isReasonOpened ? 'simple-popover' : undefined
  const [reasonText, setReasonText] = useState<string>('')

  const user = useSelector((state: RootState) => state.user)
  const {
    isComposerOpened: isOpened,
    post: { data: post },
    editingPostId,
  } = useSelector((state: RootState) => state.postComposer)

  useLayoutEffect(() => {
    if (!post) {
      onScheduledChange(undefined)
      setScheduledAt(undefined)
    }

    if (isOpened) {
      if (!post?.scheduledAt) {
        axios
          .get('private/suggested_schedule_time')
          .then((t) => {
            const time = suggestTimeHelper(user.user.data?.schedulePostsPerWeek, t.data)
            onScheduledChange(new Date(time))
            setScheduledAt(time)
            return t
          })
          .catch((e) => {
            console.error(e)
            const date = new Date()
            date.setDate(date.getDate() + 1)
            onScheduledChange(date)
          })
      } else {
        onScheduledChange(post.scheduledAt)
        setScheduledAt(getCorrectTimePickerTime(!!post.timeTarget, DateTime.fromJSDate(new Date(post.scheduledAt))))
      }
    }
  }, [post])

  const disabled = submitting || (editingPostId ? !isCanEdit : !isCanCreate || !hasChanges)

  return (
    <Box
      sx={{
        justifyContent: 'flex-end',
        display: 'flex',
        mt: 2,
        height: '40px',
        ...(disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
      }}
    >
      {post?.scheduledAt && !post?.isDraft && <TimeLeft dateTime={new Date(post?.scheduledAt)} />}
      {scheduledAt && (
        <TimeTargetSelectComponent
          onChange={(timeTarget) => {
            setScheduledAt(getCorrectTimePickerTime(!!timeTarget, DateTime.fromJSDate(new Date(scheduledAt))))
            onTimeTargetChange(timeTarget)
          }}
        />
      )}
      <TimePicker
        value={scheduledAt}
        className={!scheduledAt ? 'grey' : undefined}
        type={isTimeTarget ? 'date' : 'datetime-local'}
        min={getCorrectTimePickerTime(isTimeTarget)}
        onChange={(e) => {
          const date = new Date(e.target.value)
          setScheduledAt(e.target.value)
          onScheduledChange(date)
        }}
      />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        sx={{ mr: 1 }}
        onClick={() => {
          void submitForm('draft')
        }}
      >
        Save Draft
      </Button>
      {post?.progressStatus === PostProgressStatusTypes.IN_REVIEW && (
        <>
          <Button
            type="submit"
            disabled={!isCanAcceptReject}
            color="error"
            variant="contained"
            sx={{ mr: 1 }}
            aria-describedby={reasonId}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (!isReasonOpened) {
                handleOpenReason(e)
              }
            }}
          >
            Reject
          </Button>
          <Popover
            id={reasonId}
            open={isReasonOpened}
            anchorEl={reasonAnchorEl}
            onClose={handleCloseReason}
            sx={{ padding: '10px' }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <TextField value={reasonText} onChange={(e) => setReasonText(e.target.value)} placeholder="Reason" />
            <Button
              type="submit"
              disabled={!isCanAcceptReject}
              color="error"
              variant="contained"
              sx={{ m: 1 }}
              aria-describedby={reasonId}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                void submitForm('reject', reasonText)
              }}
            >
              Reject
            </Button>
          </Popover>
        </>
      )}
      {!isCanPostSchedule && isCanDeliver ? (
        <Button
          type="submit"
          color="success"
          variant="contained"
          sx={{ mr: 1 }}
          onClick={() => {
            void submitForm('deliver')
          }}
        >
          Deliver
        </Button>
      ) : post?.progressStatus === PostProgressStatusTypes.IN_REVIEW ? (
        <ButtonWithMenu
          disabled={!isCanAcceptReject}
          color="success"
          label="Accept"
          onClick={() => {
            void submitForm('accept')
          }}
          menuElements={[
            {
              label: 'Accept and Post Now',
              onClick: () => {
                void submitForm('post')
              },
            },
            {
              label: 'Accept and Schedule',
              onClick: () => {
                void submitForm('schedule')
              },
            },
          ]}
        />
      ) : (
        <ButtonWithMenu
          disabled={!isCanPostSchedule}
          color="success"
          label="Schedule"
          onClick={() => {
            void submitForm('schedule')
          }}
          menuElements={[
            {
              label: 'Post Now',
              onClick: () => {
                void submitForm('post')
              },
            },
          ]}
        />
      )}
    </Box>
  )
}

const TimePicker = styled.input`
  border-radius: 5px;
  margin-right: 10px;
  width: fit-content;
`
