import * as Yup from 'yup'
import FormData from 'form-data'
import { SUPPORTED_FORMATS } from '../attachmentDropZone/attachmentDropZone.component'
import axios from '../../lib/axios'

const IMAGE_SIZE = 50 * 1024 * 1024
const VIDEO_SIZE_MIN = 75 * 1024
const VIDEO_SIZE_MAX = 200 * 1024 * 1024

// const todayDate = new Date()

let previouslyCheckedVideoIsValidSoNoNeedToCheckAgain = ''

export const PostSchema = Yup.object().shape({
  text: Yup.string().test('required', (value, { path, parent, createError }) => {
    if (parent.isDraft) return true
    if (!value)
      return createError({
        path,
        message: 'Post text is required in order to deliver engaging and interesting content to your audience.',
      })
    if (value.length < 3)
      return createError({
        path,
        message: 'Your post text is too short. Consider sharing more thoughts with your audience.',
      })
    if (value.length >= 3000)
      return createError({
        path,
        message: 'The post is too long! Maybe you should consider writing an article?',
      })
    return true
  }),
  media: Yup.mixed()
    .optional()
    .test(
      'fileFormat',
      'Unsupported file type',
      (value) => !value || !!value.uploadSrc || (value && SUPPORTED_FORMATS.includes(value.type))
    )
    .test('fileSizeLarge', 'File too large', (value) => {
      if (!value || !!value.uploadSrc) return true
      if (value.type.includes('video')) {
        return value && value.size <= VIDEO_SIZE_MAX
      }
      return value && value.size <= IMAGE_SIZE
    })
    .test(
      'fileSizeSmall',
      'File too small',
      (value) =>
        !value || !!value.uploadSrc || (value && value.type.includes('video') ? value.size >= VIDEO_SIZE_MIN : true)
    )
    .test('validator-custom-name-2', (value, { createError, path }) => {
      return (
        !value ||
        !!value.uploadSrc ||
        (value.type.includes('video') &&
        previouslyCheckedVideoIsValidSoNoNeedToCheckAgain !== `${value.name}-${value.size}`
          ? (async () => {
              previouslyCheckedVideoIsValidSoNoNeedToCheckAgain = `${value.name}-${value.size}`
              const data = new FormData()
              console.log('called')
              data.append('media', value)
              try {
                return (
                  await axios.post('/public/check-media-info', data, {
                    headers: {
                      Accept: 'application/json',
                      'Accept-Language': 'en-US,en;q=0.8',
                      'Content-Type': `multipart/form-data`,
                    },
                  })
                ).data
              } catch (e) {
                console.error(e)
              }
              return {}
            })().then(({ info }) => {
              previouslyCheckedVideoIsValidSoNoNeedToCheckAgain = ''
              if (info.Duration < 3) {
                return createError({
                  path,
                  message: 'File duration is too small',
                })
              }
              if (info.Duration > 30 * 60) {
                return createError({
                  path,
                  message: 'File duration is too Big',
                })
              }
              if (info.Width < 256 || info.Height < 144) {
                return createError({
                  path,
                  message: 'Resolution too small',
                })
              }
              if (info.Width > 4096 || info.Height > 2304) {
                return createError({
                  path,
                  message: 'Resolution too Big',
                })
              }
              if (!(1 / 2.4 <= +info.DisplayAspectRatio && +info.DisplayAspectRatio <= 2.4)) {
                return createError({
                  path,
                  message: 'aspect',
                })
              }

              if (!(+info.FrameRate >= 10 && +info.FrameRate <= 60)) {
                return createError({
                  path,
                  message: 'framerate',
                })
              }
              if (!(+info.BitRate >= 192 * 8 * 1024 && +info.BitRate <= 30 * 8 * 1024 * 1024)) {
                return createError({
                  path,
                  message: 'bitrate',
                })
              }
              previouslyCheckedVideoIsValidSoNoNeedToCheckAgain = `${value.name}-${value.size}`
              return true
            })
          : true)
      )
    }),
  link: Yup.string()
    .optional()
    .test('isLinkValid', (s: string, { createError, path }) => {
      if (!s) {
        return true
      }
      try {
        // eslint-disable-next-line no-new
        new URL(s)
        return true
      } catch (err) {
        return createError({
          path,
          message: 'Link is invalid',
        })
      }
    }),
  scheduledAt: Yup.date().test('required', (value, { path, parent, createError }) => {
    if (parent.isDraft) return true
    if (parent.submitType === 'schedule') {
      if (!value) {
        return createError({
          path,
          message: 'Date is required scheduling',
        })
      }
      if (value && new Date(value).getTime() < new Date().getTime() + 60000)
        return createError({
          path,
          message: 'Date should not be less than now',
        })
    }
    return true
  }),
})
