import React, { FC } from 'react'
import { Card, Divider, Skeleton } from '@mui/material'

const PostSkeleton: FC<{ attachment?: boolean }> = ({ attachment = false }) => {
  return (
    <Card
      sx={{
        maxWidth: 410,
        height: attachment ? 380 : 190,
        mt: 3,
        position: 'relative',
        padding: 2,
      }}
      elevation={10}
    >
      <Skeleton variant="text" width={350} />
      <Skeleton variant="text" sx={{ mt: 1 }} />
      <Skeleton variant="text" width={100} />
      {attachment && <Skeleton variant="rectangular" height={200} />}
      <Divider sx={{ mt: 2 }} />
      <Skeleton variant="text" width={100} sx={{ mt: 2 }} />
    </Card>
  )
}

export default PostSkeleton
