import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Groups as TeamIcon } from '@mui/icons-material'
import axios from 'axios'
import { TeamInterface } from 'shared-data/interfaces/team.interface'
import { UserInterface } from 'shared-data/interfaces/user.interface'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import { RootState } from '../store/RootState'
import { getMyTeams, getTeamsImIn } from '../state/teams.slice'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'
import Loader from '../components/loader'
import { getTargets } from '../state/timeTargets.slice'
import { getMyOrganization } from '../state/organization.slice'
import { TeamItem } from '../components/Teams/TeamItem.component'
import { TeamTargetComponent } from '../components/Teams/TeamTarget.component'

export const TeamsPage = () => {
  const { loading: teamsLoading, data: teams } = useSelector((state: RootState) => state.teams?.teams || [])
  const { loading: teamsImInLoading, data: teamsImIn } = useSelector((state: RootState) => state.teams?.teamsImIn || [])
  const { loading: targetsLoading, data: targets } = useSelector((state: RootState) => state.targets.targets || [])
  const { loading: orgLoading, data: organization } = useSelector(
    (state: RootState) => state.organization.organization || []
  )

  const d = useDispatch()

  useEffect(() => {
    ;(async () => {
      d(getMyTeams())
      d(getTeamsImIn())
      d(getTargets())
      d(getMyOrganization())
    })()
  }, [])

  useEffect(() => {
    setDocumentTitleHelper('Teams')
  }, [])

  const onCreateNewTeam = async () => {
    handleNewTeamClose()
    await axios.post('/private/teams', { name: newTeamName, targetUrn: newTeamTarget })
    d(getMyTeams())
  }

  const onDeleteTeam = async (team: TeamInterface) => {
    if (window.confirm(`Are you sure you want to delete ${team.name} permanently?`)) {
      await axios.post('/private/teams/delete', { id: team._id })
      d(getMyTeams())
    }
  }

  const onUpdateName = async (teamId, name: string) => {
    await axios.post('/private/teams/update', { id: teamId, key: 'name', value: name })
    d(getMyTeams())
  }

  const onUpdateBooleanKey = async (teamId, key: string, value: boolean) => {
    await axios.post('/private/teams/update', { id: teamId, key, value })
    d(getMyTeams())
  }

  const onUpdateMembers = async (teamId, members: UserInterface[]) => {
    await axios.post('/private/teams/update/members', { id: teamId, members: members.map((m) => m._id) })
    d(getMyTeams())
  }

  const [newTeamName, setNewTeamName] = useState(`content team`)
  const [newTeamTarget, setNewTeamTarget] = useState('')
  const [newTeamAnchorEl, setNewTeamAnchorEl] = React.useState<null | HTMLElement>(null)
  const isNewTeamOpen = Boolean(newTeamAnchorEl)
  const onNewTeamClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNewTeamAnchorEl(event.currentTarget)
  }
  const handleNewTeamClose = () => {
    setNewTeamAnchorEl(null)
  }

  useEffect(() => {
    if (targets && targets.length > 0) {
      setNewTeamTarget(targets[0].urn)
      setNewTeamName(`${targets[0].name} content team`)
    }
  }, [targets])

  if (
    orgLoading ||
    teamsLoading ||
    teamsImInLoading ||
    targetsLoading ||
    !teams ||
    !teamsImIn ||
    !targets ||
    !organization
  ) {
    return <Loader />
  }

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Box display="flex" justifyContent="flex-end">
            <Button endIcon={<TeamIcon />} onClick={onNewTeamClick}>
              Create new team
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={newTeamAnchorEl}
              open={isNewTeamOpen}
              onClose={handleNewTeamClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box py={2} px={3} display="=flex" flexDirection="row" alignItems="center">
                <TextField
                  type="text"
                  placeholder="My personal timeline content"
                  inputMode="text"
                  variant="outlined"
                  size="small"
                  value={newTeamName}
                  sx={{ width: 300 }}
                  onChange={(e) => setNewTeamName(e.target.value)}
                />
                <Select
                  sx={{ ml: 2 }}
                  size="small"
                  value={newTeamTarget}
                  onChange={(e) => {
                    setNewTeamName(`${targets.find((t) => t.urn === e.target.value)!.name} content team`)
                    setNewTeamTarget(e.target.value)
                  }}
                >
                  {targets.map((target) => (
                    <MenuItem key={target.urn} value={target.urn}>
                      {target.name}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  disabled={!newTeamName || !newTeamTarget}
                  variant="contained"
                  color="success"
                  sx={{ ml: 2, marginTop: '-4px' }}
                  onClick={onCreateNewTeam}
                >
                  Create
                </Button>
              </Box>
            </Menu>
          </Box>
          <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
            {teams?.length ? 'Teams' : `You do not have any teams`}
          </Typography>
          <Box sx={{ display: 'flex', mt: 3, mb: 5, flexDirection: 'column' }}>
            <Box>
              {teams.map((t) => (
                <TeamItem
                  key={t._id}
                  team={t}
                  organization={organization}
                  onUpdateName={(name) => onUpdateName(t._id, name)}
                  onUpdateMembers={(members) => onUpdateMembers(t._id, members)}
                  onDelete={onDeleteTeam}
                  onUpdateBooleanKey={(k, v) => onUpdateBooleanKey(t._id, k, v)}
                />
              ))}
            </Box>
          </Box>
          <Divider sx={{ my: 5 }} />
          <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
            {teamsImIn.length ? "Teams I'm in" : `You do not belong in any other teams`}
          </Typography>
          <Box sx={{ display: 'flex', mt: 3 }}>
            <TableContainer sx={{ width: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Target</TableCell>
                    <TableCell>Team</TableCell>
                    <TableCell>Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamsImIn.map((t) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} sx={{ mb: 1 }} key={t._id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <TeamTargetComponent target={t.target} />
                            <Typography variant="h6" ml={1}>
                              {t.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {t.users.map((u) => (
                            <Chip key={u._id} label={`${u.firstName} ${u.lastName}`} sx={{ mr: 1 }} />
                          ))}
                        </TableCell>
                        <TableCell>
                          {t.isTargetOwnerApprovalRequired && (
                            <Chip variant="outlined" label="Requires approval" sx={{ mr: 1 }} />
                          )}
                          {t.isCanSetAsTarget && <Chip variant="outlined" label="Can use target" sx={{ mr: 1 }} />}
                          {t.isCanEditDraft && <Chip variant="outlined" label="All drafts" sx={{ mr: 1 }} />}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
