import React from 'react'

export const LinkedinIcon = ({ fill = '#fff', size = 12.78 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <path
      id="Icon_simple-linkedin"
      data-name="Icon simple-linkedin"
      d="M10.888,10.891H9V7.925c0-.707-.014-1.617-.986-1.617s-1.137.769-1.137,1.565v3.018H4.98v-6.1H6.8v.831h.024a1.994,1.994,0,0,1,1.795-.985c1.918,0,2.272,1.262,2.272,2.9v3.347ZM2.842,3.958a1.1,1.1,0,1,1,1.1-1.1A1.1,1.1,0,0,1,2.842,3.958Zm.949,6.933h-1.9v-6.1h1.9ZM11.835,0H.943A.932.932,0,0,0,0,.921V11.86a.931.931,0,0,0,.943.921h10.89a.935.935,0,0,0,.947-.921V.921A.935.935,0,0,0,11.834,0Z"
      fill={fill}
    />
  </svg>
)
