import React, { useEffect, useState } from 'react'
import { Box, Button, FormGroup, MenuItem, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { AssigneeInterface } from 'shared-data/interfaces/assignee.interface'
import { RootState } from '../../../store/RootState'

const AssigneesSelectComponent = ({ onChange, target }) => {
  const { data: me } = useSelector((state: RootState) => state.user?.user)
  const [selectedAssignee, setSelectedAssignee] = useState<AssigneeInterface | undefined>({
    name: 'Unassigned',
    _id: 'Unassigned',
    image: undefined,
  })
  const [assigneeList, setAssigneeList] = useState<AssigneeInterface[] | undefined>()

  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)

  useEffect(() => {
    ;(async () => {
      if (target && post) {
        const { data: assignees } = await axios.get(`/private/assignees/${target}`)
        setAssigneeList(assignees)
        const assigneeToSelect =
          assignees.length > 0 && post?.assignee
            ? assignees.find(({ _id }) => post.assignee!._id === _id)
            : {
                name: 'Unassigned',
                id: 'Unassigned',
                image: undefined,
              }
        if (assigneeToSelect) {
          setSelectedAssignee(undefined)
          setTimeout(() => {
            setSelectedAssignee(assigneeToSelect)
            onChange(assigneeToSelect?._id === 'Unassigned' ? undefined : assigneeToSelect?._id)
          })
        }
      }
    })()
  }, [target, post])

  if (!assigneeList) return <></>

  return (
    <FormGroup>
      <TextField
        select
        size="small"
        sx={{
          mb: 1,
          width: '100%',
        }}
        variant="outlined"
        label="Assignee"
        name="assignee"
        value={selectedAssignee?._id}
        onChange={(v) => {
          onChange(v.target.value === 'Unassigned' ? undefined : v.target.value)
          const toSet = assigneeList.find(
            (t) => t._id === (v.target.value === 'Unassigned' ? undefined : v.target.value)
          )
          if (toSet) {
            setSelectedAssignee(toSet)
          }
        }}
      >
        {assigneeList.map((u) => (
          <MenuItem value={u._id ?? 'Unassigned'} key={u._id ?? 'Unassigned'}>
            {u.name}
          </MenuItem>
        ))}
      </TextField>
      <Box display="flex" justifyContent="flex-end">
        <Button
          size="small"
          onClick={() => {
            if (me) {
              onChange(me._id)
              const toSet = assigneeList.find(({ _id }) => _id === me._id)
              if (toSet) {
                setSelectedAssignee(toSet)
              }
            }
          }}
        >
          Assign to me
        </Button>
      </Box>
    </FormGroup>
  )
}

export default AssigneesSelectComponent
