import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import FormData from 'form-data'
import axios from 'axios'
import { Box, Dialog, DialogContent, IconButton, List, Typography } from '@mui/material'
import { FileInterface } from 'shared-data/interfaces/file.interaface'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { AttachFile as AttachFileIcon } from '@mui/icons-material'
import ImageEditor from '@toast-ui/react-image-editor'
import FileRepresentationComponent from '../../fileRepresentation.component'
import { setFile } from '../../../state/fileModal.slice'
// import { SUPPORTED_FORMATS } from '../../attachmentDropZone/attachmentDropZone.component'
import { RootState } from '../../../store/RootState'
import 'tui-image-editor/dist/tui-image-editor.css'
import { CommonMessage } from '../../commonMessage/CommonMessage.component'
import AudioRecorderComponent from './audioRecorder.component'

type FilesListComponent = {
  setSubmitting: (c: boolean) => void
  filesChange: (c: File[]) => void
}

const FilesListComponent: React.FC<FilesListComponent> = ({ setSubmitting, filesChange }) => {
  const [files, setFiles] = useState<FileInterface[]>([])
  const [fileToRemove, setFileToRemove] = useState<{ index: number; id: string | undefined } | undefined>()
  const d = useDispatch()

  const {
    post: { data: post },
    editingPostId,
  } = useSelector((state: RootState) => state.postComposer)

  useEffect(() => {
    if (post?.files?.length) {
      setFiles(post.files)
    }
  }, [post])

  const removeFile = useCallback(async () => {
    if (!fileToRemove) return
    const { index, id } = fileToRemove
    try {
      if (!files.length) return
      setFiles((f) => {
        const d = [...(f || [])]
        d.splice(index, 1)
        return d
      })
      if (editingPostId) {
        setSubmitting(true)

        await axios.put(
          `/private/remove_files_from_post/${editingPostId}`,
          { id },
          {
            headers: {
              Accept: 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `application/json`,
            },
          }
        )
        setSubmitting(false)
      }
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false)
  }, [fileToRemove])

  const onChange = useCallback(
    async (files, e?: ChangeEvent<HTMLInputElement>) => {
      if (editingPostId) {
        if (files?.length) {
          setSubmitting(true)
          const f = new FormData()
          ;[...files].forEach((c) => {
            f.append('media[]', c)
          })
          const resultOfFiles = await axios.put(`/private/attach_files_to_post/${editingPostId}`, f, {
            headers: {
              Accept: 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data`,
            },
          })
          setFiles((f) => [...f, ...(resultOfFiles.data.files || [])])
          setSubmitting(false)
        }
      } else {
        setFiles((f) => {
          const toSet = [...f, ...(files || [])]
          filesChange(toSet)
          return toSet
        })
      }
      if (e?.target) {
        // @ts-ignore
        e.target.value = ''
      }
    },
    [editingPostId]
  )

  const inputRef = useRef<any>(null)
  const selectAttachment = () => {
    inputRef.current?.click()
  }

  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false)
  const [editedImageUrl, setEditedImageUrl] = useState('')

  const onCloseImageEditor = () => {
    setIsImageEditorOpen(false)
  }

  const onImageEditClick = (imageUrl: string) => {
    // Nice sample https://codesandbox.io/s/7ytvs?file=/src/white-theme.js
    setEditedImageUrl(`${process.env.REACT_APP_API_URL}/private/proxy/img?url=${imageUrl}`)
    setIsImageEditorOpen(true)
  }

  return (
    <Box
      sx={{ height: '100%', position: 'relative' }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      flexGrow={1}
    >
      <CommonMessage
        open={!!fileToRemove}
        title="Confirm Deletion"
        message="Are you sure you want to delete the file"
        cancel="Cancel"
        okMessage="Confirm"
        handleClose={(c) => {
          if (c) {
            void removeFile()
          }
          setFileToRemove(undefined)
        }}
      />
      <Dialog
        open={isImageEditorOpen && Boolean(editedImageUrl)}
        fullWidth
        fullScreen
        sx={{ padding: 10 }}
        onClose={onCloseImageEditor}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box>
            <ImageEditor
              {...{
                includeUI: {
                  loadImage: {
                    path: editedImageUrl,
                    name: 'EditedImage',
                  },
                  uiSize: {
                    // width: '90%',
                    height: `${window.screen.availHeight * 0.7}px`,
                  },
                  menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'],
                  menuBarPosition: 'bottom',
                  // theme: whiteTheme,
                },
                cssMaxWidth: 1200,
                cssMaxHeight: 800,
                selectionStyle: {
                  cornerSize: 20,
                  rotatingPointOffset: 70,
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{ backgroundColor: '#e7e7e7', px: 1, py: 0.5, mb: 1, width: '100%' }}
        display="flex"
        position="relative"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={0}
      >
        <Typography variant="body1">
          Attachments <small>({files.length})</small>
        </Typography>
        <FileInput
          type="file"
          ref={inputRef}
          onChange={(e) => {
            onChange(e.target.files, e)
          }}
        />
        <Box>
          <IconButton onClick={selectAttachment}>
            <AttachFileIcon />
          </IconButton>
          <AudioRecorderComponent onChange={(file) => onChange([file])} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        flexBasis={1}
        flexWrap="wrap"
        sx={{ overflowY: 'scroll', position: 'relative' }}
      >
        <List sx={{ mb: 5 }}>
          {files.map((file, index) => {
            return (
              <FileRepresentationComponent
                file={file}
                onRemove={() => setFileToRemove({ index, id: file._id })}
                onOpen={() => {
                  d(setFile({ file, files }))
                }}
                onEdit={onImageEditClick}
              />
            )
          })}
        </List>
      </Box>
      <Gradient />
    </Box>
  )
}

export default FilesListComponent

const FileInput = styled('input')(() => ({
  display: 'none',
}))

const Gradient = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '60px',
  background: 'linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%)',
}))
