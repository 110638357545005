import React, { FC } from 'react'
import styled from 'styled-components'
import { findWithRegexInAllText, HASHTAG_REGEX } from '../PostTextInput/PostTextInput.component'
import { spacing } from '../../config/theme'

interface Props {
  text: string
  onSelect: (text: string, hashtags: any) => void
}

export const PostSuggestionItem: FC<Props> = ({ text, onSelect }) => {
  return (
    <Wrapper>
      <TextWrapper>{text}</TextWrapper>
      <ActionsWrapper>
        <UseThisButton type="button" onClick={() => onSelect(text, findWithRegexInAllText(HASHTAG_REGEX, text))}>
          Use this
        </UseThisButton>
      </ActionsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 ${spacing(6)}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  font-size: 14px;
  padding: ${spacing(1)}px;
  border-radius: 15px;
  border: thin solid #777;
`

const ActionsWrapper = styled.div``

const UseThisButton = styled.button`
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 15px;
  margin-top: 16px;
`
