import { Container } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Buffer } from 'buffer'
import { SignupForm } from '../components/auth/forms/signup.form'
import { RootState } from '../store/RootState'
import Loader from '../components/loader'
import { InvitationAcceptComponent } from '../components/InvitationAccept/InvitationAccept.component'
import { useUserApi } from '../hooks/useUserApi'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const InvitationPage: FC = () => {
  const { loading, data: user } = useSelector((state: RootState) => state.user.user)

  const { signOut } = useUserApi()

  const { token } = useParams()
  const [searchParams] = useSearchParams()
  const email = Buffer.from(String(searchParams.get('email')), 'base64').toString()

  useEffect(() => {
    setDocumentTitleHelper('Invitation')
  }, [])

  if (user?.email && email !== user?.email) {
    signOut(`/?email=${searchParams.get('email')}`)
  }

  if (loading || !token || !email) {
    return <Loader />
  }

  if (user?._id) {
    return (
      <Container>
        <InvitationAcceptComponent token={token} email={email} />
      </Container>
    )
  }

  return (
    <Container>
      <SignupForm token={token} email={email} />
    </Container>
  )
}
