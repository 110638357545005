import { Box, Button, Typography } from '@mui/material'
import React, { FC } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Layout from '../layout'

interface Props {
  email: string
  token: string
}

export const InvitationAcceptComponent: FC<Props> = ({ email, token }) => {
  const nav = useNavigate()

  const onAcceptClick = async () => {
    await axios.post('/private/org/invitation/accept', { token, email })
    nav('/')
  }

  return (
    <Layout>
      <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
        Invitation
      </Typography>
      <Typography my={5}>You have been invited to an organization.</Typography>
      <Box>
        <Button variant="contained" onClick={onAcceptClick}>
          Accept invitation
        </Button>
      </Box>
    </Layout>
  )
}
