import React, { FC } from 'react'
import { Box, Chip, List, Typography } from '@mui/material'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { PostProgressStatusTypes } from 'shared-data/types/postProgressStatus.types'
import { useDispatch } from 'react-redux'
import FileRepresentationComponent from '../../fileRepresentation.component'
import { setFile } from '../../../state/fileModal.slice'

interface Props {
  post: PostInterface
}

const ResolveStatus = ({ status = PostProgressStatusTypes.BACKLOG }: { status?: PostProgressStatusTypes }) => {
  switch (status) {
    case PostProgressStatusTypes.BACKLOG:
      return <Chip label="Backlog" variant="outlined" color="info" />
    case PostProgressStatusTypes.BLOCKED:
      return <Chip label="Blocked" variant="filled" color="error" />
    case PostProgressStatusTypes.DISCARDED:
      return <Chip label="Discarded" variant="filled" color="secondary" />
    case PostProgressStatusTypes.IN_PROGRESS:
      return <Chip label="In Progress" variant="outlined" color="success" />
    case PostProgressStatusTypes.IN_REVIEW:
      return <Chip label="In Review" variant="outlined" color="success" />
    case PostProgressStatusTypes.REJECTED:
      return <Chip label="Rejected" variant="filled" color="warning" />
    case PostProgressStatusTypes.DONE:
      return <Chip label="Done" variant="filled" color="success" />
    default:
      return <Chip label="To be determined" variant="outlined" color="info" />
  }
}

export const PostItemDraftAttachmentsComponent: FC<Props> = ({ post }) => {
  const d = useDispatch()
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexDirection="column" flexGrow={1} pb={5}>
        <Box sx={{ marginTop: '-13px' }}>
          <ResolveStatus status={post.progressStatus} />
        </Box>
        <Box mt={3}>
          <Typography variant="h6"> Files and attachments list </Typography>
          <List sx={{ display: 'flex', justifyContent: 'flex-start', overflow: 'auto' }}>
            {post.files?.map((c) => (
              <FileRepresentationComponent
                file={c}
                key={c._id}
                onOpen={() => {
                  d(setFile({ file: c, files: post.files }))
                }}
              />
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
