import React, { useEffect } from 'react'
import styled from 'styled-components'
import timeZones from 'shared-data/timeTarget/timeZones.json'
import { Box, Button, Card, CardActionArea, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _f } from 'shared-data/features/getFeatureConfig.service'
import { FeatureTypes } from 'shared-data/features/feature.types'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import { editTimeTarget, getTargets, openModal } from '../state/timeTargets.slice'
import { RootState } from '../store/RootState'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const TimeTargetPage = () => {
  const user = useSelector((state: RootState) => state.user.user)
  const timeTargets = useSelector((state: RootState) => state.timeTargets.targets?.data || [])

  const d = useDispatch()

  useEffect(() => {
    ;(async () => {
      d(getTargets())
    })()
  }, [])

  useEffect(() => {
    setDocumentTitleHelper('Time Targets')
  }, [])

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Button
            variant="contained"
            color="success"
            onClick={() => d(openModal())}
            disabled={!_f(FeatureTypes.TIME_TARGET, user?.data?.plan).enabled}
          >
            Create new Time Target
          </Button>
          <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
            {timeTargets.length ? 'Time targets' : `You do not have custom time targets`}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: 1 }}>
              {timeTargets.map((t) => {
                const item = timeZones.find(({ value }) => value === t.value)
                if (!item) return false
                return (
                  <Card sx={{ mb: 1 }} onClick={() => d(editTimeTarget(t._id))} key={t._id}>
                    <CardActionArea sx={{ p: 1 }}>
                      <Typography variant="h6">{t.name}</Typography>
                      <Typography variant="body1">
                        {item.text} | {item.value}
                      </Typography>
                    </CardActionArea>
                  </Card>
                )
              })}
            </Box>
            {/* <Box sx={{ width: 1 / 2 }} /> */}
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
