import React from 'react'

export const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.18" height="11.385" viewBox="0 0 15.18 11.385">
    <path
      id="Icon_awesome-envelope"
      data-name="Icon awesome-envelope"
      d="M14.892,8.259a.178.178,0,0,1,.288.139v6.063a1.423,1.423,0,0,1-1.423,1.423H1.423A1.423,1.423,0,0,1,0,14.462V8.4a.177.177,0,0,1,.288-.139c.664.516,1.545,1.171,4.569,3.368.626.457,1.681,1.417,2.734,1.411s2.135-.972,2.737-1.411C13.351,9.433,14.228,8.775,14.892,8.259ZM7.59,12.09c.688.012,1.678-.866,2.176-1.227C13.7,8.007,14,7.758,14.907,7.047a.709.709,0,0,0,.273-.56V5.923A1.423,1.423,0,0,0,13.757,4.5H1.423A1.423,1.423,0,0,0,0,5.923v.563a.714.714,0,0,0,.273.56c.907.709,1.207.961,5.141,3.816C5.912,11.224,6.9,12.1,7.59,12.09Z"
      transform="translate(0 -4.5)"
      fill="#fff"
    />
  </svg>
)
