import { FeatureTypes } from './feature.types'
import { PlanTypes } from './plan.types'
import { featuresConfig } from './features.config'
import { FeatureAvailabilityInterface } from './featureAvailability.interface'

/**
 * This function returns configuration for a specific feature. This should be used on both backend and frontend to
 * verify whether the user is able to use a specific feature and if there are any limitations to that.
 * @param feature
 * @param plan
 */
export const getFeatureConfigService = (feature: FeatureTypes, plan?: PlanTypes): FeatureAvailabilityInterface => {
  const p = plan || PlanTypes.FREE
  if (featuresConfig && featuresConfig[feature] && featuresConfig[feature][p]) {
    return featuresConfig[feature][p]
  }
  return {
    enabled: false,
    specialLimit: true,
    countLimit: 0,
  }
}

export const _f = getFeatureConfigService
