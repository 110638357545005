import React, { FC } from 'react'
import styled from 'styled-components'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ErrorWrapper, InputWrapper, SubmitWrapper } from '../../styles/form.styles'

import { Button } from '../button'
import { spacing } from '../../config/theme'

interface Props {
  query: string
  onSave: (q: string) => void
  onCancel: () => void
}

const QuerySchema = Yup.object().shape({
  query: Yup.string().min(3, 'Too Short!').max(255, 'Too Long!').required('Query is required'),
})

export const ColumnEditComponent: FC<Props> = ({ query, onSave, onCancel }) => {
  return (
    <Wrapper>
      <>
        <Formik
          initialValues={{
            query,
          }}
          validationSchema={QuerySchema}
          onSubmit={({ query: q }) => {
            // same shape as initial values
            onSave(q)
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <InputWrapper>
                <Field name="query" placeholder="Query" />
                {errors.query && touched.query ? <ErrorWrapper>{errors.query}</ErrorWrapper> : null}
              </InputWrapper>

              <SubmitWrapper>
                <Button type="submit" buttonType="secondary" label="Cancel" onClick={onCancel} />
                <Button type="submit" buttonType="primary" label="Save" onClick={() => {}} />
              </SubmitWrapper>
              <QueryLink>
                You can learn more about Twitter search query capabilities
                <a
                  href="https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query
                </a>
              </QueryLink>
            </Form>
          )}
        </Formik>
      </>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const QueryLink = styled.div`
  text-align: center;
  padding: ${spacing(3)}px;
  margin-top: ${spacing(3)}px;
  a {
    margin-top: ${spacing(1)}px;
    display: block;
    color: #5d7ebf;
    text-overflow: ellipsis;
    line-break: anywhere;
  }
`
