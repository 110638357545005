import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/RootState'
import ProgressStatusComponent from './progressStatus.component'
import AssigneesSelectComponent from './assigneesSelect.component'

const TeamSettingsComponent = ({ setSubmitting, onAssigneeChange, onProgressStatusChange, target }) => {
  const { data: organization } = useSelector((state: RootState) => state.organization.organization)

  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)

  if (!post || !organization) return <></>

  return (
    <>
      {/* <Typography variant="subtitle2" sx={{ mb: 1 }}> */}
      {/*  Team settings */}
      {/* </Typography> */}
      <ProgressStatusComponent onChange={onProgressStatusChange} />
      <AssigneesSelectComponent onChange={onAssigneeChange} target={target} />
    </>
  )
}

export default TeamSettingsComponent
