import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _f } from 'shared-data/features/getFeatureConfig.service'
import { FeatureTypes } from 'shared-data/features/feature.types'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import PostComponent from '../components/Post/Post.component'
import { openComposer } from '../state/postComposer.slice'
import { RootState } from '../store/RootState'
import { getPosts, getPulse, setLoader } from '../state/posts.slice'
import PostSkeleton from '../components/Post/PostSkeleton.component'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'
import { FiltersComponent } from '../components/Filters/Filters.component'

export const TimelinePage = () => {
  const { data: posts, loading } = useSelector((state: RootState) => state.posts?.timeline)
  const postToDiscardOrDelete = useSelector((state: RootState) => state.posts.postToDiscardOrDelete)
  const user = useSelector((state: RootState) => state.user.user)
  const pulse = useSelector((state: RootState) => state.posts.pulse)

  const d = useDispatch()

  useEffect(() => {
    d(setLoader({ timeline: true }))
    d(getPosts('timeline'))
    d(getPulse())
  }, [])

  useEffect(() => {
    setDocumentTitleHelper('Timeline')
  }, [])

  return (
    <Layout>
      <Container>
        <Wrapper>
          <Button
            variant="contained"
            color="success"
            onClick={() => d(openComposer())}
            disabled={!_f(FeatureTypes.POST_TEXT_IMAGE_VIDEO_LINK, user?.data?.plan).enabled}
          >
            Compose new post
          </Button>
          {!loading && (
            <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
              {posts?.length ? 'Your timeline' : `There’re no posts so far`}
            </Typography>
          )}
          <Box mt={3}>
            <FiltersComponent />
          </Box>
          <Box sx={{ display: 'flex', mt: 5 }}>
            <Box sx={{ width: 1 }}>
              {!posts || loading ? (
                <>
                  <PostSkeleton />
                  <PostSkeleton attachment />
                </>
              ) : (
                posts.map((post, index) => (
                  <PostComponent
                    post={post}
                    postType="timeline"
                    postIndex={index}
                    key={post._id}
                    pulse={pulse}
                    deleting={
                      !!postToDiscardOrDelete &&
                      postToDiscardOrDelete.index === index &&
                      postToDiscardOrDelete.type === 'draft'
                    }
                  />
                ))
              )}
            </Box>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
