import React, { createRef, useEffect, useState } from 'react'
import { Box, IconButton, List, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useDispatch, useSelector } from 'react-redux'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { CommentInterface } from 'shared-data/interfaces/comment.interface'
import { AuditPostInterface } from 'shared-data/interfaces/auditPost.interface'
import { DateTime } from 'luxon'
import { CommentBalloonComponent } from './CommentBalloonComponent'
import { CommentAuditActionComponent } from './CommentAuditAction.component'
import { RootState } from '../../store/RootState'
import { postComment } from '../../state/postComposer.slice'

enum CommentType {
  COMMENT = 'COMMENT',
  AUDIT = 'AUDIT',
}

type CombinedCommentInterface =
  // | {
  //     type: CommentType
  //     item: CommentInterface | AuditPostInterface
  //   }
  | {
      type: CommentType.COMMENT
      item: CommentInterface
    }
  | {
      type: CommentType.AUDIT
      item: AuditPostInterface
    }

interface Props {
  post: PostInterface
}

export const CommentsComponent: React.FC<Props> = ({ post }) => {
  const comments = useSelector((state: RootState) => state.postComposer.comments)
  const auditItems = useSelector((state: RootState) => state.postComposer.auditItems)

  const [combinedCommentsAndAuditItems, setCombinedCommentsAndAuditItems] = useState<Array<CombinedCommentInterface>>(
    []
  )

  useEffect(() => {
    const tmp = [
      ...comments.map((comment) => ({ type: CommentType.COMMENT, item: comment })),
      ...auditItems.map((auditItem) => ({ type: CommentType.AUDIT, item: auditItem })),
    ].sort(
      (a, b) =>
        DateTime.fromISO(a.item.createdAt!.toString()).toMillis() -
        DateTime.fromISO(b.item.createdAt!.toString()).toMillis()
    )
    setCombinedCommentsAndAuditItems(tmp as Array<CombinedCommentInterface>)
  }, [comments, auditItems])

  const [text, setText] = useState('')

  const commentsListRef = createRef<HTMLUListElement>()

  const dispatch = useDispatch()

  const onSubmit = () => {
    if (text) {
      dispatch(postComment({ postId: post._id!, text }))
    }
    setText('')
  }

  useEffect(() => {
    if (commentsListRef.current) {
      commentsListRef.current.scrollIntoView({ block: 'end' })
    }
  }, [comments, auditItems])

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        flexGrow={1}
        flexBasis={1}
        flexWrap="wrap"
        sx={{ backgroundColor: '#efefef', position: 'relative', overflowY: 'scroll' }}
      >
        <List sx={{ px: 1 }} ref={commentsListRef}>
          {combinedCommentsAndAuditItems.map((element, i) =>
            element.type === CommentType.COMMENT ? (
              <CommentBalloonComponent key={element.item._id} comment={element.item} />
            ) : (
              <CommentAuditActionComponent key={element.item._id} item={element.item} />
            )
          )}
        </List>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          id="standard-name"
          label="Your comment"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          multiline
          maxRows={6}
          size="small"
          // sx={{ height: '200px' }}
          fullWidth
          InputProps={{
            style: { fontSize: 14, lineHeight: 1.4 },
            endAdornment: (
              <IconButton size="small" sx={{ marginRight: '-8px' }} onClick={onSubmit} disabled={!text}>
                <SendIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  )
}
