import { AuditPostInterface } from 'shared-data/interfaces/auditPost.interface'
import { AuditPostTypes } from 'shared-data/types/auditPost.types'

const textify = (el: any) => {
  if (!el || el === null) {
    return ''
  }
  if (typeof el === 'string') {
    return el.toString()
  }
  if ('name' in el) {
    return el.name
  }
  if ('title' in el) {
    return el.title
  }
}

export const auditToTextHelper = (auditItem: AuditPostInterface): string => {
  if (auditItem.action === AuditPostTypes.CREATE) {
    return 'Created the post'
  }
  if (auditItem.action === AuditPostTypes.DELETE) {
    return 'Deleted the post'
  }
  if (auditItem.action === AuditPostTypes.POST) {
    return 'Posted on LinkedIn'
  }
  const { diff, originalObject, updatedObject } = auditItem
  if (!diff) {
    return 'Updated post'
  }
  let textBuilder = 'Updated '
  textBuilder += Object.keys(diff)
    .map((key) => {
      return `${key} from <strong><i>${textify(originalObject[key])}</i></strong> to <strong><i>${textify(
        updatedObject[key]
      )}</i></strong>`
    })
    .join(', ')
  return textBuilder
}
