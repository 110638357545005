import { SocketEventType } from 'shared-data/types/socketEventType'

export const socketMessagesHandler = (event: SocketEventType, payload: any) => {
  switch (event) {
    case SocketEventType.MESSAGE:
      console.log('socket on any', event, payload)
      break
    default:
      break
  }
}
