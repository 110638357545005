import React, { useEffect, useState } from 'react'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/RootState'

const LinkInputComponent = ({ onChange }) => {
  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)
  const [link, setLink] = useState(post?.link)

  useEffect(() => {
    if (post) {
      setLink(post?.link)
    }
  }, [post])
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box flexGrow={1}>
        {(() => {
          return (
            <TextField
              onChange={(e) => {
                onChange(e.target.value)
                setLink(e.target.value)
              }}
              onBlur={(e) => {
                try {
                  // eslint-disable-next-line no-new
                  new URL(e.target.value)
                } catch (err) {
                  onChange('')
                  e.target.value = ''
                }
              }}
              variant="standard"
              placeholder="URL to share in the comment"
              margin="normal"
              fullWidth
              value={link}
            />
          )
        })()}
      </Box>
      <Typography fontSize="10px" ml="5px" sx={{ width: '30%' }}>
        This will share a link in the comment, not in the post itself which will give more views to the post.
      </Typography>
    </Stack>
  )
}

export default LinkInputComponent
