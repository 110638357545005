import { UserInterface } from 'shared-data/interfaces/user.interface'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Avatar, Chip, Divider, Paper, Stack, useTheme } from '@mui/material'
import axios, { getToken } from '../../lib/axios'
import { AsyncData } from '../../store/AsyncData'
import { Logo } from '../logo/Logo'
import { useUserApi } from '../../hooks/useUserApi'
import Loader from '../loader'
import { HelpButtonComponent } from '../Intercom/HelpButton.component'

interface Props {
  user: AsyncData<UserInterface>
}

interface LinkProps {
  href?: string
  label: string
  onClick?: (e?: any) => void
  key?: string
}

export const TopNavBarComponent: FC<Props> = ({ user }) => {
  // const linkedinProfile = getLinkedinProfileHelper(user.data)
  // const twitterProfile = getTwitterProfileHelper(user.data)
  const navigate = useNavigate()

  const theme = useTheme()

  const { signOut } = useUserApi()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const [isLoading, setIsLoading] = useState(false)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const billingForm = useRef<HTMLFormElement>()

  const [pages, setPages] = useState<LinkProps[]>([])
  const [settings, setSettings] = useState<LinkProps[]>([])

  const clickBilling = useCallback(
    (e) => {
      if (billingForm?.current) {
        setIsLoading(true)
        e.preventDefault()
        billingForm.current.action = `${axios.defaults.baseURL}private/create-customer-portal-session?tkn=${getToken()}`
        billingForm.current.submit()
        handleCloseUserMenu()
      }
    },
    [billingForm]
  )

  const setNonLoggedInUserPages = () => {
    setPages([
      { label: 'Pricing', href: '/pricing', onClick: handleCloseNavMenu },
      // { label: 'Sign In', href: '/' },
      // { label: 'Sign Up', href: '/sign-up' },
    ])
  }

  const clickSignOut = () => {
    handleCloseUserMenu()
    setIsLoading(true)
    void signOut()
    setNonLoggedInUserPages()
  }

  const setMenuElements = useCallback(() => {
    if (!user?.data) {
      setNonLoggedInUserPages()
    }
  }, [user])

  const setSettingsElements = useCallback(() => {
    if (user?.data) {
      setSettings([
        { label: 'Brand', href: '/brand', onClick: handleCloseUserMenu },
        { label: 'Settings', href: '/settings', onClick: handleCloseUserMenu },
        { label: 'Organization', href: '/organization', onClick: handleCloseUserMenu },
        { label: 'Teams', href: '/teams', onClick: handleCloseUserMenu },
        { label: 'divider', key: '1' },
        { label: 'Billing & Payments', href: '#', onClick: clickBilling },
        { label: 'divider', key: '2' },
        { label: 'Sign Out', href: '#', onClick: clickSignOut },
      ])
    }
  }, [user, billingForm])

  useEffect(() => {
    setMenuElements()
    setSettingsElements()
  }, [])

  useEffect(() => {
    if (user?.data) {
      setPages([
        { label: 'Schedule', href: '/schedule', onClick: handleCloseNavMenu },
        { label: 'Dashboard', href: '/dashboard', onClick: handleCloseNavMenu },
        { label: 'Timeline', href: '/timeline', onClick: handleCloseNavMenu },
        { label: 'Drafts', href: '/draft', onClick: handleCloseNavMenu },
        { label: 'Time Target', href: '/timetargets', onClick: handleCloseNavMenu },
      ])
    }
  }, [])

  return (
    <AppBar position="static">
      {isLoading && <Loader />}
      <Container>
        <Toolbar disableGutters>
          <Paper
            elevation={0}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              background: 'none',
            }}
          >
            <Link to="/">
              <Logo width={120} light />
            </Link>
          </Paper>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  onClick={page.onClick}
                  component={Link}
                  to={page.href || '#'}
                  key={`${page.label}-${page.key || ''}`}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Paper
            elevation={0}
            sx={{
              display: { xs: 'flex', md: 'none' },
              background: 'none',
              flexGrow: 1,
            }}
          >
            <Link to="/">
              <Logo width={120} light />
            </Link>
          </Paper>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                to={page.href || '#'}
                key={`${page.label}-${page.key || ''}`}
                component={Link}
                onClick={page.onClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          {user?.data && (
            <Box sx={{ flexGrow: 0 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Box>
                  <HelpButtonComponent />
                </Box>
                <Box>
                  <Chip
                    label={`${user?.data?.plan?.toUpperCase()} plan`}
                    size="small"
                    color="success"
                    onClick={() => navigate('/pricing')}
                  />
                </Box>
                <Tooltip title="Open settings">
                  <Box display="flex" alignItems="center">
                    <MenuItem onClick={handleOpenUserMenu}>
                      <Typography>{`${user.data.firstName} ${user.data.lastName}`}</Typography>
                      {user.data.avatar && (
                        <Avatar src={user.data.avatar} alt={user.data.firstName} sx={{ ml: 1 }} variant="rounded" />
                      )}
                      <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                    </MenuItem>
                  </Box>
                </Tooltip>
              </Stack>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => {
                  if (setting.label === 'divider') {
                    return <Divider key={`${setting.label}-${setting.key || ''}`} />
                  }
                  return (
                    <MenuItem
                      to={setting.href || '#'}
                      key={`${setting.label}-${setting.key || ''}`}
                      component={Link}
                      onClick={setting.onClick}
                    >
                      <Typography textAlign="center">{setting.label}</Typography>
                    </MenuItem>
                  )
                })}
              </Menu>
              <form
                method="post"
                ref={(r) => {
                  if (r) {
                    billingForm.current = r
                  }
                }}
                action={`${axios.defaults.baseURL}private/create-customer-portal-session?tkn=${getToken()}`}
              >
                <input type="hidden" value={window.location.href} name="returnUrl" />
              </form>
            </Box>
          )}
          {!user?.data && (
            <Box sx={{ flexGrow: 0 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Box display="flex" alignItems="center">
                  <MenuItem component={Link} to="/">
                    <Typography>Sign In</Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/sign-up"
                    sx={{
                      ml: 2,
                      backgroundColor: theme.palette.success.main,
                      '&:hover': { backgroundColor: theme.palette.success.dark },
                    }}
                  >
                    <Typography>Sign Up</Typography>
                  </MenuItem>
                </Box>
              </Stack>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
