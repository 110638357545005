import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { RootState } from './RootState'
import { userSlice } from '../state/user.slice'
import { postComposerSlice } from '../state/postComposer.slice'
import { targetsSlice } from '../state/targets.slice'
import { timeTargetsSlice } from '../state/timeTargets.slice'
import { teamsSlice } from '../state/teams.slice'
import { postsSlice } from '../state/posts.slice'
import { organizationSlice } from '../state/organization.slice'
import { fileModalSlice } from '../state/fileModal.slice'

const reducer = combineReducers<RootState>({
  user: userSlice.reducer,
  postComposer: postComposerSlice.reducer,
  posts: postsSlice.reducer,
  targets: targetsSlice.reducer,
  timeTargets: timeTargetsSlice.reducer,
  teams: teamsSlice.reducer,
  organization: organizationSlice.reducer,
  file: fileModalSlice.reducer,
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
