import React, { useEffect } from 'react'
import styled from 'styled-components'
import Container from '../components/layout/container'
import Layout from '../components/layout'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const ConnectionsPage = () => {
  useEffect(() => {
    setDocumentTitleHelper('Connections')
  }, [])

  return (
    <Layout>
      <Container>
        <Wrapper>Connections page</Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
