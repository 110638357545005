import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeTargetInterface } from 'shared-data/interfaces/timeTarget.interface'
import axios from 'axios'
import { TimeTargetsState } from './timeTargets.state'
import { asyncDataInitialState } from '../store/AsyncData'

const initialState: TimeTargetsState = {
  targets: asyncDataInitialState,
  isModalOpen: false,
  editingTargetId: undefined,
}

export const getTargets = createAsyncThunk('getTargets', async () => {
  try {
    return await axios.get('/private/time_targets')
  } catch (e) {
    console.error(e)
    return { data: [] }
  }
})

export const timeTargetsSlice = createSlice({
  name: 'timeTarget',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TimeTargetInterface[] | null>) => {
      state.targets.loading = false
      state.targets.data = action.payload
    },
    openModal: (state) => {
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
      state.editingTargetId = undefined
    },
    editTimeTarget: (state, action: PayloadAction<string | undefined>) => {
      state.editingTargetId = action.payload
      state.isModalOpen = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTargets.fulfilled, (state, action) => {
      state.targets.data = action.payload.data
    })
  },
})

export const { openModal, closeModal, editTimeTarget } = timeTargetsSlice.actions
