import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'

type CommonMessage = {
  title: string
  message: string
  cancel?: string
  ok2Message?: string
  okMessage: string
  open: boolean
  handleClose: (c: boolean | 'quantumEntanglement') => void
}

export const CommonMessage: React.FC<CommonMessage> = ({
  title = '',
  message = '',
  cancel = '',
  ok2Message = '',
  okMessage = '',
  open = false,
  handleClose = () => {},
}) => {
  return ReactDOM.createPortal(
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button onClick={() => handleClose(false)} variant="outlined">
            {cancel}
          </Button>
        )}
        {ok2Message && (
          <Button onClick={() => handleClose('quantumEntanglement')} variant="contained">
            {ok2Message}
          </Button>
        )}
        <Button onClick={() => handleClose(true)} autoFocus variant="contained">
          {okMessage}
        </Button>
      </DialogActions>
    </Dialog>,
    document.body
  )
}
