import { PostInterface, PostType } from 'shared-data/interfaces/post.interface'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { AsyncData, asyncDataInitialState } from '../store/AsyncData'
import { userSlice } from './user.slice'
import { RootState } from '../store/RootState'

export type PostsState = {
  schedule: AsyncData<PostInterface[]>
  timeline: AsyncData<PostInterface[]>
  draft: AsyncData<PostInterface[]>
  postToDiscardOrDelete: null | { type: PostType; index: number }
  pulse: number[]

  filters: {
    status?: string[]
    assignee?: string[]
    targets?: string[]
  }
}

const initialState: PostsState = {
  schedule: asyncDataInitialState,
  timeline: asyncDataInitialState,
  draft: asyncDataInitialState,
  postToDiscardOrDelete: null,
  pulse: [],

  filters: {
    status: [],
    assignee: [],
    targets: [],
  },
}

export const deletePost = createAsyncThunk(
  'deletePost',
  async (doNotJustClose: boolean | undefined, { getState, dispatch: d }) => {
    if (doNotJustClose) return null
    try {
      const state = (getState() as RootState).posts
      const { postToDiscardOrDelete } = state

      if (!postToDiscardOrDelete) return null

      const id = (state[postToDiscardOrDelete.type].data || [])[postToDiscardOrDelete.index]._id

      await axios.delete(`/private/delete/${id}`)
      const p = await axios.get(`/private/posts/${postToDiscardOrDelete.type}`)
      return { [postToDiscardOrDelete.type as string]: p }
    } catch (e) {
      console.error(e?.message ?? e)
      d(userSlice.actions.setError(e?.message ?? e))
    }
    return null
  }
)

export const getPosts = createAsyncThunk('getPosts', async (type: PostType) => {
  const p = await axios.get(`/private/posts/${type}`)
  return { [type as string]: p }
})

export const getFilteredPosts = createAsyncThunk(
  'getFilteredPosts',
  async (payload: { type: PostType; assignees: string[]; statuses: string[]; targets: string[] }) => {
    const p = await axios.get(
      `/private/posts/${payload.type}?assignees=${payload.assignees.join(',')}&statuses=${payload.statuses.join(
        ','
      )}&targets=${payload.targets.join(',')}`
    )
    return { [payload.type as string]: p }
  }
)

export const getPulse = createAsyncThunk('getPulse', async () => {
  const p = await axios.get(`/private/statistics/me/reactions_pulse`)
  console.log(p)
  return p
})

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ [key in PostType]?: PostInterface[] }>) => {
      const key: PostType = Object.keys(action.payload)[0] as PostType
      state[key].loading = false
      if (key && action.payload[key] !== undefined) {
        state[key].data = action.payload[key] as PostInterface[]
      }
    },

    setPostToDiscardOrDelete: (state, action: PayloadAction<{ type: PostType; index: number } | null>) => {
      state.postToDiscardOrDelete = action.payload
    },

    setLoader: (state, action: PayloadAction<{ [key in PostType]?: boolean }>) => {
      const key: PostType = Object.keys(action.payload)[0] as PostType
      if (key && action.payload[key] !== undefined) {
        state[key].loading = !!action.payload[key]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.fulfilled, (state, action) => {
      // @ts-ignore
      const key: PostType = Object.keys(action.payload)[0] as PostType
      state[key].loading = false
      state[key].data = action.payload[key].data
    })
    builder.addCase(getFilteredPosts.fulfilled, (state, action) => {
      // @ts-ignore
      const key: PostType = Object.keys(action.payload)[0] as PostType
      state[key].loading = false
      state[key].data = action.payload[key].data
    })
    builder.addCase(getPulse.fulfilled, (state, action) => {
      state.pulse = action.payload.data
    })
    builder.addCase(deletePost.fulfilled, (state, action) => {
      if (action.payload && state.postToDiscardOrDelete) {
        // @ts-ignore
        const key: PostType = state.postToDiscardOrDelete.type
        state[key].loading = false
        state[key].data = action.payload[key].data
      }
      state.postToDiscardOrDelete = null
    })
  },
})

export const { setLoader, setPostToDiscardOrDelete } = postsSlice.actions
