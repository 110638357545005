import React from 'react'

export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.14" height="14.14" viewBox="0 0 14.14 14.14">
    <path
      id="Icon_simple-instagram"
      data-name="Icon simple-instagram"
      d="M7.07,0C5.149,0,4.909.009,4.155.042A5.213,5.213,0,0,0,2.439.371a3.462,3.462,0,0,0-1.253.815A3.45,3.45,0,0,0,.371,2.439,5.2,5.2,0,0,0,.042,4.155C.007,4.909,0,5.149,0,7.07S.009,9.23.042,9.985A5.216,5.216,0,0,0,.371,11.7a3.467,3.467,0,0,0,.815,1.253,3.457,3.457,0,0,0,1.253.815,5.22,5.22,0,0,0,1.716.329c.754.035.994.042,2.915.042s2.16-.009,2.915-.042a5.232,5.232,0,0,0,1.716-.329A3.613,3.613,0,0,0,13.769,11.7,5.216,5.216,0,0,0,14.1,9.985c.035-.754.042-.994.042-2.915s-.009-2.16-.042-2.915a5.229,5.229,0,0,0-.329-1.716,3.47,3.47,0,0,0-.815-1.253A3.445,3.445,0,0,0,11.7.371,5.2,5.2,0,0,0,9.985.042C9.23.007,8.991,0,7.07,0Zm0,1.273c1.887,0,2.112.009,2.857.042a3.9,3.9,0,0,1,1.312.245A2.327,2.327,0,0,1,12.582,2.9a3.9,3.9,0,0,1,.243,1.312c.034.746.041.97.041,2.857s-.009,2.112-.044,2.857a3.979,3.979,0,0,1-.248,1.312,2.245,2.245,0,0,1-.53.814,2.206,2.206,0,0,1-.813.528,3.932,3.932,0,0,1-1.317.243c-.751.034-.972.041-2.863.041s-2.113-.009-2.863-.044a4.009,4.009,0,0,1-1.317-.248,2.189,2.189,0,0,1-.812-.53,2.147,2.147,0,0,1-.53-.813,4.012,4.012,0,0,1-.247-1.317c-.027-.742-.036-.972-.036-2.854s.009-2.113.036-2.864a4.008,4.008,0,0,1,.247-1.316,2.1,2.1,0,0,1,.53-.814,2.091,2.091,0,0,1,.812-.529A3.913,3.913,0,0,1,4.181,1.29c.751-.027.972-.035,2.863-.035l.027.018Zm0,2.167A3.63,3.63,0,1,0,10.7,7.07,3.63,3.63,0,0,0,7.07,3.44Zm0,5.987A2.357,2.357,0,1,1,9.427,7.07,2.356,2.356,0,0,1,7.07,9.427ZM11.693,3.3a.848.848,0,1,1-.848-.848A.849.849,0,0,1,11.693,3.3Z"
      fill="#fff"
    />
  </svg>
)
