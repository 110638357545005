import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './editor.scss'
import debounce from 'lodash/debounce'
import { useTheme } from '@mui/material'
import axios from 'axios'
import { CompositeDecorator, ContentState, Editor, EditorState } from 'draft-js'
import { useDispatch } from 'react-redux'
import { InputWrapper } from '../../styles/form.styles'
import { setMentionedCompanies } from '../../state/postComposer.slice'

interface Props {
  value: string
  onChange: (value: string, hashtags: string[]) => void
}

export const PostTextInput: React.FC<Props> = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const editorRef = useRef<any>(null)
  useLayoutEffect(() => {
    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current.focus()
      }
    })
  }, [])

  const debouncedOnChange = useCallback(
    debounce((state) => {
      const cleanText = state.getCurrentContent().getPlainText('\n')
      const hashtags = findWithRegexInAllText(HASHTAG_REGEX, cleanText)
      onChange(cleanText, hashtags)
    }, 500),
    []
  )

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromText(value), decorators))
  }, [])

  return (
    <InputWrapper>
      <Editor
        editorState={editorState}
        placeholder="Enter your post content here"
        ref={editorRef}
        onChange={(state) => {
          setEditorState(state)
          debouncedOnChange(state)
        }}
      />
    </InputWrapper>
  )
}

export const HASHTAG_REGEX = /#\w+/g
const COMP_ORG_REGEX = /@\w(\w|-|_)*|https:\/\/www\.linkedin\.com\/\w+\/\w(\w|-|_)*\//g

function hashtagStrategy(contentBlock, callback, contentState) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback)
}

function companyStrategy(contentBlock, callback, contentState) {
  findWithRegex(COMP_ORG_REGEX, contentBlock, callback)
}

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText()
  let matchArr
  let start

  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

export function findWithRegexInAllText(regex, text) {
  const res: string[] = []
  let matchArr
  let start
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    res.push(text.substring(start, start + matchArr[0].length))
  }
  return res
}

const HashtagLink = ({ children }) => {
  const theme = useTheme()
  const { text } = children[0]?.props
  const spanLinkClick = useCallback(() => {
    window.open(
      `https://www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(
        text
      )}&origin=GLOBAL_SEARCH_HEADER&ref=cortip`,
      '_blank'
    )
  }, [text])

  return (
    <a href="javascript" onClick={spanLinkClick} style={{ color: theme.palette.primary.main }} rel="noreferrer">
      {children}
    </a>
  )
}

const CompanyLink = ({ children }) => {
  const theme = useTheme()
  const [companyData, setCompanyData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const d = useDispatch()
  const { text } = children[0]?.props
  const spanLinkClick = useCallback(() => {
    if (companyData) {
      window.open(`https://www.linkedin.com/company/${companyData.vanityName}/`, '_blank')
    }
  }, [companyData])

  const timeOutRef = useRef<any>(null)

  useEffect(() => {
    setError('')
    setLoading(false)
    setCompanyData(null)
    if (timeOutRef.current) clearTimeout(timeOutRef.current)
    timeOutRef.current = setTimeout(async () => {
      try {
        setLoading(true)
        const q = text.includes('https') ? text.match(/((\w|-|_)*)\/$/)[1] : text.toLowerCase().replace(/@+/g, '')
        const res = await axios.get(`/private/organization?query=${q}`)
        console.log(res)
        if (res.data?.elements?.length) {
          const [elem] = res.data.elements
          setCompanyData(elem) // looks like it returns only one element
          d(
            setMentionedCompanies({
              text,
              id: elem.id,
              vanityName: elem.vanityName,
              localizedName: elem.localizedName,
            })
          )
          if (res.data.elements.length > 1) {
            // sentryCapture(res.data.elements, 'companyMention has more then one element')
          }
        } else {
          setError('notFound')
        }
      } catch (e) {
        console.error(e)
        setError('error')
      }
      setLoading(false)
    }, 1000)
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current)
      }
    }
  }, [text])

  if (loading)
    return (
      <a href="javascript" style={{ color: theme.palette.primary.main }}>
        [loading]{children}
      </a>
    )
  if (error)
    return (
      <a href="javascript" style={{ color: theme.palette.error.main }}>
        [{error}]{children}
      </a>
    )
  if (!companyData) return children
  return (
    <a href="javascript" onClick={spanLinkClick} style={{ color: theme.palette.success.main }} rel="noreferrer">
      {children}
    </a>
  )
}

const decorators = new CompositeDecorator([
  {
    strategy: hashtagStrategy,
    component: HashtagLink,
  },
  {
    strategy: companyStrategy,
    component: CompanyLink,
  },
])
