import React from 'react'
import { render } from 'react-dom'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from 'shared-data/theme'
import mixpanel from 'mixpanel-browser'
import { IntercomProvider } from 'react-use-intercom'
import { store } from './store/store'
import App from './App'
//
mixpanel.init(String(process.env.REACT_APP_MIXPANEL_KEY), {
  debug: Boolean(process.env.REACT_APP_MIXPANEL_DEBUG),
  api_host: 'https://api-eu.mixpanel.com',
})

const rootElement = document.getElementById('root')
render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <IntercomProvider appId="nbs4wr2r" apiBase="https://api-iam.intercom.io">
          <App />
        </IntercomProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  rootElement
)
