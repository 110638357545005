import React, { FC, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  LinearProgress,
  Typography,
} from '@mui/material'
import hash from 'object-hash'
import { useDispatch, useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import axios from 'axios'
import { PostType } from 'shared-data/interfaces/post.interface'
import { PostProgressStatusTypes } from 'shared-data/types/postProgressStatus.types'
import { PermissionsInterface } from 'shared-data/interfaces/permissions'
import { PostSuggestionInterface } from 'shared-data/interfaces/PostSuggestion.interface'
import debounce from 'lodash/debounce'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { RootState } from '../../store/RootState'
import { closeComposer, getAuditItems, getComments, postAPost, resetComments } from '../../state/postComposer.slice'
import { ErrorWrapper } from '../../styles/form.styles'
import { PostTextInput } from '../PostTextInput/PostTextInput.component'
import { AttachmentDropZoneComponent } from '../attachmentDropZone/attachmentDropZone.component'
import { CommonMessage } from '../commonMessage/CommonMessage.component'
import BootstrapDialogTitle from '../bootstrapDialogTitle'
import { PostSchema } from './validation'
import { TagsSelectComponent } from './TagsSelect.component'
import LinkInputComponent from './LinkInput.component'
import { didNotChangeHelper } from './didNotChangeHelper'
import { getPosts, setLoader } from '../../state/posts.slice'
import TargetSelectComponent from './target/TargetSelect.component'
import TeamSettingsComponent from './teamSettings/teamSettings.component'
import FilesListComponent from './teamSettings/filesList.component'
import { CommentsComponent } from '../Comments/Comments.component'
import { ComposerDialogActionsComponent } from './ComposerDialogActions.component'
// Import Swiper React components

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { PostSuggestionItem } from './PostSuggestionItem.component'

export const PostComposer: FC = () => {
  const {
    isComposerOpened: isOpened,
    post: { data: post },
    editingPostId,
    attachment,
  } = useSelector((state: RootState) => state.postComposer)

  const user = useSelector((state: RootState) => state.user)

  const [permissions, setPermissions] = useState<PermissionsInterface>({
    isCanPostSchedule: false,
    isCanDeliver: false,
    isCanAcceptReject: false,
    isCanEdit: false,
    isCanCreate: false,
  })

  const d = useDispatch()

  const [suggestions, setSuggestions] = useState<PostSuggestionInterface[]>([])

  useEffect(() => {
    if (post?._id) {
      d(getComments(post._id))
      d(getAuditItems(post._id))
      if (post?.isPosted) {
        setShowDialogScheduled(true)
        return
      }
      window.location.hash = post?._id
      ;(async () => {
        try {
          const {
            data: { isCanPostSchedule, isCanEdit, isCanDeliver, isCanView, isCanAcceptReject },
          } = await axios.get(`/private/permissions/post/${post._id}`)

          if (!isCanView) {
            d(closeComposer())
          } else {
            setPermissions((c) => ({
              ...c,
              isCanPostSchedule,
              isCanDeliver,
              isCanEdit,
              isCanAcceptReject,
            }))
          }
        } catch (e) {
          setPermissions((c) => ({
            ...c,
            isCanPostSchedule: false,
          }))
          console.error(e)
        }
      })()
    }
  }, [post?._id])

  const formikValuesRef = useRef(null)

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [showDialogScheduled, setShowDialogScheduled] = useState<boolean>(false)
  const [showDialogUnsaved, setShowDialogUnsaved] = useState<boolean>(false)

  useEffect(() => {
    setShowDialogScheduled(!!post?.isPosted)
    setShowDialogUnsaved(false)

    let timeout
    if (post?.scheduledAt && !post.isDraft) {
      const duration = new Date(post?.scheduledAt).getTime() - Date.now()
      timeout = setTimeout(() => {
        setShowDialogScheduled(true)
      }, duration)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpened])

  useEffect(() => {
    if (formikValuesRef.current) {
      ;(formikValuesRef.current as any).setFieldValue('media', attachment)
    }
  }, [attachment])

  const [text, setText] = useState('')
  const [link, setLink] = useState('')

  const getSuggestions = debounce(async (text?: string, link?: string) => {
    console.log('xxx dddd')
    if (text || link) {
      const suggestions = await axios.get(
        `/ai/getAIPostSuggestion?url=${link ? encodeURIComponent(link) : ''}&text=${
          text ? encodeURIComponent(text) : ''
        }`
      )
      setSuggestions(suggestions.data)
      // console.log('xxx', suggestions.data)
    }
  }, 1000)

  useEffect(() => {
    setSuggestions([])
    void getSuggestions(text, link)
  }, [text, link, post])

  const handleClose = () => {
    const didNotChange = didNotChangeHelper(post, (formikValuesRef.current as any)?.values, !editingPostId)
    if (showDialogUnsaved || (!showDialogUnsaved && Object.values(didNotChange).some((c) => !c))) {
      setShowDialogUnsaved(true)
      return
    }
    setSuggestions([])
    d(closeComposer())
    d(resetComments())
  }

  // const swiper = createRef<SwiperRef>()
  const [swiper, setSwiper] = useState<any>(null)

  const submitHandler = async (settings, { setFieldValue }) => {
    if (submitting) return
    setSubmitting(true)
    const changes = didNotChangeHelper(post, settings)
    await d(postAPost({ ...settings, changes }))

    setFieldValue('submitType', undefined)
    setSubmitting(false)

    let match: RegExpMatchArray | null | string = window.location.pathname.match(/\/(\w+)$/)
    match = match ? match[1] : 'schedule'
    d(setLoader({ [match as PostType as string]: true }))
    d(getPosts(match as PostType))

    mixpanel.track('Post composer', {
      submitType: settings.submitType,
    })

    const types = {
      draft() {
        mixpanel.track('Post draft create')
      },
      schedule() {
        mixpanel.track('Post scheduled')
      },
      deliver() {
        mixpanel.track('Post delivered')
      },
      post() {
        mixpanel.track('Post posted')
      },
    }
    if (types[settings.submitType as unknown as string]) types[settings.submitType as unknown as string]()
  }

  if (!post || !user.user.data) return <></>

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpened && !!post}
      maxWidth={false}
      fullScreen
      sx={{ margin: 16 }}
      fullWidth
    >
      {(post.scheduledAt || post.isPosted) && (
        <CommonMessage
          open={showDialogScheduled}
          title="Post has been posted"
          message="Sorry you are not able to make any changes. Scheduled post has been already posted"
          okMessage="Ok"
          handleClose={() => {
            setShowDialogScheduled(false)
            d(closeComposer())
          }}
        />
      )}
      {submitting && <LinearProgress sx={{ mb: '-4px' }} />}
      <Formik
        innerRef={formikValuesRef}
        initialValues={{
          text: post.text ? JSON.parse(JSON.stringify(post.text)) : '',
          hashtags: post.hashtags?.length ? JSON.parse(JSON.stringify(post.hashtags)) : [],
          tags: post.tags?.length ? JSON.parse(JSON.stringify(post.tags)) : [],
          link: post.link ? JSON.parse(JSON.stringify(post.link)) : '',
          media: post.attachment ? JSON.parse(JSON.stringify(post.attachment)) : null,
          submitType: undefined,
          rejectReason: undefined,
          scheduledAt: post.scheduledAt || undefined,
          timeTarget: post.timeTarget || undefined,
          target: post.targetEntity?._id || undefined,
          assignee: post.assignee?._id || null,
          progressStatus: post.progressStatus || PostProgressStatusTypes.BACKLOG,
        }}
        validationSchema={PostSchema}
        onSubmit={submitHandler}
      >
        {({ errors, setFieldValue, submitForm, values }) => (
          <>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Typography sx={{ fontWeight: 'bold' }}>Post {editingPostId ? 'Edit' : 'composer'}</Typography>
            </BootstrapDialogTitle>

            <DialogContent sx={{ display: 'flex', height: 600 }}>
              <Box
                sx={{ width: 2 / 4, position: 'relative', overflowY: 'scroll', paddingRight: '16px' }}
                display="flex"
                flexDirection="column"
              >
                <Box flexGrow={1}>
                  <Box>
                    <CommonMessage
                      open={showDialogUnsaved}
                      title="Discard post"
                      message={
                        editingPostId
                          ? 'Are you sure you want to discard this post? This action will delete it permanently from the system.'
                          : 'You have unsaved fields. Do you want to discard the post or save as draft?'
                      }
                      cancel="Cancel"
                      okMessage="Discard"
                      ok2Message="Save as Draft"
                      handleClose={(c) => {
                        if (![true, false].includes(c as boolean) && !editingPostId) {
                          setFieldValue('submitType', 'draft')
                          void submitForm()
                        }
                        if (c) {
                          d(closeComposer())
                        }
                        setShowDialogUnsaved(false)
                      }}
                    />
                    <Form>
                      <FormGroup>
                        <TargetSelectComponent
                          onChange={(v) => {
                            setFieldValue('target', v)
                            ;(async () => {
                              try {
                                setPermissions((c) => ({
                                  ...c,
                                  isCanPostSchedule: false,
                                  isCanDeliver: false,
                                  isCanCreate: false,
                                }))
                                const {
                                  data: { isCanPostSchedule, isCanCreate, isCanDeliver },
                                } = await axios.get(`/private/permissions/target/${v}`)
                                setPermissions((c) => ({
                                  ...c,
                                  isCanPostSchedule,
                                  isCanDeliver,
                                  isCanCreate,
                                }))
                              } catch (e) {
                                setPermissions((c) => ({
                                  ...c,
                                  isCanPostSchedule: false,
                                }))
                                console.error(e)
                              }
                            })()
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <div style={{ display: 'block', position: 'relative', width: '100%' }}>
                          <Swiper navigation modules={[Navigation]} className="mySwiper" onSwiper={setSwiper}>
                            <SwiperSlide>
                              <span>
                                <PostTextInput
                                  value={post?.text}
                                  onChange={(text, hashtags) => {
                                    setFieldValue('text', text)
                                    setFieldValue('hashtags', hashtags)
                                    setText(text)
                                  }}
                                />
                                {errors.text ? <ErrorWrapper>{errors.text}</ErrorWrapper> : null}
                              </span>
                            </SwiperSlide>
                            {suggestions &&
                              suggestions.map((suggestion: PostSuggestionInterface, i) => (
                                <SwiperSlide key={hash(suggestion)}>
                                  <PostSuggestionItem
                                    text={suggestion.text}
                                    onSelect={(text: string, hashtags: string[]) => {
                                      console.log('xxx', { text, hashtags })
                                      setFieldValue('text', text)
                                      setFieldValue('hashtags', hashtags)
                                      setText(text)
                                      if (swiper) {
                                        swiper.slideTo(0)
                                      }
                                    }}
                                  />
                                </SwiperSlide>
                              ))}
                          </Swiper>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <AttachmentDropZoneComponent
                          onChange={(a) => {
                            setFieldValue('media', a)
                          }}
                        />
                        {errors.media ? <ErrorWrapper>{errors.media}</ErrorWrapper> : null}
                      </FormGroup>
                      <LinkInputComponent
                        onChange={(link) => {
                          setFieldValue('link', link)
                          setLink(link)
                        }}
                      />
                      {errors.link ? <ErrorWrapper>{errors.link}</ErrorWrapper> : null}
                      <TagsSelectComponent onChange={(tags) => setFieldValue('tags', tags)} />
                      <Divider sx={{ margin: '10px 0' }} />
                    </Form>
                  </Box>
                </Box>
              </Box>
              <Divider orientation="vertical" sx={{ margin: '0 10px', height: '100%' }} />
              <Box sx={{ width: 1 / 4, pt: 1 }} display="flex" flexDirection="column">
                <TeamSettingsComponent
                  setSubmitting={setSubmitting}
                  onProgressStatusChange={(v) => setFieldValue('progressStatus', v)}
                  onAssigneeChange={(v) => setFieldValue('assignee', v)}
                  target={values.target}
                />
                <Box sx={{}} flexGrow={1}>
                  {/* <Divider sx={{ margin: '10px 0' }} /> */}
                  <FilesListComponent
                    setSubmitting={setSubmitting}
                    filesChange={(f) => {
                      setFieldValue('files', f)
                    }}
                  />
                </Box>
              </Box>
              <Divider orientation="vertical" sx={{ margin: '0 10px', height: '100%' }} />
              <Box sx={{ width: 1 / 4, position: 'relative' }} display="flex" flexDirection="column">
                <CommentsComponent post={post} />
              </Box>
            </DialogContent>
            <DialogActions sx={{ borderTop: 'thin solid #e0e0e0' }}>
              <ComposerDialogActionsComponent
                onScheduledChange={(v) => setFieldValue('scheduledAt', v)}
                onTimeTargetChange={(v) => setFieldValue('timeTarget', v)}
                submitForm={(submitType, reason) => {
                  if (['accept', 'reject'].includes(submitType) && editingPostId) {
                    setFieldValue('submitType', 'draft')
                    if (reason) {
                      setFieldValue('rejectReason', reason)
                    }
                    setFieldValue(
                      'progressStatus',
                      submitType === 'accept' ? PostProgressStatusTypes.DONE : PostProgressStatusTypes.REJECTED
                    )
                  } else {
                    setFieldValue('submitType', submitType)
                  }
                  setTimeout(submitForm)
                }}
                permissions={permissions}
                isTimeTarget={!!values.timeTarget}
                submitting={submitting}
                hasChanges={Object.values(didNotChangeHelper(post, values, !editingPostId)).some((c) => !c)}
              />
              {errors.scheduledAt ? <ErrorWrapper>{errors.scheduledAt}</ErrorWrapper> : null}
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}
