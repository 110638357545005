import React, { memo, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Duration } from 'luxon'

export const TimeLeft = memo(
  ({ dateTime, onOver, withoutTitle }: { dateTime: Date; onOver?: () => void; withoutTitle?: true }) => {
    const [time, setTime] = useState(0)

    useEffect(() => {
      const duration = dateTime.getTime() - new Date().getTime()
      setTime(duration)
      const interval = setInterval(() => {
        setTime((t) => {
          const toReturn = t - 1000
          if (toReturn <= 0) {
            clearInterval(interval)
            if (onOver) {
              onOver()
            }
            return 0
          }
          return toReturn
        })
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [dateTime])
    return (
      <Box sx={{ mr: 1, color: 'primary.main', textAlign: 'center', pt: 1 }}>
        {!withoutTitle && <Typography>Scheduled</Typography>}
        <Typography>{time && Duration.fromObject({ millisecond: time }).toFormat('hh:mm:ss')}</Typography>
      </Box>
    )
  }
)
