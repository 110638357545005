import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { userSlice } from '../../state/user.slice'

export enum DangerActions {
  'DELETE_ACCOUNT',
}

type props = {
  dangerType: keyof typeof DangerActions
  onClose?: () => void
}

export const DangerActionConfirmComponent: React.FC<props> = ({ dangerType, onClose }) => {
  const [passwordValue, setPasswordValue] = useState('')
  const d = useDispatch()
  const handleClose = async (c: boolean) => {
    if (c) {
      if (dangerType === 'DELETE_ACCOUNT') {
        try {
          await axios.post('/private/danger/delete-user', { password: passwordValue })
          window.location.pathname = '/'
        } catch (e) {
          d(userSlice.actions.setError(e.message))
          console.error(e)
        }
      }
    }
    if (onClose) onClose()
  }

  return ReactDOM.createPortal(
    <Dialog
      open
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm dangerous action</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 1 }}
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          id="outlined-basic"
          label="Please enter the password"
          variant="outlined"
          type="password"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} autoFocus variant="contained" color="error">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>,
    document.body
  )
}
