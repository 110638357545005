import React, { FC } from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import { PostInterface, PostType } from 'shared-data/interfaces/post.interface'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { ThumbUpIcon } from '../icons/thumbUp.icon'
import { ChatBubbleIcon } from '../icons/chatBubble.icon'
import { getPostAndEdit } from '../../state/postComposer.slice'
import axios from '../../lib/axios'
import { PostItemWithDetailsComponent } from './PostItemWithDetails.component'

interface Props {
  post: PostInterface
  postIndex: number
  postType: PostType
  onPostDelete: () => void
}

const LoadingOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      position: 'absolute',
      backgroundColor: '#ffffffaa',
      width: 1,
      height: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
)

const PostComponent: FC<Props> = ({ post, postIndex, onPostDelete, postType }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleting, setDeleting] = React.useState<boolean>(false)
  const isMenuOpen = Boolean(anchorEl)
  const d = useDispatch()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = async () => {
    handleClose()
    setDeleting(true)
    await axios.delete(`/private/delete/${post._id}`)
    onPostDelete()
  }

  const handleEdit = () => {
    setAnchorEl(null)
    d(getPostAndEdit(post._id as string))
  }

  const ResolvedDateTime = () => {
    if (post.scheduledAt) {
      return (
        <>
          {`${DateTime.fromISO(post.scheduledAt.toString()).toRelativeCalendar()}, ${DateTime.fromISO(
            post.scheduledAt.toString()
          ).toFormat('MMMM dd, yyyy h:mm a')}`}
        </>
      )
    }
    if (post.updatedAt) {
      return <>{DateTime.fromISO(post.updatedAt.toString()).toFormat('MMMM dd, yyyy h:mm a')}</>
    }
    return <></>
  }

  return (
    <Card
      sx={{
        maxWidth: 410,
        mt: 3,
        opacity: post.isDeletedOnLinkedIn ? 0.5 : 1,
        borderLeft: post.isDeletedOnLinkedIn ? '4px solid red' : 'none',
        position: 'relative',
      }}
      aria-disabled={post.isDeletedOnLinkedIn}
      elevation={10}
    >
      {deleting && <LoadingOverlay />}
      <Menu
        id={`menu${postIndex}`}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `post${postIndex}`,
        }}
      >
        {!post.urn && [
          <MenuItem onClick={handleEdit} key={1}>
            Edit
          </MenuItem>,
          <Divider variant="middle" sx={{ margin: '0 10px 0' }} key={2} />,
          <MenuItem onClick={handleDelete} key={3}>
            Discard
          </MenuItem>,
        ]}
        {post.urn && [
          <MenuItem onClick={handleClose}>Clone to drafts</MenuItem>,
          <Divider variant="middle" sx={{ margin: '0 10px 0' }} key={2} />,
          <MenuItem onClick={handleDelete} key={3}>
            Delete
          </MenuItem>,
        ]}
      </Menu>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            id={`post${postIndex}`}
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHoriz />
          </IconButton>
        }
        subheader={<ResolvedDateTime />}
        subheaderTypographyProps={{ fontSize: 12 }}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {post.text}
        </Typography>
      </CardContent>
      {post.attachment && post.attachment.type === 'image' && post.attachment.uploadSrc && (
        <CardMedia
          component="img"
          height="194"
          image={post.attachment.uploadSrc}
          alt={post.attachment.uploadSrc}
          sx={{ pr: 2, pl: 2 }}
        />
      )}
      {post.attachment && post.attachment.type === 'video' && post.attachment.uploadSrc && (
        <CardMedia component="video" height="194" src={post.attachment.uploadSrc} controls sx={{ pr: 2, pl: 2 }} />
      )}
      <Divider variant="middle" sx={{ mt: 2 }} />
      <Box>
        <CardActions sx={{ d: 'flex', justifyContent: 'space-between', pr: 2 }}>
          <Box sx={{ px: 2 }} flexGrow={1} flexShrink={0}>
            {post?.likesSummary && (
              <IconButton aria-label="add to favorites">
                <ThumbUpIcon />
                <Typography sx={{ ml: 1 }}>{post.likesSummary.aggregatedTotalLikes}</Typography>
              </IconButton>
            )}
            {post?.commentsSummary && (
              <IconButton aria-label="add to favorites">
                <ChatBubbleIcon />
                <Typography sx={{ ml: 1 }}>{post.commentsSummary.aggregatedTotalComments}</Typography>
              </IconButton>
            )}
          </Box>
          <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" justifyContent="flex-end">
            {post.tags?.map((tag) => (
              <Chip label={tag.name} variant="outlined" color="primary" key={tag._id} />
            ))}
          </Stack>
        </CardActions>
      </Box>
    </Card>
  )
}

export const OldPostItem = PostComponent
export default PostItemWithDetailsComponent
