import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { Buffer } from 'buffer'
import Container from '../components/layout/container'
import { SignInForm } from '../components/auth/forms/signin.form'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

export const SignInPage = () => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
    ? Buffer.from(String(searchParams.get('email')), 'base64').toString()
    : undefined

  useEffect(() => {
    setDocumentTitleHelper('Sign In')
  }, [])

  return (
    <Wrapper>
      <Container>
        <SignInForm email={email} />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
