import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import timeZones from 'shared-data/timeTarget/timeZones.json'
import { TimeTargetInterface } from 'shared-data/interfaces/timeTarget.interface'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { RootState } from '../../store/RootState'
import axios from '../../lib/axios'
import { closeModal, getTargets } from '../../state/timeTargets.slice'
import TimeZoneCardComponent from './timeZoneCard.component'
import { ErrorWrapper } from '../../styles/form.styles'
import BootstrapDialogTitle from '../bootstrapDialogTitle'

const PostSchema = Yup.object().shape({
  timeTargets: Yup.mixed()
    .test('length', 'At least one timezone should be selected', (value) => {
      return !!value.length
    })
    .test('name', 'The Name is too short', (value) => !!value.every(({ name }) => name?.length >= 3))
    .test('name', 'The Name is too long', (value) => !!value.every(({ name }) => name?.length <= 50))
    .required('The Name is required')
    .test(
      'invalidTime',
      'The time you entered is invalid - please fix',
      (value) =>
        !!value.every(({ from, to }) => {
          return (
            from && to && DateTime.fromFormat(from, 'hh:mm').toMillis() < DateTime.fromFormat(to, 'hh:mm').toMillis()
          )
        })
    )
    .required('Time target name is required'),
})

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

export const TimeTargetModal: FC = () => {
  const { isModalOpen: isOpened, editingTargetId, targets } = useSelector((state: RootState) => state.timeTargets)
  const formikValuesRef = useRef(null)

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [timeTargets, setTimeTargets] = useState<TimeTargetInterface[]>([])
  const [val, setVal] = useState('')

  useEffect(() => {
    if (editingTargetId) {
      const found = targets.data?.find(({ _id }) => _id === editingTargetId)
      setTimeTargets(found ? [found] : [])
    }
  }, [isOpened])

  const d = useDispatch()

  const handleClose = () => {
    d(closeModal())
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpened} maxWidth="lg" fullWidth>
      {submitting && <LinearProgress sx={{ mb: '-4px' }} />}
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography sx={{ fontWeight: 'bold' }}>Create new time targets</Typography>
      </BootstrapDialogTitle>
      <Formik
        innerRef={formikValuesRef}
        initialValues={{
          timeTargets,
          isEdit: false,
        }}
        validationSchema={PostSchema}
        onSubmit={async ({ timeTargets, isEdit }) => {
          if (submitting) return
          setSubmitting(true)
          try {
            await axios[editingTargetId && isEdit ? 'put' : 'post'](
              `/private/time_target${editingTargetId && isEdit ? `/${editingTargetId}` : ''}`,
              { timeTargets },
              {
                headers: {
                  Accept: 'application/json',
                  'Accept-Language': 'en-US,en;q=0.8',
                  'Content-Type': 'application/json',
                },
              }
            )
          } catch (e) {
            console.error(e)
          }
          d(closeModal())
          d(getTargets())
          setTimeTargets([])
          setSubmitting(false)
        }}
      >
        {({ errors, setFieldValue, submitForm }) => (
          <Form>
            <DialogContent sx={{ display: 'flex', height: 600 }}>
              <Box sx={{ position: 'relative', width: 1 }}>
                {!editingTargetId && (
                  <>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Box flexGrow={1}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Select timezone to include</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            onChange={(e) => {
                              setTimeTargets((c) => {
                                const next = [
                                  ...c,
                                  {
                                    value: e.target?.value,
                                    from: '',
                                    to: '',
                                    name: '',
                                  },
                                ]
                                setFieldValue('timeTargets', next)
                                return next
                              })
                              setVal('')
                            }}
                            value={val}
                          >
                            {timeZones.map((t) => (
                              <MenuItem value={t.value} key={t.value + t.text}>
                                {t.text} | {t.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Typography fontSize="10px" ml="5px" sx={{ width: '30%' }}>
                        Pick a timezone from this list to add into your time targeting.
                      </Typography>
                    </Stack>
                    <Divider sx={{ margin: '10px 0' }} />
                  </>
                )}
                <Box>
                  {errors.timeTargets ? <ErrorWrapper>{errors.timeTargets}</ErrorWrapper> : null}
                  {timeTargets.map((c, i) => (
                    <TimeZoneCardComponent
                      key={c._id}
                      {...c}
                      onTimeChanged={(data) => {
                        setTimeTargets((c) => {
                          const next = [...c]
                          next[i] = { ...next[i], ...data }
                          setFieldValue('timeTargets', next)
                          return next
                        })
                      }}
                      onNameChanged={(name) => {
                        setTimeTargets((c) => {
                          const next = [...c]
                          next[i] = { ...next[i], name }
                          setFieldValue('timeTargets', next)
                          return next
                        })
                      }}
                      name={c.name}
                      onRemove={() => {}}
                    />
                  ))}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ m: 0, py: 2, px: 3, flexDirection: 'column' }}>
              <Divider sx={{ width: 1, mb: 1 }} />
              <Box
                sx={{
                  justifyContent: 'flex-end',
                  width: 1,
                  display: 'flex',
                  ...(submitting ? { opacity: 0.5, pointerEvents: 'none' } : {}),
                }}
              >
                <Button type="submit" color="success" variant="contained">
                  Create new Time target
                </Button>
                {editingTargetId && (
                  <Button
                    type="submit"
                    color="success"
                    variant="contained"
                    sx={{ ml: 1 }}
                    onClick={() => setFieldValue('isEdit', true)}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
