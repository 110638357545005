import React, { FC } from 'react'
import { Avatar, Box, CardMedia, Tooltip, Typography } from '@mui/material'
import { PostInterface } from 'shared-data/interfaces/post.interface'
import { DateTime } from 'luxon'
import { TeamTargetComponent } from '../../Teams/TeamTarget.component'
import { PostTextComponent } from './PostText.component'

interface Props {
  post: PostInterface
}

const ResolvedDateTime = ({ post }: { post: PostInterface }) => {
  if (post.scheduledAt) {
    return (
      <>
        {`${DateTime.fromISO(post.scheduledAt.toString()).toRelativeCalendar()}, ${DateTime.fromISO(
          post.scheduledAt.toString()
        ).toFormat('MMMM dd, yyyy h:mm a')}`}
      </>
    )
  }
  if (post.postedAt) {
    return <>{DateTime.fromISO(post.postedAt.toString()).toFormat('MMMM dd, yyyy h:mm a')}</>
  }
  if (post.updatedAt) {
    return <>{DateTime.fromISO(post.updatedAt.toString()).toFormat('MMMM dd, yyyy h:mm a')}</>
  }
  return <></>
}

export const PostItselfComponent: FC<Props> = ({ post }) => (
  <Box flexGrow={1} display="flex" flexDirection="column">
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      <Box>{post.targetEntity && <TeamTargetComponent target={post.targetEntity} />}</Box>
      <Box>
        {post.assignee && (
          <Tooltip
            title={`${post.assignee.firstName} ${post.assignee.lastName} (${post.assignee.email})`}
            placement="top"
          >
            <Avatar src={post.assignee.avatar} alt="user" sx={{ width: 40 }} variant="rounded" />
          </Tooltip>
        )}
      </Box>
    </Box>
    <Box sx={{ p: 2, pb: 1, mb: 1, flexGrow: 1 }}>
      <PostTextComponent text={post.text} />
    </Box>
    {post.attachment && post.attachment.type === 'image' && post.attachment.uploadSrc && (
      <Tooltip title={`Attachment Status: ${post.attachment.status}`} placement="top">
        <CardMedia
          component="img"
          height="194"
          image={post.attachment.uploadSrc}
          alt={post.attachment.uploadSrc}
          sx={{ pr: 2, pl: 2 }}
        />
      </Tooltip>
    )}
    {post.attachment && post.attachment.type === 'video' && post.attachment.uploadSrc && (
      <Tooltip title={`Attachment Status: ${post.attachment.status}`} placement="top">
        <CardMedia component="video" height="194" src={post.attachment.uploadSrc} controls sx={{ pr: 2, pl: 2 }} />
      </Tooltip>
    )}
    <Typography variant="body2" color="text.secondary" sx={{ p: 2, pt: 1, mt: 1 }}>
      🗓 <ResolvedDateTime post={post} />
    </Typography>
  </Box>
)
