import React from 'react'

export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.779" height="12.779" viewBox="0 0 12.779 12.779">
    <path
      id="Icon_metro-facebook"
      data-name="Icon metro-facebook"
      d="M13.22,1.928H4.7a2.13,2.13,0,0,0-2.13,2.13v8.519a2.13,2.13,0,0,0,2.13,2.13H8.96V9.116h-1.6v-1.6h1.6v-1.2a2,2,0,0,1,2-2h2v1.6h-2a.4.4,0,0,0-.4.4v1.2h2.2l-.4,1.6h-1.8v5.591H13.22a2.13,2.13,0,0,0,2.13-2.13V4.058a2.13,2.13,0,0,0-2.13-2.13Z"
      transform="translate(-2.571 -1.928)"
      fill="#fff"
    />
  </svg>
)
