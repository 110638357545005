import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { companyMention, PostInterface } from 'shared-data/interfaces/post.interface'
import axios from 'axios'
import { PostAttachmentInterface } from 'shared-data/interfaces/postAttachment.interface'
import FormData from 'form-data'
import { asyncDataInitialState } from '../store/AsyncData'
import { PostState } from './post.state'
import { userSlice } from './user.slice'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'

const initialState: PostState = {
  post: asyncDataInitialState,
  isComposerOpened: false,
  editingPostId: '',
  attachment: null,
  mentionedCompanies: [],
  comments: [],
  auditItems: [],
}

export const getPostAndEdit = createAsyncThunk('getPostAndEdit', async (id: string) => {
  try {
    const p = await axios.get(`/private/post/${id}`)
    return { data: p.data, id }
  } catch (err) {
    console.error(err)
    return { data: undefined, id: '', err }
  }
})

export const postAPost = createAsyncThunk('postAPost', async (settings: any, { getState, dispatch: d }) => {
  try {
    const {
      changes,
      hashtags,
      text,
      tags,
      link,
      submitType,
      rejectReason,
      scheduledAt,
      timeTarget,
      target,
      assignee,
      progressStatus,
      files,
    } = settings
    const {
      post: { data: post },
      attachment,
      editingPostId,
      mentionedCompanies,
      // @ts-ignore
    } = getState().postComposer

    const data = new FormData()

    if (!changes.text) {
      data.append('text', text)
      data.append('hashtags', JSON.stringify(hashtags))
    }
    if (!changes.tags) {
      data.append('tags', JSON.stringify(tags))
    }
    if (!changes.attachment) {
      if (post?.attachment?.uploadSrc) {
        data.append('attachmentDeleted', true)
      } else {
        data.append('media', attachment)
      }
    }
    if (mentionedCompanies?.length) {
      const splitText = text.split(/\s+/)
      const nextMentions = mentionedCompanies.filter((c) => splitText.includes(c.text))
      if (nextMentions.length) {
        data.append('mentionedCompanies', JSON.stringify(nextMentions))
      }
    }
    if (!changes.target) {
      data.append('target', target)
    }
    if (!changes.link) {
      data.append('link', link)
    }
    if (!changes.assignee) {
      data.append('assignee', assignee)
    }
    if (!changes.progressStatus) {
      data.append('progressStatus', progressStatus)
    }
    if (rejectReason) {
      data.append('rejectReason', rejectReason)
    }

    if (editingPostId) {
      if (!changes.timeTarget) {
        data.append('scheduledAt', scheduledAt)
        data.append('timeTarget', timeTarget)
      } else if (!changes.scheduledAt) {
        data.append('scheduledAt', scheduledAt)
      }
    } else {
      if (!scheduledAt) return
      data.append('scheduledAt', scheduledAt)
      if (timeTarget) {
        data.append('timeTarget', timeTarget)
      }
    }

    try {
      const result = await axios.post(`/private/post/${submitType}${editingPostId ? `/${editingPostId}` : ''}`, data, {
        headers: {
          Accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data`,
        },
      })
      if (files?.length) {
        const f = new FormData()
        files
          .filter(({ _id }) => !_id)
          .forEach((c) => {
            f.append('media[]', c)
          })
        const resultOfFiles = await axios.put(`/private/attach_files_to_post/${result.data._id}`, f, {
          headers: {
            Accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data`,
          },
        })
        console.log(resultOfFiles)
      }

      d(closeComposer())
      return result
    } catch (e) {
      console.error(e?.message ?? e)
      d(userSlice.actions.setError(e?.message ?? e))
    }
    return undefined
  } catch (e) {
    console.error(e)
  }
  return undefined
})

export const getComments = createAsyncThunk('getComments', async (postId: string) => {
  try {
    const commentsResponse = await axios.get(`/private/post/${postId}/comment`)
    return commentsResponse.data
  } catch (err) {
    console.error(err)
    return []
  }
})

export const getAuditItems = createAsyncThunk('getAuditItems', async (postId: string) => {
  try {
    const auditResponse = await axios.get(`/private/post/${postId}/audit`)
    return auditResponse.data
  } catch (err) {
    console.error(err)
    return []
  }
})

export const postComment = createAsyncThunk('postComment', async (payload: { postId: string; text: string }) => {
  try {
    const commentResponse = await axios.post(`/private/post/${payload.postId}/comment`, { text: payload.text })
    return commentResponse.data
  } catch (err) {
    console.error(err)
    return []
  }
})

let previousDocumentTitle = document.title

export const postComposerSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PostInterface | null>) => {
      state.post.loading = false
      state.post.data = action.payload
    },
    setAttachment: (state, action: PayloadAction<File | PostAttachmentInterface | null>) => {
      state.attachment = action.payload
    },
    setLoader: (state, action: PayloadAction<boolean | undefined>) => {
      state.post.loading = action.payload === undefined ? true : action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.post.loading = false
      state.post.error = action.payload
    },

    setMentionedCompanies: (state, action: PayloadAction<companyMention>) => {
      if (!state.mentionedCompanies.find(({ id }) => action.payload.id === id)) {
        state.mentionedCompanies = [...state.mentionedCompanies, action.payload]
      }
    },

    openComposer: (state, action: PayloadAction<PostInterface | undefined>) => {
      state.isComposerOpened = true
      state.post.data = action.payload ?? {
        text: '',
        tags: [],
      }
      previousDocumentTitle = document.title
      setDocumentTitleHelper('Post Composer')
    },
    closeComposer: (state) => {
      state.isComposerOpened = false
      state.post.data = null
      state.editingPostId = ''
      setDocumentTitleHelper(previousDocumentTitle)
      setTimeout(() => {
        window.location.hash = ''
      })
    },
    resetComments: (state) => {
      state.comments = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostAndEdit.fulfilled, (state, action) => {
        // @ts-ignore
        state.post.data = action.payload.data
        state.editingPostId = action.payload.id
        state.isComposerOpened = Boolean(action.payload.id)
        if (action.payload.err?.message) {
          alert(action.payload.err.message)
        }
      })
      .addCase(postAPost.fulfilled, (state, action) => {
        console.log(action.payload)
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.comments = action.payload
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.comments = [...state.comments, action.payload]
      })
      .addCase(getAuditItems.fulfilled, (state, action) => {
        state.auditItems = action.payload
      })
  },
})

export const { openComposer, closeComposer, setMentionedCompanies, resetComments } = postComposerSlice.actions
