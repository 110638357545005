import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Chart } from 'react-google-charts'
import Layout from '../components/layout'
import { RootState } from '../store/RootState'
import axios from '../lib/axios'
import { setDocumentTitleHelper } from '../helpers/setDocumentTitle.helper'
import { userSlice } from '../state/user.slice'

/**
 * CHARTS DOCUMENTATION https://www.react-google-charts.com/
 */

interface PersonalConnectionsCountInterface {
  _id: string
  count: number
}

interface SocialSummary {
  [key: string]: {
    commentsSummary: number
    likesSummary: number
  }
}

export const DashboardPage = () => {
  const user = useSelector((state: RootState) => state.user.user)

  const [personalConnectionsCount, setPersonalConnectionsCount] = useState<PersonalConnectionsCountInterface[]>([])
  const [socialSummary, setSocialSummary] = useState<SocialSummary[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const d = useDispatch()

  useEffect(() => {
    Promise.all([
      (async () => {
        setLoading(true)
        try {
          const response = await axios.get('/private/statistics/me/connections/count/daily')
          // console.log('STATISTICS ME CONNECTIONS COUNT AGG DAILY', response.data)
          setPersonalConnectionsCount(response.data || [])
        } catch (e) {
          d(userSlice.actions.setError(e?.message ?? e))
        }
      })(),
      (async () => {
        setLoading(true)
        try {
          const response = await axios.get('/private/statistics/me/social_summary')
          setSocialSummary(response.data || [])
        } catch (e) {
          d(userSlice.actions.setError(e?.message ?? e))
        }
      })(),
    ])
      .then(() => {
        setLoading(false)
        return true
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    setDocumentTitleHelper('Dashboard')
  }, [])

  return (
    <Layout>
      <Container>
        <Box>
          <Typography variant="h3">Dashboard</Typography>
        </Box>
        <Box mt={5}>
          <Paper sx={{ p: 4 }}>
            <Box>
              <Typography variant="h6">Connections for {`${user.data?.firstName} ${user.data?.lastName}`}</Typography>
              <Typography variant="body2" mt={1}>
                Linkedin connections count on personal profile
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            <Box>
              {loading ? (
                <CircularProgress />
              ) : personalConnectionsCount.length ? (
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="550px"
                  data={[
                    ['Date', 'Connections count'],
                    ...personalConnectionsCount.map((entry) => [entry._id, entry.count]),
                  ]}
                  options={{
                    chartArea: { width: '90%', height: '400px' },
                    // curveType: 'function',
                    legend: { position: 'top' },
                  }}
                />
              ) : (
                <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
                  There’re no connections data so far
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>
        <Box mt={5}>
          <Paper sx={{ p: 4 }}>
            <Box>
              <Typography variant="h6">
                Social summary for {`${user.data?.firstName} ${user.data?.lastName}`}
              </Typography>
              <Typography variant="body2" mt={1}>
                Accumulated likes and comments over time
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
            <Box sx={{ display: 'flex' }}>
              {loading ? (
                <CircularProgress />
              ) : socialSummary ? (
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="550px"
                  data={[
                    ['Date', 'Comments count', 'Likes count'],
                    ...socialSummary.map((sum) => {
                      const [date, { commentsSummary, likesSummary }] = Object.entries(sum)[0]

                      return [date, commentsSummary, likesSummary]
                    }),
                  ]}
                  options={{
                    chartArea: { width: '90%', height: '400px' },
                    legend: { position: 'top' },
                  }}
                />
              ) : (
                <Typography variant="h5" component="h1" sx={{ mt: 5 }}>
                  There’re no social data so far
                </Typography>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Layout>
  )
}
