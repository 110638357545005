import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, MenuItem, Select, Typography } from '@mui/material'
import { TimeTargetInterface } from 'shared-data/interfaces/timeTarget.interface'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/RootState'
import { getTargets, openModal } from '../../state/timeTargets.slice'

const worldWide = { name: 'Manual time', _id: 'worldWide', value: 'worldWide' }

const ListItem = ({ target }) => (
  <Box sx={{ display: 'flex', gap: 1 }} alignItems="center">
    <Typography variant="h6">{target.name}</Typography>
  </Box>
)

const TimeTargetSelectComponent = ({ onChange }: { onChange: (c: string | undefined) => void }) => {
  const timeTargets = useSelector((state: RootState) => state.timeTargets.targets?.data || [])
  const [selectedTarget, setSelectedTarget] = useState<TimeTargetInterface | undefined | typeof worldWide>(worldWide)
  const [expectingNew, setExpectingNew] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    post: { data: post },
  } = useSelector((state: RootState) => state.postComposer)
  const d = useDispatch()

  useEffect(() => {
    if (!timeTargets.length) {
      ;(async () => {
        d(getTargets())
      })()
    }
    if (!post || !post.timeTarget) {
      setSelectedTarget(worldWide)
    } else {
      setSelectedTarget(timeTargets.find(({ _id }) => _id === post.timeTarget))
    }
  }, [post])

  useEffect(() => {
    if (timeTargets.length) {
      if (expectingNew) {
        setExpectingNew(false)
        setSelectedTarget(timeTargets[0])
        setIsOpen(false)
        onChange(timeTargets[0]._id)
        return
      }
      if (post?.timeTarget) {
        setSelectedTarget(timeTargets.find(({ _id }) => _id === post.timeTarget))
      }
    }
  }, [timeTargets])

  if (!post || !selectedTarget) return <></>

  return (
    <Box sx={{ display: 'flex', mr: 1 }}>
      <Select
        value={selectedTarget?._id}
        size="small"
        sx={{
          mb: 1,
          height: '40px',
          width: 1,
        }}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        renderValue={() => (selectedTarget ? <ListItem target={selectedTarget} /> : <></>)}
        onChange={(e) => {
          const { value } = e.target
          if (!value) {
            setSelectedTarget(worldWide)
            onChange(undefined)
          } else {
            const next = timeTargets?.find((t) => t._id === value)
            setSelectedTarget(next)
            onChange(next?._id)
          }
        }}
        placeholder="Select your Time target"
        variant="outlined"
      >
        <Button
          color="success"
          variant="contained"
          sx={{ height: '40px', m: 2 }}
          onClick={() => {
            d(openModal())
            setExpectingNew(true)
          }}
        >
          New
        </Button>
        <MenuItem value="">
          <ListItem target={worldWide} />
        </MenuItem>
        <Divider />
        {timeTargets?.map((t) => (
          <MenuItem value={t._id} key={t._id}>
            <ListItem target={t} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default TimeTargetSelectComponent
