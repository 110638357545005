import { io, Socket } from 'socket.io-client'
import { Dispatch } from '@reduxjs/toolkit'

class SocketHandler {
  private socket: Socket

  private dispatch: Dispatch

  public socketConnect = (token) => {
    this.socket = io(String(process.env.REACT_APP_API_URL), {
      // auth: token,
      query: { token },
    })
    return this.socket
  }

  public setDispatch = (d: Dispatch) => {
    this.dispatch = d
  }

  public getDispatch = () => this.dispatch

  public getSocket = () => this.socket
}

export const socketHandler = new SocketHandler()
